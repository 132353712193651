import {Layout, Flex, Image, Typography, Input, Table, List, Skeleton, Empty, Tag, message} from 'antd';
import {guidesApi, useGetCutGradeClassesWithChildrenQuery} from '../../services/guidesApi';
import { useEffect, useState } from 'react';
import { ClearOutlined, CopyOutlined, DeleteOutlined, EditOutlined, HomeOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import g from "../../general.module.scss";
import {ModalCreateCutGrade, ModalEditCutGrade} from "./CutGradeModals";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";
import {Link} from "react-router-dom";

const { Title, Text } = Typography;

const columns = [
    {
        title: 'Позиция',
        dataIndex: 'pos'
    },
    {
        title: 'Элемент',
        dataIndex: ['tool', 'id'],
        render: (id, record) => (
            <Flex gap="small" vertical>
                <Text>{record.tool.id}</Text>
                <Text>{record.tool.name}</Text>
                <Text>{record.tool.description}</Text>
            </Flex>
        )
    },
    {
        title: 'Количество',
        dataIndex: 'quantity'
    }
];

const CutGrade = () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedCutGradeId, setSelectedCutGradeId] = useState(null);
    const [selectedCutGrade, setSelectedCutGrade] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);
    const [refetch, setRefetch] = useState(null)

    // const { data: cutGrade, isFetching, isError } = useGetCutGradeClassesWithChildrenQuery(currentPage);
    const [search, {data: cutGrade, isFetching, isError}] = guidesApi.endpoints.getCutGrades.useLazyQuery();


    const handleToolClick = (branch) => {
        console.log(branch);
        setSelectedCutGradeId(branch.id);
        setSelectedCutGrade(branch)

        // const parameters = branch.branches.map((item) => ({
        //     key: item.id,
        //     ...item
        // }));
        //
        // setParameters(parameters);
        //
        // setInformation([
        //     { name: 'ID', value: branch.id },
        //     { name: 'Наименование', value: branch.name }
        // ]);
    };

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => {setOpenModalCreateCutGrade(true)}
        },
        {
            icon: <EditOutlined />,
            disabled: selectedCutGrade === null,
            onClick: () => {setOpenModalEditCutGrade(true)}
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedCutGrade === null,
            onClick: () => { setOpenModalConfirmDelete(true) }
        },
    ]

    const [refetchList, setRefetchList] = useState();

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetchList(!refetchList)
    }

    const [openModalEditCutGrade, setOpenModalEditCutGrade] = useState(false)
    const handleCloseModalEditCutGrade = () => {
        setOpenModalEditCutGrade(false)
        setRefetchList(!refetchList)
    }

    const [openModalCreateCutGrade, setOpenModalCreateCutGrade] = useState(false)
    const handleCloseModalCreateCutGrade = () => {
        setOpenModalCreateCutGrade(false)
        setRefetchList(!refetchList)
    }

    const handleDeleteGroup = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/cutGrade/${selectedCutGradeId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    message.success('Элемент удален!')
                    setOpenModalConfirmDelete(false)
                    message.info('refetching...')
                    return res
                }
            })
    }

    const [companies, setCompanies] = useState([])

    const [detailCompany, setDetailCompany] = useState(null)

    useEffect(() => {
        if (selectedCutGrade && selectedCutGrade?.supplier) {
            fetch(`${process.env.REACT_APP_BASE_URL}/guide/company/${selectedCutGrade?.supplier?.id}`).then(res => res.json()).then(res => setDetailCompany(res))
        }
    }, [selectedCutGrade]);

    useEffect(() => {
        if (openModalCreateCutGrade || openModalEditCutGrade) {
            fetch(`${process.env.REACT_APP_BASE_URL}/guide/company`).then(res => res.json()).then(res => setCompanies(res))
        }
    }, [openModalCreateCutGrade, openModalEditCutGrade])

    useEffect(() => {
        if (cutGrade && totalElements === 0) setTotalElements(cutGrade.totalElements);
        setAllData(cutGrade)
    }, [cutGrade])
    const [allData, setAllData] = useState(null)

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedCutGrade}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}
        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedCutGrade && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedCutGrade?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedCutGrade?.name}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Вид'}</Text>
                        <Text>{selectedCutGrade?.kind || 'Не указан'}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Поставщик'}</Text>
                        <Text>
                            <Link to={`/companies/${detailCompany?.id}`}>
                                <Tag>{detailCompany?.shortName}</Tag>
                            </Link>
                        </Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Покрытие'}</Text>
                        <Text>{selectedCutGrade?.coat || 'Не указан'}</Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{ flexGrow: 2 }} className={g.container_div}>
                <Layout style={{  background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={`${g.container_block} ${g.container_block_image}`}>
                    {/* {file ? <Scene url={file} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />} */}
                    <Image className={g.container_block_image_div} src={selectedCutGrade?.drawing?.includes('base64') ? selectedCutGrade?.drawing : `data:image/jpeg;base64,${selectedCutGrade?.drawing}`} />
                </Layout>
            </Flex>

            <ModalCreateCutGrade
                callback_open={openModalCreateCutGrade}
                callback_close={handleCloseModalCreateCutGrade}
                companies={companies}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCutGrade}

            />
            <ModalEditCutGrade
                callback_open={openModalEditCutGrade}
                callback_close={handleCloseModalEditCutGrade}
                companies={companies}

                selectedCutGrade={selectedCutGrade}
                detailCompany={detailCompany}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCutGrade}
            />

            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/cutGrade/${selectedCutGradeId}`}

                setAllElements={setAllData}
                selectedElement={selectedCutGrade}
                setSelectedElement={setSelectedCutGrade}
                setTotalElements={setTotalElements}
            />
        </ContainerAside>
    );
};

export default CutGrade
