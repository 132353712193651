import React, { useState, useEffect } from 'react';
import { LoadStep } from '../../StepLoader';
import Size from './Size';

function StepModel({ url, setBlob3D, setActionRuler, ...props }) {
  const [obj, setObj] = useState(null);

  useEffect(() => {
    async function load() {
      const mainObject = await LoadStep(url, setBlob3D);
      setObj(mainObject);
    }
    load();
  }, [url]);

  useEffect(() => {
    setActionRuler(false);
  }, []);

  if (!obj) {
    return null;
  }

  return (
    <group {...props}>
      <primitive name="Model" object={obj} />
      <Size obj={obj} />
    </group>
  );
}
export default StepModel;
