import { createSlice } from '@reduxjs/toolkit';

const loadTabsFromLocalStorage = () => {
  const storedTabs = localStorage.getItem('tabs');
  return storedTabs
    ? JSON.parse(storedTabs)
    : [
        {
          key: '',
          label: 'Стартовая страница',
          closable: false,
          path: '/startPage',
          active: true
        }
      ];
};

const loadActiveTabFromLocalStorage = () => {
  const storedActiveTab = localStorage.getItem('activeTab');
  return storedActiveTab ? storedActiveTab : '';
};

const headerSlice = createSlice({
  name: 'header',
  initialState: {
    activeTab: loadActiveTabFromLocalStorage(),
    tabs: loadTabsFromLocalStorage()
  },
  reducers: {
    addTab(state, action) {
      const { key, label } = action.payload;

      if (key === 'startPage') {
        const existingTab = state.tabs.find((tab) => tab.key === key);
        if (!existingTab) {
          state.tabs.push({ key, label, closable: false, path: '/', active: true });
          localStorage.setItem('tabs', JSON.stringify(state.tabs));
        }
      } else {
        const cond = state.tabs.some((el) => el.label === label);
        if (!cond) {
          state.tabs.push(action.payload);
          localStorage.setItem('tabs', JSON.stringify(state.tabs));
        }
      }
    },
    deleteTab(state, action) {
      const index = state.tabs.findIndex((tab) => tab.key === action.payload);
      if (index !== -1) {
        state.tabs.splice(index, 1);
        localStorage.setItem('tabs', JSON.stringify(state.tabs));
      }
    },
    setActiveTab(state, action) {
      if (action.payload !== 'startPage') {
        state.activeTab = action.payload;
        localStorage.setItem('activeTab', action.payload);
      } else {
        state.activeTab = 'startPage';
        localStorage.setItem('activeTab', 'startPage');
      }
    },
    setTabs(state, action) {
      const newTabs = action.payload;

      const hasHomeTab = newTabs.some((tab) => tab.key === 'startPage');

      if (!hasHomeTab) {
        state.tabs = [{ key: 'startPage', label: 'Стартовая страница', active: true, closable: false }, ...newTabs];
      }

      localStorage.setItem('tabs', JSON.stringify(state.tabs));
    }
  }
});

export default headerSlice.reducer;
export const { addTab, deleteTab, setActiveTab, setTabs } = headerSlice.actions;
