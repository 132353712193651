import React, {useEffect, useState} from 'react'
import {
    guidesApi,
    useGetCompanyTypeClassesWithChildrenQuery,
    useGetCountryClassesWithChildrenQuery
} from "../../services/guidesApi";
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import {ColorPicker, Flex, List, message, Table, Typography} from "antd";
const { Title, Text } = Typography;
import {
    ClearOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    HomeOutlined,
    PlusOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import g from '../../general.module.scss'
import {ModalCreateCompanyType, ModalEditCompanyType} from "./CompanyTypeModals";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";

const CompanyType = () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedCompanyTypeId, setSelectedCompanyTypeId] = useState(null);
    const [selectedCompanyType, setSelectedCompanyType] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);

    const [refetchList, setRefetchList] = useState();

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetchList(!refetchList)
    }

    const [openModalEditCompanyType, setOpenModalEditCompanyType] = useState(false)
    const handleCloseModalEditCompanyType = () => {
        setOpenModalEditCompanyType(false)
        disabled: selectedCompanyType === null,
        setRefetchList(!refetchList)
    }

    const [openModalCreateCompanyType, setOpenModalCreateCompanyType] = useState(false)
    const handleCloseModalCreateCompanyType = () => {
        setOpenModalCreateCompanyType(false)
        setRefetchList(!refetchList)
    }

    //const { data: companyTypes, isFetching, isError } = useGetCompanyTypeClassesWithChildrenQuery(currentPage);
    const [search, {data: companyTypes, isFetching, isError}] = guidesApi.endpoints.getCompanyTypes.useLazyQuery();


    const handleToolClick = (branch) => {
        console.log(branch);
        setSelectedCompanyTypeId(branch.id);
        setSelectedCompanyType(branch);

        // const parameters = branch.branches.map((item) => ({
        //     key: item.id,
        //     ...item
        // }));
        //
        // setParameters(parameters);
        //
        // setInformation([
        //     { name: 'ID', value: branch.id },
        //     { name: 'Наименование', value: branch.name }
        // ]);
    };

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => {
                setOpenModalCreateCompanyType(true)
            }
        },
        {
            icon: <EditOutlined />,
            disabled: selectedCompanyType === null,
            onClick: () => {
                setOpenModalEditCompanyType(true)
            }
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedCompanyType === null,
            onClick: () => {
                setOpenModalConfirmDelete(true)
            }
        },
    ]

    useEffect(() => {
        if (companyTypes && totalElements === 0) setTotalElements(companyTypes.totalElements);
        setAllData(companyTypes)
    }, [companyTypes]);

    const [refetch, setRefetch] = useState(null);

    const handleDeleteGroup = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/companyType/${selectedCompanyTypeId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    message.success('Элемент удален!')
                    setOpenModalConfirmDelete(false)
                    message.info('refetching...')
                    return res
                }
            })
    }

    const [allData, setAllData] = useState(null)

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedCompanyType}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}
        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedCompanyType && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedCompanyType?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedCompanyType?.name}</Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{flexGrow: 2}} className={g.container_block}>
                <div style={{height: '50%', padding: '10px', overflow: 'auto', position: 'relative', background: 'white'}}>
                    <Table
                        title={() => <Title level={4}>Распределение соединений</Title>}
                        pagination={false}
                        dataSource={parameters}
                        columns={[]}
                    />
                </div>
            </Flex>
            
            <ModalCreateCompanyType 
                callback_open={openModalCreateCompanyType}
                callback_close={handleCloseModalCreateCompanyType}
                setAllElements={setAllData}
                selectedElement={selectedCompanyType}
                setSelectedElement={setSelectedCompanyType}
                setTotalElements={setTotalElements}
                allElements={allData}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCompanyType}



            />
            <ModalEditCompanyType
                callback_open={openModalEditCompanyType}
                callback_close={handleCloseModalEditCompanyType}
                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCompanyType}

                selectedCompanyType={selectedCompanyType}



               
            />
            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/companyType/${selectedCompanyTypeId}`}

                setAllElements={setAllData}
                selectedElement={selectedCompanyType}
                setSelectedElement={setSelectedCompanyType}
                setTotalElements={setTotalElements}
            />
        </ContainerAside>
    )
}

export default CompanyType