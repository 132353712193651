import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, InputNumber, Layout, message, Modal, Select, Switch, Upload} from "antd";
import g from "../../general.module.scss";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import tool from '../../content/images/tool.svg'
import assembly from '../../content/images/assembly.svg'

export const ModalEditMachine = ({callback_open, callback_close, selectedMachine, machineClasses, allElements, setAllElements, setSelectedElement, setTotalElements}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        const result = {...body, machineClass: {id: body.machineClass}}

        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/machine`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    setAllElements({...allElements, results: [...allElements.results.filter(f => f.id !== selectedMachine.id), body]})
                    // setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    callback_close()
                    setIsWaitingResponse(false)
                    return res
                }
            })
    }

    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64Image(`${reader.result}`)
        };
    };
    const [base64Image, setBase64Image] = useState('')

    useEffect(() => {
        setBase64Image(`${
            selectedMachine?.drawing &&
            selectedMachine?.drawing.includes('base64')
                ?
                selectedMachine?.drawing
                :
                'data:image/jpeg;base64, ' + selectedMachine?.drawing}` || '')
    }, [selectedMachine]);

    useEffect(() => {
        try {
            form.setFieldsValue({
                drawing: `${base64Image}`,
            });
        } catch (e) {
            console.log(e)
        }
    }, [base64Image])

    const onChange = e => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };

    const [checkedFirstOption, setCheckedFirstOption] = useState(false)
    const [checkedSecondOption, setCheckedSecondOption] = useState(false)

    return (
        <Modal
            centered
            title={'Изменение оборудования'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            destroyOnClose
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                    name={'createBranch'}
                    initialValues={{
                        id: selectedMachine?.id,
                        name: selectedMachine?.name,
                        drawing: selectedMachine?.drawing,
                        machineClass: {label: selectedMachine?.machineClass?.name, value: selectedMachine?.machineClass?.id}
                    }}
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="drawing"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input type="file" id={'photoUploader'} onChange={onChange} className={g.modal_inputfile_input}/>
                        <img src={base64Image} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image === ''} onClick={() => {
                            document.getElementById("photoUploader").value = "";
                            setBase64Image('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
                <Form.Item
                    name="machineClass"
                    label="Класс станков"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Select
                        options={machineClasses?.results?.map((el,i) => {
                            return (
                                {
                                    label: el?.name,
                                    value: el?.id
                                }
                            )
                        })}
                    />
                </Form.Item>
            </Layout>
        </Modal>
    )
}

export const ModalCreateMachine = ({callback_open, callback_close, machineClasses, allElements, setAllElements, setTotalElements, setSelectedElement}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        console.log(body)
        const result = {...body, machineClass: {id: body.machineClass}}
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/machine`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    console.log(allElements)
                    setAllElements({...allElements, results: [...allElements.results, body]})
                    setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    setIsWaitingResponse(false)
                    callback_close()
                    return res
                }
            })
            .catch(e => {
                message.error(`${e}`)
                setIsWaitingResponse(false)
            })
    }

    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64Image(`${reader.result}`)
        };
    };
    const [base64Image, setBase64Image] = useState('')

    useEffect(() => {
        try {
            form.setFieldsValue({
                drawing: `${base64Image}`,
            });
        } catch (e) {
            console.log(e)
        }
    }, [base64Image])

    const onChange = e => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };


    return (
        <Modal
            centered
            title={'Создание оборудования'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="drawing"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input type="file" id={'photoUploader'} onChange={onChange} className={g.modal_inputfile_input}/>
                        <img src={base64Image} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image === ''} onClick={() => {
                            document.getElementById("photoUploader").value = "";
                            setBase64Image('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
                <Form.Item
                    name="machineClass"
                    label="Класс станков"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Select
                        options={machineClasses?.results?.map((el,i) => {
                            return (
                                {
                                    label: el?.name,
                                    value: el?.id
                                }
                            )
                        })}
                    />
                </Form.Item>
            </Layout>
        </Modal>
    )
}