import {Layout, Flex, Image, Typography, Input, Table, List, Skeleton, Empty, Tag, message, ColorPicker} from 'antd';
import {
    guidesApi,
    useGetMaterialClassesWithChildrenQuery,
    useGetMaterialWithChildrenQuery
} from '../../services/guidesApi';
import React, { useEffect, useState } from 'react';
import { ClearOutlined, CopyOutlined, DeleteOutlined, EditOutlined, HomeOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import g from "../../general.module.scss";
import {ModalCreateMaterial, ModalEditMaterial} from "./MaterialModals";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";

const { Title, Text } = Typography;

const columns = [
    {
        title: 'Позиция',
        dataIndex: 'pos'
    },
    {
        title: 'Элемент',
        dataIndex: ['tool', 'id'],
        render: (id, record) => (
            <Flex gap="small" vertical>
                <Text>{record.tool.id}</Text>
                <Text>{record.tool.name}</Text>
                <Text>{record.tool.description}</Text>
            </Flex>
        )
    },
    {
        title: 'Количество',
        dataIndex: 'quantity'
    }
];

const Material= () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedMaterialId, setSelectedMaterialId] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);

    // const { data: material, isFetching, isError } = useGetMaterialWithChildrenQuery(currentPage);
    const [search, {data: material, isFetching, isError}] = guidesApi.endpoints.getMaterials.useLazyQuery();

    const handleToolClick = (branch) => {
        console.log(branch);
        setSelectedMaterialId(branch.id);
        setSelectedMaterial(branch)

        // const parameters = branch.branches.map((item) => ({
        //     key: item.id,
        //     ...item
        // }));
        //
        // setParameters(parameters);
        //
        // setInformation([
        //     { name: 'ID', value: branch.id },
        //     { name: 'Наименование', value: branch.name }
        // ]);
    };

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => {setOpenModalCreateMaterial(true)}
        },
        {
            icon: <EditOutlined />,
            disabled: selectedMaterial === null,
            onClick: () => {setOpenModalEditMaterial(true)}
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedMaterial === null,
            onClick: () => { setOpenModalConfirmDelete(true) }
        },
    ]

    const [refetchList, setRefetchList] = useState();

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetchList(!refetchList)
    }

    const [openModalEditMaterial, setOpenModalEditMaterial] = useState(false)
    const handleCloseModalEditMaterial = () => {
        setOpenModalEditMaterial(false)
        setRefetchList(!refetchList)
    }

    const [openModalCreateMaterial, setOpenModalCreateMaterial] = useState(false)
    const handleCloseModalCreateMaterial = () => {
        setOpenModalCreateMaterial(false)
        setRefetchList(!refetchList)
    }

    const handleDeleteGroup = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/material/${selectedMaterialId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    message.success('Элемент удален!')
                    setOpenModalConfirmDelete(false)
                    message.info('refetching...')
                    return res
                }
            })
    }

    const [refetch, setRefetch] = useState(null)

    const [materialClasses, setMaterialClasses] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/materialClass`).then(res => res.json()).then(res => setMaterialClasses(res))
    }, [])

    useEffect(() => {
        if (material && totalElements === 0) setTotalElements(material.totalElements);
        setAllData(material)
    }, [material]);

    const [allData, setAllData] = useState(null)

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedMaterial}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}
        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedMaterial && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedMaterial?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedMaterial?.name}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Жесткость'}</Text>
                        <Text>{selectedMaterial?.hardness || 'Не указано'}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Сила растяжения'}</Text>
                        <Text>{selectedMaterial?.tensileStrength || 'Не указано'}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Класс материала'}</Text>
                        <Tag>{selectedMaterial?.materialClass?.name || 'Не указано'}</Tag>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{ flexGrow: 2 }} className={g.container_div}>
                <Layout style={{  background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={`${g.container_block} ${g.container_block_image}`}>
                    {/* {file ? <Scene url={file} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />} */}
                </Layout>
            </Flex>

            <ModalCreateMaterial
                callback_open={openModalCreateMaterial}
                callback_close={handleCloseModalCreateMaterial}
                materialClasses={materialClasses}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedMaterial}
            />
            <ModalEditMaterial
                callback_open={openModalEditMaterial}
                callback_close={handleCloseModalEditMaterial}

                materialClasses={materialClasses}
                selectedMaterial={selectedMaterial}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedMaterial}
            />

            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/material/${selectedMaterialId}`}

                setAllElements={setAllData}
                selectedElement={selectedMaterial}
                setSelectedElement={setSelectedMaterial}
                setTotalElements={setTotalElements}
            />
        </ContainerAside>
    );
};

export default Material
