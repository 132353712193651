import { Tabs } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteTab, setActiveTab } from '../../features/headerSlice';
import ph from '../PageHeader/PageHeader.module.scss';

export const TabsComponent = () => {
  const tabs = useSelector((state) => state.header.tabs);
  const activeTab = useSelector((state) => state.header.activeTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (newActiveKey) => {
    dispatch(setActiveTab(newActiveKey));
    navigate(newActiveKey);
  };

  const onEdit = (targetKey) => {
    if (targetKey === activeTab) {
      const newActiveKey = tabs.length > 1 ? tabs[0].key : 'startPage';
      dispatch(deleteTab(targetKey));
      dispatch(setActiveTab(newActiveKey));
      navigate(newActiveKey === 'startPage' ? '/startPage' : newActiveKey);
    } else {
      dispatch(deleteTab(targetKey));
    }
  };

  return (
    <Tabs
      className={ph.tabs}
      hideAdd
      type="editable-card"
      onChange={onChange}
      activeKey={activeTab}
      onEdit={onEdit}
      items={tabs}
      style={{ background: '#14181F' }}
    />
  );
};
