import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Layout, Modal, message, InputNumber } from 'antd';
import g from '../../general.module.scss';
import { useForm } from 'antd/lib/form/Form';
import uuid from 'uuid-random';

export const ModalEditClassGroups = ({ callback_open, callback_close, selectedClass }) => {
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    tdmClass: {},
    used: '',
    drawing: '',
    parameters: []
  });
  const callback_submit = () => {
    setIsWaitingResponse(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/guide/group`, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      if (res.status !== 200) {
        message.error(`Ошибка! ${res.status}`);
      } else {
        res.json();
      }
    });
  };
  return (
    <Modal
      centered
      title={'Изменение отрасли'}
      open={callback_open}
      onCancel={callback_close}
      width={384}
      destroyOnClose
      okButtonProps={{
        htmlType: 'submit'
      }}
      confirmLoading={isWaitingResponse}
      modalRender={(dom) => (
        <Form
          className={g.modal_layout}
          layout={'vertical'}
          variant={'outlined'}
          style={{
            maxWidth: 600
          }}
          onFinish={(values) => callback_submit(values)}
          clearOnDestroy
          name={'createBranch'}
          initialValues={{
            id: selectedClass?.id,
            name: selectedClass?.name
          }}>
          {dom}
        </Form>
      )}>
      <Layout className={g.modal_formLayout}>
        <Form.Item name="id" label="Идентификатор" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Название" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Название" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="used" label="Использование" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
      </Layout>
    </Modal>
  );
};

export const ModalCreateClassGroups = ({ allClasses, setAllClasses, callback_open, callback_close, selectedClass, setSelectedClass }) => {
  const [form] = useForm();
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const callback_submit = (body) => {
    const data = body;
    data.isAcitve = false;
    data.isObligatory = false;
    data.value = '';
    const formData = { ...selectedClass, parameters: selectedClass.parameters !== undefined ? [...selectedClass.parameters] : [] };
    formData.parameters.push(data);
    console.log(formData);
    fetch(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARAM_CLASS}`, {
      method: 'PUT',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        if (res.status === 200) {
          message.success('Параметр успешно создан');
          setSelectedClass(null);
          return res.json();
        } else {
          message.error('Ошибка создания');
        }
      })
      .then((data) => {
        console.log(data);
        setAllClasses(allClasses.map((l) => (l.id == selectedClass.id ? { ...data } : l)));
        setSelectedClass(data);
        message.success(`Успешно создано`);
        callback_close();
      });
  };

  return (
    <Modal
      centered
      title={'Создание параметра'}
      open={callback_open}
      onCancel={callback_close}
      width={384}
      okButtonProps={{
        htmlType: 'submit'
      }}
      confirmLoading={isWaitingResponse}
      modalRender={(dom) => (
        <Form
          form={form}
          className={g.modal_layout}
          layout={'vertical'}
          variant={'outlined'}
          style={{
            maxWidth: 600
          }}
          onFinish={(values) => callback_submit(values)}
          clearOnDestroy
          name={'createBranch'}>
          {dom}
        </Form>
      )}>
      <Layout className={g.modal_formLayout}>
        <Form.Item name="id" label="Идентификатор" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Название" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="unit" label="Единица измерения" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Описание" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Layout>
    </Modal>
  );
};
