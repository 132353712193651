import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const guidesApi = createApi({
  reducerPath: 'guidesApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: [
    'tool',
    'assemble',
    'class',
    'machine',
    'material',
    'detail',
    'cut',
    'list',
    'country',
    'branch',
    'company',
    'language',
    'classType',
    'cutGrade',
    'adapter',
    'cutGradeGroup'
  ],
  endpoints: (builder) => ({
    getTools: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_TOOL}?page=${page}&size=200&includeChildren=true${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result ? [...result.results.map(({ id }) => ({ type: 'tool', id })), { type: 'tool', id: 'LIST' }] : [{ type: 'tool', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getToolAssemblies: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_ASSEMBLE}?page=${page}&size=200&includeChildren=true${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'assemble', id })), { type: 'assemble', id: 'LIST' }]
          : [{ type: 'assemble', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getAssemble: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_ASSEMBLE}?page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'assemble', id })), { type: 'assemble', id: 'LIST' }]
          : [{ type: 'assemble', id: 'LIST' }]
    }),

    getClasses: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_CLASS}?page=${page}&size=200&includeChildren=true${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'class', id })), { type: 'class', id: 'LIST' }]
          : [{ type: 'class', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getMachineClasses: builder.query({
      // Группы станков
      query: ({ page, searchQuery }) =>
        searchQuery === ''
          ? `${process.env.REACT_APP_PARAM_MACHINE_CLASS}?page=${page}&size=200&includeChildren=true`
          : `${process.env.REACT_APP_PARAM_MACHINE_CLASS}?page=${page}&size=200&filter=id-${searchQuery}&includeChildren=true`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'machine', id })), { type: 'machine', id: 'LIST' }]
          : [{ type: 'machine', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getMachineClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_MACHINE_CLASS}?includeChildren=true&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'machine', id })), { type: 'machine', id: 'LIST' }]
          : [{ type: 'machine', id: 'LIST' }]
    }),

    createGroupMachines: builder.mutation({
      query: (group) => ({
        url: `${process.env.REACT_APP_PARAM_MACHINE_CLASS}`,
        method: 'POST',
        body: group
      }),
      invalidatesTags: ['machine']
    }),

    editGroupMachines: builder.mutation({
      query: (group) => ({
        url: `${process.env.REACT_APP_PARAM_MACHINE_CLASS}`,
        method: 'PUT',
        body: group
      }),
      invalidatesTags: ['machine']
    }),

    deleteGroupMachines: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_MACHINE_CLASS}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['machine']
    }),

    getMaterialGroups: builder.query({
      // Группы материалов
      query: ({ page, searchQuery }) =>
        searchQuery === ''
          ? `${process.env.REACT_APP_PARAM_MATERIAL_CLASS}?page=${page}&size=200&includeChildren=true`
          : `${process.env.REACT_APP_PARAM_MATERIAL_CLASS}?page=${page}&size=200&filter=id-${searchQuery}&includeChildren=true`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'material', id })), { type: 'material', id: 'LIST' }]
          : [{ type: 'material', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getMaterialClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_MATERIAL_CLASS}?includeChildren=true&page=${page}&size=200`,

      providesTags: (result) => {
        console.log(result);
        return result
          ? [...result.results.map(({ id }) => ({ type: 'material', id })), { type: 'material', id: 'LIST' }]
          : [{ type: 'material', id: 'LIST' }];
      }
    }),
    getMaterials: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_MATERIAL}?page=${page}&size=200&includeChildren=false${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'material', id })), { type: 'material', id: 'LIST' }]
          : [{ type: 'material', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getMaterialWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_MATERIAL}?includeChildren=true&page=${page}&size=200`,

      providesTags: (result) => {
        console.log(result);
        return result
          ? [...result.results.map(({ id }) => ({ type: 'material', id })), { type: 'material', id: 'LIST' }]
          : [{ type: 'material', id: 'LIST' }];
      }
    }),

    createGroupMaterials: builder.mutation({
      query: (group) => ({
        url: `${process.env.REACT_APP_PARAM_MATERIAL_CLASS}`,
        method: 'POST',
        body: group
      }),
      invalidatesTags: ['material']
    }),

    editGroupMaterials: builder.mutation({
      query: (group) => ({
        url: `${process.env.REACT_APP_PARAM_MATERIAL_CLASS}`,
        method: 'POST',
        body: group
      }),
      invalidatesTags: ['material']
    }),

    deleteGroupMaterial: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_MATERIAL_CLASS}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['material']
    }),

    getDetailClasses: builder.query({
      // Группы материалов
      query: ({ page, searchQuery }) =>
        searchQuery === ''
          ? `${process.env.REACT_APP_PARAM_DETAIL_CLASS}?page=${page}&size=200&includeChildren=true`
          : `${process.env.REACT_APP_PARAM_DETAIL_CLASS}?page=${page}&size=200&filter=id-${searchQuery}&includeChildren=true`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'detail', id })), { type: 'detail', id: 'LIST' }]
          : [{ type: 'detail', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getDetailClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_DETAIL_CLASS}?includeChildren=true&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'detail', id })), { type: 'detail', id: 'LIST' }]
          : [{ type: 'detail', id: 'LIST' }]
    }),

    createGroupDetails: builder.mutation({
      query: (group) => ({
        url: `${process.env.REACT_APP_PARAM_DETAIL_CLASS}`,
        method: 'POST',
        body: group
      }),
      invalidatesTags: ['detail']
    }),

    editGroupDetails: builder.mutation({
      query: (group) => ({
        url: `${process.env.REACT_APP_PARAM_DETAIL_CLASS}`,
        method: 'PUT',
        body: group
      }),
      invalidatesTags: ['detail']
    }),

    deleteGroupDetail: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_DETAIL_CLASS}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['detail']
    }),
    getCountries: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_COUNTRY}?page=${page}&size=200&includeChildren=false${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'country', id })), { type: 'country', id: 'LIST' }]
          : [{ type: 'country', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getCutGrade: builder.mutation({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_CUT_GRADE}?page=${page}&size=200`,
      providesTags: (result) => {
        return result
          ? [...result.results.map(({ id }) => ({ type: 'cut', id })), { type: 'cut', id: 'LIST' }]
          : [{ type: 'cut', id: 'LIST' }];
      }
    }),

    getBranchClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_BRANCH}?includeChildren=false&page=${page}&size=200`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'branch', id })), { type: 'branch', id: 'LIST' }]
          : [{ type: 'branch', id: 'LIST' }]
    }),

    getBranches: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_BRANCH}?page=${page}&size=200&includeChildren=true${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'branch', id })), { type: 'branch', id: 'LIST' }]
          : [{ type: 'branch', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getCompanies: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_COMPANY}?page=${page}&size=200&includeChildren=false${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'company', id })), { type: 'company', id: 'LIST' }]
          : [{ type: 'company', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getCompanyTypes: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_COMPANY_TYPE}?page=${page}&size=200&includeChildren=true${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'company', id })), { type: 'company', id: 'LIST' }]
          : [{ type: 'company', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getLanguages: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_LANGUAGE}?page=${page}&size=200&includeChildren=false${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'language', id })), { type: 'language', id: 'LIST' }]
          : [{ type: 'language', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getCountryClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_COUNTRY}?includeChildren=false&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'country', id })), { type: 'country', id: 'LIST' }]
          : [{ type: 'country', id: 'LIST' }]
    }),

    getCompanyClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_COMPANY}?includeChildren=false&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'company', id })), { type: 'company', id: 'LIST' }]
          : [{ type: 'company', id: 'LIST' }]
    }),

    getCompanyTypeClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_COMPANY_TYPE}?includeChildren=false&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'companyType', id })), { type: 'companyType', id: 'LIST' }]
          : [{ type: 'companyType', id: 'LIST' }]
    }),

    getLanguageClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_LANGUAGE}?includeChildren=false&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'language', id })), { type: 'language', id: 'LIST' }]
          : [{ type: 'language', id: 'LIST' }]
    }),

    getClassTypeClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_CLASS_TYPE}?includeChildren=false&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'classType', id })), { type: 'classType', id: 'LIST' }]
          : [{ type: 'classType', id: 'LIST' }]
    }),
    getClassTypes: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_CLASS_TYPE}?page=${page}&size=200&includeChildren=false${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'classType', id })), { type: 'classType', id: 'LIST' }]
          : [{ type: 'classType', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getCutGrades: builder.query({
      query: (args) => {
        const { page, searchQuery } = args;
        return `${process.env.REACT_APP_PARAM_CUT_GRADE}?page=${page}&size=200&includeChildren=true${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`;
      },
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'cutGrade', id })), { type: 'cutGrade', id: 'LIST' }]
          : [{ type: 'cutGrade', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getCutGradeClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_CUT_GRADE}?includeChildren=false&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'cutGrade', id })), { type: 'cutGrade', id: 'LIST' }]
          : [{ type: 'cutGrade', id: 'LIST' }]
    }),

    getAdapters: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_ADAPTER}?page=${page}&size=200&includeChildren=false${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'adapter', id })), { type: 'adapter', id: 'LIST' }]
          : [{ type: 'adapter', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getAdapterClassesWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_ADAPTER}?includeChildren=false&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'adapter', id })), { type: 'adapter', id: 'LIST' }]
          : [{ type: 'adapter', id: 'LIST' }]
    }),

    getMachineWithChildren: builder.query({
      query: (page = 1) => `${process.env.REACT_APP_PARAM_MACHINE}?includeChildren=false&page=${page}&size=200`,

      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'machine', id })), { type: 'machine', id: 'LIST' }]
          : [{ type: 'machine', id: 'LIST' }]
    }),

    getMachines: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_MACHINE}?page=${page}&size=200&includeChildren=false${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'machine', id })), { type: 'machine', id: 'LIST' }]
          : [{ type: 'machine', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getCutGradeGroups: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_CUT_GRADE_GROUP}?page=${page}&size=200&includeChildren=false${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'cutGradeGroup', id })), { type: 'cutGradeGroup', id: 'LIST' }]
          : [{ type: 'cutGradeGroup', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getList: builder.query({
      query: ({ page, searchQuery }) =>
        searchQuery === ''
          ? `${process.env.REACT_APP_PARAM_LIST}?page=${page}&size=200&includeChildren=true`
          : `${process.env.REACT_APP_PARAM_LIST}?page=${page}&size=200&filter=id-${searchQuery}&includeChildren=true`,

      providesTags: (result) =>
        result ? [...result.results.map(({ id }) => ({ type: 'list', id })), { type: 'list', id: 'LIST' }] : [{ type: 'list', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    })
  })
});

export const {
  useGetToolsQuery,
  useGetAssembleQuery,
  useGetClassesQuery,
  useGetMachineClassesWithChildrenQuery,
  useCreateGroupMachinesMutation,
  useEditGroupMachinesMutation,
  useDeleteGroupMachinesMutation,
  useGetMaterialClassesWithChildrenQuery,
  useCreateGroupMaterialsMutation,
  useEditGroupMaterialsMutation,
  useDeleteGroupMaterialMutation,
  useGetDetailClassesWithChildrenQuery,
  useCreateGroupDetailsMutation,
  useEditGroupDetailsMutation,
  useDeleteGroupDetailMutation,
  useGetCutGradeMutation,
  useGetListQuery,

  useGetBranchClassesWithChildrenQuery,

  useGetCountryClassesWithChildrenQuery,
  useGetCompanyTypeClassesWithChildrenQuery,
  useGetCompanyClassesWithChildrenQuery,
  useGetLanguageClassesWithChildrenQuery,
  useGetClassTypeClassesWithChildrenQuery,

  useGetCutGradeClassesWithChildrenQuery,
  useGetAdapterClassesWithChildrenQuery,
  useGetMachineWithChildrenQuery,
  useGetMaterialWithChildrenQuery
} = guidesApi;
