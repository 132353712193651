import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, InputNumber, Layout, message, Modal, Switch, Tooltip, Upload} from "antd";
import g from "../../general.module.scss";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import tool from '../../content/images/tool.svg'
import assembly from '../../content/images/assembly.svg'

export const ModalEditClassType = ({callback_open, callback_close, selectedClassType , allElements, setAllElements, setSelectedElement, setTotalElements}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        const result = body
        switch (result?.toolOrAssem?.length) {

            case 0:
                result.toolOrAssem = 0;
                break;
            case 1:
                result.toolOrAssem = result.toolOrAssem[0] === 'instrument' ? 1 : 2
                break;
            case 2:
                result.toolOrAssem = 3
                break;
            default:
                break;
        }
        console.log(result?.toolOrAssem?.length)

        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/classType`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    setAllElements({...allElements,results:allElements.results?.map(l=>l.id===result.id ?{...result}:l)});
                    setSelectedElement(body)

                    callback_close()
                    setIsWaitingResponse(false)
                    return res
                }
            })
    }

    const getBase64 = (file, setter) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setter(`${reader.result}`)
        };
    };

    const [base64Image, setBase64Image] = useState('')
    const [base64Image_assemble, setBase64Image_assemble] = useState('')

    useEffect(() => {
        setBase64Image(`${
            selectedClassType?.drawing &&
            selectedClassType?.drawing.includes('base64')
                ?
                selectedClassType?.drawing
                :
                'data:image/jpeg;base64, ' + selectedClassType?.drawing}` || '')
        setBase64Image_assemble(`${
            selectedClassType?.drawingAssemble &&
            selectedClassType?.drawingAssemble.includes('base64')
                ?
                selectedClassType?.drawingAssemble
                :
                'data:image/jpeg;base64, ' + selectedClassType?.drawingAssemble}` || '')
    }, [selectedClassType]);

    useEffect(() => {
        try {
            form.setFieldsValue({
                drawing: `${base64Image}`,
                drawingAssemble: `${base64Image_assemble}`
            });
        } catch (e) {
            console.log(e)
        }
    }, [base64Image, base64Image_assemble])

    const onChange = (e, setter) => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file, setter);
    };

    const [checkedFirstOption, setCheckedFirstOption] = useState(false)
    const [checkedSecondOption, setCheckedSecondOption] = useState(false)

    useEffect(() => {
        setCheckedFirstOption(selectedClassType?.toolOrAssem === 1 || selectedClassType?.toolOrAssem === 3)
        setCheckedSecondOption(selectedClassType?.toolOrAssem === 2 || selectedClassType?.toolOrAssem === 3)
    }, [selectedClassType])

    return (
        <Modal
            centered
            title={'Изменение типа класса'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            destroyOnClose
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={`${g.modal_layout} ${g.modal_layout_overflow}`}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                    name={'createBranch'}
                    initialValues={{
                        id: selectedClassType?.id,
                        name: selectedClassType?.name,
                        drawing: selectedClassType?.drawing,
                        disabled: selectedClassType?.disabled,
                        toolOrAssem:
                            selectedClassType?.toolOrAssem === 0 || !!!selectedClassType?.toolOrAssem ? []
                                : selectedClassType?.toolOrAssem === 1 ? ['instrument']
                                    : selectedClassType?.toolOrAssem === 2 ? ['assembly'] : ['instrument', 'assembly'],
                    }}
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="disabled"
                    label="Выключен (Может быть использовано для создания нового инструмента Да/Нет ?)"
                    valuePropName={'checked'}
                    rules={[
                        {required: false},
                    ]}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="drawing"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input type="file" id={'photoUploader'} onChange={(e) => onChange(e, setBase64Image)} className={g.modal_inputfile_input}/>
                        <img src={base64Image} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image === ''} onClick={() => {
                            document.getElementById("photoUploader").value = "";
                            setBase64Image('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
                <Form.Item
                    name="drawingAssemble"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input type="file" id={'photoUploader_assemble'} onChange={(e) => onChange(e, setBase64Image_assemble)} className={g.modal_inputfile_input}/>
                        <img src={base64Image_assemble} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image_assemble === ''} onClick={() => {
                            document.getElementById("photoUploader_assemble").value = "";
                            setBase64Image_assemble('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
                <Form.Item
                    name="toolOrAssem"
                    label="Используется для"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Checkbox.Group style={{width: '100%', justifyContent: 'space-between'}} className={g.checkbox_group}>
                        <Checkbox onClick={(event) => {
                            setCheckedFirstOption(event.target.checked)
                        }} value={'instrument'}/>
                        <div className={`${g.form_svg_fit} ${checkedFirstOption ? g.form_svg_fit_selected : ""}`} style={{backgroundImage: `url(${tool})`}}></div>
                        <Checkbox onClick={(event) => {
                            setCheckedSecondOption(event.target.checked)
                        }} value={'assembly'}/>
                        <div className={`${g.form_svg_fit} ${checkedSecondOption ? g.form_svg_fit_selected : ""}`} style={{backgroundImage: `url(${assembly})`}}></div>
                    </Checkbox.Group>
                </Form.Item>
            </Layout>
        </Modal>
    )
}

export const ModalCreateClassType = ({callback_open, callback_close, allElements, setAllElements, setTotalElements, setSelectedElement}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        console.log(body)
        const result = body
        switch (result?.toolOrAssem?.length) {
            case 0:
                result.toolOrAssem = 0;
                break;
            case 1:
                result.toolOrAssem = result.toolOrAssem[0] === 'instrument' ? 1 : 2
                break;
            case 2:
                result.toolOrAssem = 3
                break;
            default:
                break;
        }
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/classType`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    console.log(allElements)
                    setAllElements({...allElements, results: [...allElements.results, body]})
                    setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)


                    setIsWaitingResponse(false)
                    callback_close()
                    return res
                }
            })
            .catch(e => {
                message.error(`${e}`)
                setIsWaitingResponse(false)
            })
    }

    const getBase64 = (file, setter) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setter(`${reader.result}`)
        };
    }

    const [base64Image, setBase64Image] = useState('')
    const [base64Image_assemble, setBase64Image_assemble] = useState('')

    useEffect(() => {
        try {
            form.setFieldsValue({
                drawing: `${base64Image}`,
                drawingAssemble: `${base64Image_assemble}`,
            });
        } catch (e) {
            console.log(e)
        }
    }, [base64Image, base64Image_assemble])

    const onChange = (e, setter) => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file, setter);
    };



    const [checkedFirstOption, setCheckedFirstOption] = useState(false)
    const [checkedSecondOption, setCheckedSecondOption] = useState(false)


    return (
        <Modal
            centered
            title={'Создание типа класса'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={`${g.modal_layout} ${g.modal_layout_overflow}`}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="disabled"
                    label="Выключен (Может быть использовано для создания нового инструмента Да/Нет ?)"
                    valuePropName={'checked'}
                    rules={[
                        {required: false},
                    ]}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="drawing"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input type="file" id={'photoUploader'} onChange={(e) => onChange(e, setBase64Image)} className={g.modal_inputfile_input}/>
                        <img src={base64Image} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image === ''} onClick={() => {
                            document.getElementById("photoUploader").value = "";
                            setBase64Image('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
                <Form.Item
                    name="drawingAssemble"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input type="file" id={'photoUploader_assemble'} onChange={(e) => onChange(e, setBase64Image_assemble)} className={g.modal_inputfile_input}/>
                        <img src={base64Image_assemble} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image_assemble === ''} onClick={() => {
                            document.getElementById("photoUploader_assemble").value = "";
                            setBase64Image_assemble('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
                <Form.Item
                    name="toolOrAssem"
                    label="Используется для"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Checkbox.Group style={{width: '100%', justifyContent: 'space-between'}} className={g.checkbox_group}>
                        <Checkbox onClick={(event) => {
                            setCheckedFirstOption(event.target.checked)
                        }} value={'instrument'}/>
                        <div className={`${g.form_svg_fit} ${checkedFirstOption ? g.form_svg_fit_selected : ""}`} style={{backgroundImage: `url(${tool})`}}></div>
                        <Checkbox onClick={(event) => {
                            setCheckedSecondOption(event.target.checked)
                        }} value={'assembly'}/>
                        <div className={`${g.form_svg_fit} ${checkedSecondOption ? g.form_svg_fit_selected : ""}`} style={{backgroundImage: `url(${assembly})`}}></div>
                    </Checkbox.Group>
                </Form.Item>
            </Layout>
        </Modal>
    )
}