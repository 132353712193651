import { Modal, Input, Button, Form, message } from 'antd';
import { costCenterApi } from '../../services/costCenterApi';
import { useEffect, useState } from 'react';
import { useEditStorageMutation } from '../../services/costCenterApi';

export const RenameStorage = ({ editStorageOpen, setEditStorageOpen, storage }) => {
  const [form] = Form.useForm();

  const [editStorage] = useEditStorageMutation();

  const handleEdit = async (values) => {
    try {
      await editStorage({
        id: storage.id,
        body: { name: values.name }
      });
      message.success('Место хранения переименовано');
    } catch (error) {
      console.log(error);
      message.error('Не удалось переименовать');
    } finally {
      setEditStorageOpen(false);
    }
  };

  useEffect(() => {
    if (storage) {
      form.setFieldsValue(storage);
    }
  }, [storage]);

  return (
    <Modal footer={false} title="Переименовать место хранения" open={editStorageOpen} onCancel={() => setEditStorageOpen(false)}>
      <Form
        form={form}
        onFinish={handleEdit}
        name="basic"
        initialValues={{
          remember: true
        }}
        autoComplete="off">
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Переименовать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
