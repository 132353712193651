import { ArrowLeftOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Table, Tabs, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { General } from './general';
import Parameters from './parameters';
const { TabPane } = Tabs;

export const CreateInstrument = ({ allTools, setAllTools, tool, visible, onClose, setTool, setTotalElements }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [classesDrawer, setClassesDrawer] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    description: '',
    weight: '',
    countStage: '',
    countCuts: '',
    group: {},
    adapter: {},
    cutGrade: {},
    parameters: [],
    resources: [{}]
  });
  const [renderFlag, setRenderFlag] = useState(false);
  const fetchCreateInstrument = async (state) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARAM_TOOL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const json = await response.json();
        setAllTools([...allTools, { ...json }]);
        setTotalElements((prevValue) => prevValue + 1);
        setTool(json);
        if (state === true) {
          onClose();
        }
        message.success('Успешно создано');
      } else {
        message.error('Ошибка создания');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchEditInstrument = async (state) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARAM_TOOL}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        setAllTools(allTools?.map((l) => (l.id == tool.id ? { ...formData } : l)));
        setTool(formData);
        if (state === true) {
          onClose();
        }
        message.success('Успешно изменено');
      } else {
        message.error('Ошибка редактирования');
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    console.log(tool);
    if (tool !== null && tool !== undefined) {
      setRenderFlag(false);
      setFormData(tool);
      setSelectedClass(tool?.tmdClass);
      setSelectedGroup(tool?.group);
    }
  }, [tool, visible]);
  useEffect(() => {
    setRenderFlag(true);
  }, [renderFlag]);
  return (
    <Drawer
      width={'100vw'}
      placement="left"
      title={
        <Flex align="center" gap="60px">
          <Button onClick={onClose} size="large" icon={<ArrowLeftOutlined />} />
          <Tabs size="large" activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab="Общие" key="1" />
            <TabPane tab="Параметры" key="2" />
            <TabPane tab="Соединения инструментов" key="3" />
          </Tabs>
        </Flex>
      }
      open={visible}
      closeIcon={false}
      footer={
        <Flex align="center" justify="end" gap="middle">
          <Button
            onClick={() => {
              tool !== null && tool !== undefined ? fetchEditInstrument(false) : fetchCreateInstrument(false);
            }}
            icon={<SaveOutlined />}
            type="primary"
            size="large">
            Создать
          </Button>
          <Button
            onClick={() => {
              tool !== null && tool !== undefined ? fetchEditInstrument(true) : fetchCreateInstrument(true);
            }}
            size="large">
            Сохранить и закрыть
          </Button>
          <Button onClick={onClose} icon={<CloseCircleOutlined />} danger size="large">
            Отменить
          </Button>
        </Flex>
      }>
      <div>
        {activeTab === '1' && renderFlag && (
          <General
            tool={tool}
            fileList={fileList}
            setFileList={setFileList}
            uploading={uploading}
            setSelectedGroup={setSelectedGroup}
            selectedGroup={selectedGroup}
            classesDrawer={classesDrawer}
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
            setClassesDrawer={setClassesDrawer}
            formData={formData}
            setFormData={setFormData}
          />
        )}
        {activeTab === '2' && (
          <Parameters
            tool={tool}
            classesDrawer={classesDrawer}
            setClassesDrawer={setClassesDrawer}
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
            formData={formData}
            setFormData={setFormData}
            parameters={tool !== null && tool !== undefined ? tool?.parameters : selectedGroup?.parameters}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
        )}
        {activeTab === '3' && <div>Additional content for Tab 3</div>}
      </div>
    </Drawer>
  );
};
