import * as THREE from 'three'
import { Vector3 } from 'three'

const Line = ({ start, end, color = '#0c60ff' }) => {
	// Создаем массив вершин для линии
	const points = []
	points.push(new Vector3(...start))
	points.push(new Vector3(...end))

	// Создаем геометрию с этими точками
	const lineGeometry = new THREE.BufferGeometry().setFromPoints(points)

	return (
		<line renderOrder={1} geometry={lineGeometry} name={'HELP'}  >
			<lineBasicMaterial attach='material' color={color} depthTest={false} depthWrite={false} transparent={true} lineWidth={10000} />
		</line>
	)
}

export default Line