import React, {useEffect, useState} from 'react'
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import {guidesApi, useGetBranchClassesWithChildrenQuery} from "../../services/guidesApi";
import {ColorPicker, Flex, Layout, List, Table, Typography, message} from "antd";
import {
    ClearOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    HomeOutlined,
    PlusOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import g from "../../general.module.scss";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";
import {DrawerEditBranch, ModalCreateBranch, ModalEditBranch} from "./BranchModals";
const { Title, Text } = Typography;

const Branch = () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);
    const [refetch, setRefetch] = useState(null)

    const [refetchList, setRefetchList] = useState();

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetchList(!refetchList)
    }

    const [openModalEditBranch, setOpenModalEditBranch] = useState(false)
    const handleCloseModalEditBranch = () => {
        setOpenModalEditBranch(false)
        setRefetchList(!refetchList)
    }

    const [openModalCreateBranch, setOpenModalCreateBranch] = useState(false)
    const handleCloseModalCreateBranch = () => {
        setOpenModalCreateBranch(false)
        setRefetchList(!refetchList)
    }

    useEffect(() => {
        if (typeof refetchList === 'boolean') {
            message.info('refetching list... (empty code)')
        }
    }, [refetchList]);

    // const { data: branches, isFetching, isError } = useGetBranchClassesWithChildrenQuery(currentPage);
    const [search, {data: branches, isFetching, isError}] = guidesApi.endpoints.getBranches.useLazyQuery();
    const [allData, setAllData] = useState(null)

    const handleToolClick = (branch) => {
        console.log(branch);
        setSelectedBranchId(branch.id);
        setSelectedBranch(branch);

        // const parameters = branch.branches.map((item) => ({
        //     key: item.id,
        //     ...item
        // }));
        //
        // setParameters(parameters);
        //
        // setInformation([
        //     { name: 'ID', value: branch.id },
        //     { name: 'Наименование', value: branch.name }
        // ]);
    };

    useEffect(() => {
        if (branches && totalElements === 0) setTotalElements(branches.totalElements);
        setAllData(branches)
    }, [branches]);

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => { setOpenModalCreateBranch(true) }
        },
        {
            icon: <EditOutlined />,
            disabled: selectedBranch === null,
            onClick: () => { setOpenModalEditBranch(true) }
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedBranch === null,
            onClick: () => { setOpenModalConfirmDelete(true) }
        },
    ]

    const handleDeleteGroup = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/branch/${selectedBranchId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    message.success('Элемент удален!')
                    setOpenModalConfirmDelete(false)
                    message.info('refetching...')
                    return res
                }
            })
    }

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedBranch}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}
        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedBranch && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedBranch?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedBranch?.name}</Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{flexGrow: 2}}>
                <Layout style={{height: '50%', overflow: 'auto', position: 'relative'}} className={g.container_block}>
                    <Table
                        title={() => <Title level={4}>Распределение соединений</Title>}
                        pagination={false}
                        dataSource={parameters}
                        columns={[]}
                    />
                </Layout>
            </Flex>

            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/branch/${selectedBranchId}`}

                setAllElements={setAllData}
                selectedElement={selectedBranch}
                setSelectedElement={setSelectedBranch}
                setTotalElements={setTotalElements}
            />
            <ModalEditBranch
                callback_open={openModalEditBranch}
                callback_close={handleCloseModalEditBranch}

                selectedBranch={selectedBranch}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedBranch}
            />
            <ModalCreateBranch
                callback_open={openModalCreateBranch}
                callback_close={handleCloseModalCreateBranch}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedBranch}
            />
        </ContainerAside>
    )
}

export default Branch