import { Modal, Input, Button, Form, InputNumber, message } from 'antd';
import { useEffect } from 'react';
import { useEditAssembleMutation } from '../../../services/assemblyTools';

export const EditToolAssembly = ({ visible, onClose, currentValues, refetchAssembleInfo }) => {
  const [form] = Form.useForm();

  const [editAssemble] = useEditAssembleMutation();

  const handleUpdate = async (values) => {
    try {
      await editAssemble({ ...values, group: { id: currentValues.group.id } }).unwrap();
      await refetchAssembleInfo();
      form.resetFields();
      onClose();
      message.success('Сборка успешно обновлена');
    } catch (error) {
      console.log(error);
      message.error('Не удалось обновить сборку');
    }
  };

  useEffect(() => {
    if (currentValues) {
      form.setFieldsValue(currentValues);
    }
  }, [currentValues, form]);

  return (
    <Modal footer={false} title="Редактирование сборки" open={visible} onCancel={onClose}>
      <Form form={form} name="basic" onFinish={handleUpdate}>
        <Form.Item
          label="ID"
          name="id"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Описание"
          name="description"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Вес"
          name="weight"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <InputNumber min="0" step="0.1" stringMode />
        </Form.Item>
        <Form.Item
          label="Количество режущих кромок"
          name="cutCount"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <InputNumber min={0} />
        </Form.Item>
        {/* <Form.Item
          label="Тип"
          name="type"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <InputNumber min={0} />
        </Form.Item> */}
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Обновить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
