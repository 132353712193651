import { Image } from 'antd';
import React, { useEffect, useState } from 'react';

export const Base64ToImage = ({ url }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((res) => res.text())
        .then((text) => {
          setImageSrc(text);
        });
    }
  }, [url]);

  return <Image style={{ maxWidth: '100px' }} src={imageSrc ? `data:image/png;base64,${imageSrc}` : ''} />;
};
