import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: localStorage.getItem('accessToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  idToken: localStorage.getItem('idToken') || null, // Добавляем idToken
  isAuthenticated: !!localStorage.getItem('accessToken')
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.idToken = action.payload.idToken;
      state.isAuthenticated = true;

      localStorage.setItem('accessToken', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      localStorage.setItem('idToken', action.payload.idToken);
    },
    logout(state) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('idToken');

      state.accessToken = null;
      state.refreshToken = null;
      state.idToken = null;
      state.isAuthenticated = false;
    },
    refreshToken(state, action) {
      state.accessToken = action.payload.accessToken;

      localStorage.setItem('accessToken', action.payload.accessToken);
    }
  }
});

export const { login, logout, refreshToken } = authSlice.actions;
export default authSlice.reducer;
