import { CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Drawer, Popconfirm, Typography, message, Flex } from 'antd';
import { useState, useEffect } from 'react';
import { AvailableAssemblyTools } from '../AvailableAssemblyTools/AvailableAssemblyTools';
import { SceneAssembly } from '../../sceneAssembly';
import { useAddToolToAssembleMutation } from '../../../services/assemblyTools';
import { ListOfTools } from '../../ListOfTools/ListOfTools';

export const BuildingAnAssembly = ({ assembleInfo, visible, onClose, refetchAssembleInfo,stpName, setStpName}) => {
  const [blob3D, setBlob3D] = useState(null);
  const [listOfAvailableToolsVisible, setListOfAvailableToolsVisible] = useState(false);
  const [listOfToolsVisible, setListOfToolsVisible] = useState(false);
  const [stpURLs, setStpURLs] = useState([]);
  const [gridBuldingAnAssembly, setGridBuldingAnAssembly] = useState(0);
  const [localTools, setLocalTools] = useState([]);
  const [boxModelCard, setBoxModelCard] = useState(null);


  const [addtool] = useAddToolToAssembleMutation();

  const handleAddTool = (newTool) => {
    if (newTool.resources.length === 0) message.warning('У добавляемого инструмента отсутствует 3D модель');
    console.log(newTool);

    const updatedTools = [{ tool: { ...newTool }, pos: 1, quantity: newTool.quantity }, ...localTools];

    setLocalTools(updatedTools);
  };

  const handleDeleteTool = (toolToRemove) => {
    const filteredTools = localTools.filter((el) => el.tool.id !== toolToRemove.tool.id); 
    console.log('handleDeleteTool')
    
    setLocalTools(filteredTools);
   
  };

  const handleSaveTools = async () => {
    try {
      const updatedTools = localTools.map((item, index) => ({
        tool: { id: item.tool.id },
        pos: index + 1,
        quantity: item.quantity
      }));

      await addtool({ assembleId: assembleInfo.id, body: updatedTools }).unwrap();
      refetchAssembleInfo();
      message.success(`Изменения сохранены успешно`);
    } catch (error) {
      console.log(error);
      message.error('Не удалось сохранить изменения в сборке');
    }
  };

  useEffect(() => {
    if (assembleInfo && visible) {
      const assembleToolsSortered = [...assembleInfo.assembleTools].sort((a, b) => a.pos - b.pos);
      setLocalTools(assembleToolsSortered);
    }
  }, [visible]);

  useEffect(() => {
    if (localTools) {
      const stepUrlsArray = localTools.flatMap((item) =>
        item.tool.resources
          .filter((resource) => resource.content_type === 'model/step')
          .map((resource) => `${process.env.REACT_APP_BASE_URL}/guide/` + resource.url)
      );

      setStpURLs(stepUrlsArray);
      const nameSTP= localTools.flatMap((item) =>
        item.tool.id
      );
  
    setStpName(nameSTP?.reverse())
    setBoxModelCard("{}")


    }
  }, [localTools]);
  const handleCardClick = (el) => {
    console.log(el.tool.id)
    setBoxModelCard(el.tool.id)
  }
  return (
    <Drawer
      width={'100vw'}
      placement="left"
      open={visible}
      closeIcon={false}
      footer={
        <Flex justify="end" gap={'small'}>
          <Button type="primary" icon={<SaveOutlined />} onClick={handleSaveTools} style={{ marginTop: '16px' }}>
            Сохранить
          </Button>
          <Button danger icon={<CloseOutlined />} onClick={onClose} style={{ marginTop: '16px' }}>
            Отменить
          </Button>
        </Flex>
      }
      title={
        <Typography.Title level={3} style={{ margin: 0 }}>
          Построение сборки
        </Typography.Title>
      }>
      <AvailableAssemblyTools // Доступные инструменты
        open={listOfAvailableToolsVisible}
        onClose={() => setListOfAvailableToolsVisible(false)}
        localTools={localTools}
        setTool={(newTool) => {
          handleAddTool(newTool);
          setListOfAvailableToolsVisible(false);
        }}
      />

      <ListOfTools
        open={listOfToolsVisible}
        onClose={() => setListOfToolsVisible(false)}
        setListOfTools={(newTool) => {
          handleAddTool(newTool);
          setListOfToolsVisible(false);
        }}
      />
      <Flex vertical gap={'small'} style={{ width: '100%', height: '100%' }}>
        <Flex gap="middle" justify="center" align="center" style={{ overflowX: 'auto', width: '100%', height: '30%' }}>
          <Button
            onClick={() => (localTools.length > 0 ? setListOfAvailableToolsVisible(true) : setListOfToolsVisible(true))}
            size="large"
            icon={<PlusOutlined />}
            style={{ minWidth: '40px' }}
          />
          {localTools.map((el) => (
            <Card
              key={el.tool.id}
              onClick={() => handleCardClick(el)} 
              extra={
                <Popconfirm
                  title="Вы уверены, что хотите удалить инструмент из сборки?"
                  onConfirm={() => handleDeleteTool(el)}
                  okText="Да"
                  cancelText="Нет">
                  <Button icon={<DeleteOutlined />} danger />
                </Popconfirm>
              }
              hoverable
              style={{ textAlign: 'center', border: '2px solid #f0f0f0' }}
              bordered={false}>
              <Flex vertical>
                <Typography.Text strong style={{ color: '#A0D911' }}>
                  {el.tool.id}
                </Typography.Text>
                <Typography.Text>{el.tool.name}</Typography.Text>
              </Flex>
            </Card>
          ))}
        </Flex>
        <Flex style={{ width: '100%', height: '100%', border: '1px solid rgba(5, 5, 5, 0.06)' }}>
          <SceneAssembly
            style={{ width: '100%', height: '100%' }}
            setBlob3D={setBlob3D}
            urlAssembly={stpURLs}
            setUrlAssembly={setStpURLs}
            gridZ={gridBuldingAnAssembly}
            setGridZ={setGridBuldingAnAssembly}
            stpName={stpName} 
            setStpName={setStpName}
            BoxModelCard={boxModelCard}
            setBoxModelCard={setBoxModelCard}
          />
        </Flex>
      </Flex>
    </Drawer>
  );
};
