import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, InputNumber, Layout, message, Modal, Switch, Upload} from "antd";
import g from "../../general.module.scss";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import tool from '../../content/images/tool.svg'
import assembly from '../../content/images/assembly.svg'

export const ModalEditCountry = ({callback_open, callback_close, selectedCountry, allElements, setAllElements, setSelectedElement, setTotalElements}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/country`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    setAllElements({...allElements, results: [...allElements.results.filter(f => f.id !== selectedCountry.id), body]})
                    // setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    callback_close()
                    setIsWaitingResponse(false)
                    return res
                }
            })
    }

    return (
        <Modal
            centered
            title={'Изменение адаптера'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            destroyOnClose
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                    name={'createBranch'}
                    initialValues={{
                        id: selectedCountry?.id,
                        name: selectedCountry?.name,
                    }}
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Layout>
        </Modal>
    )
}

export const ModalCreateCountry = ({callback_open, callback_close, allElements, setAllElements, setTotalElements, setSelectedElement}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        console.log(body)
        const result = body
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/country`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    console.log(allElements)
                    setAllElements({...allElements, results: [...allElements.results, body]})
                    setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    setIsWaitingResponse(false)
                    callback_close()
                    return res
                }
            })
            .catch(e => {
                message.error(`${e}`)
                setIsWaitingResponse(false)
            })
    }

    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64Image(`${reader.result}`)
        };
    };
    const [base64Image, setBase64Image] = useState('')

    useEffect(() => {
        try {
            form.setFieldsValue({
                drawing: `${base64Image}`,
            });
        } catch (e) {
            console.log(e)
        }
    }, [base64Image])

    const onChange = e => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };


    return (
        <Modal
            centered
            title={'Создание адаптера'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Layout>
        </Modal>
    )
}