import { Modal, Input, Button, Form } from 'antd';
import { useEffect } from 'react';

export const EditUser = ({ onSubmit, visible, onClose, currentValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentValues) {
      form.setFieldsValue(currentValues);
    }
  }, [currentValues, form]);

  const handleUpdate = (values) => {
    onSubmit(values);
    onClose();
  };

  return (
    <Modal footer={false} title="Редактирование пользователя" open={visible} onCancel={onClose}>
      <Form form={form} onFinish={handleUpdate} name="basic" autoComplete="off">
        <Form.Item
          label="Имя"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Фамилия"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!',
              type: 'email'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button htmlType="submit" type="primary">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
