import occtimportjs from 'occt-import-js';
import * as THREE from 'three';
const wasmUrl = process.env.PUBLIC_URL + '/occt-import-js.wasm';
// console.log(wasmUrl)
export async function LoadStep(base64FileUrl, setBlob3D) {
  try {
    const targetObject = new THREE.Object3D();

    // init occt-import-js
    const occt = await occtimportjs({
      locateFile: (name) => {
        // return occtimportWasm
        return wasmUrl;
      }
    });

    // Fetch and decode the base64 data
    console.log(base64FileUrl)
    const response = await fetch(base64FileUrl);
    const base64Data = await response.text();
    const buffer = new Uint8Array(
      atob(base64Data)
        .split('')
        .map((c) => c.charCodeAt(0))
    );
    // Декодируем base64 строку в бинарные данные
    const binaryString = window.atob(base64Data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    // Создаем Blob из бинарных данных
    // const blob = new Blob([bytes], { type: 'application/octet-stream' });
    // setBlob3D(blob);

    // Read the imported STEP file
    const result = occt.ReadStepFile(buffer);

    // Process the geometries of the result
    for (const resultMesh of result.meshes) {
      const geometry = new THREE.BufferGeometry();

      geometry.setAttribute('position', new THREE.Float32BufferAttribute(resultMesh.attributes.position.array, 3));
      if (resultMesh.attributes.normal) {
        geometry.setAttribute('normal', new THREE.Float32BufferAttribute(resultMesh.attributes.normal.array, 3));
      }
      const index = Uint16Array.from(resultMesh.index.array);
      geometry.setIndex(new THREE.BufferAttribute(index, 1));

      let material = null;
      if (resultMesh.color) {
        const color = new THREE.Color(resultMesh.color[0], resultMesh.color[1], resultMesh.color[2]);
        material = new THREE.MeshPhongMaterial({ color: color });
      } else {
        material = new THREE.MeshPhongMaterial({ color: 0xcccccc });
      }

      const mesh = new THREE.Mesh(geometry, material);
      targetObject.add(mesh);
    }

    return targetObject;
  } catch (e) {
    console.log(e);
  }
}
