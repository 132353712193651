import React, {useEffect, useState} from 'react'
import ca from './ContainerAside.module.scss'
import {Button, Descriptions, Flex, Image, Layout, List, Skeleton} from "antd";
import Search from "antd/es/input/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import {useGetToolsQuery} from "../../services/guidesApi";
import g from "../../general.module.scss";
import {Toolbar} from "../toolbar";
/**
 * Возвращает боковую панель.
 *
 * @param {React.useState | Array} dataSource полученный объект значений
 * @param {ResultType} search объект поиска, полученный из LazyQueryTrigger
 * @param {ResultType | boolean} isFetching переменная, полученная из LazyQuery
 * @param {ResultType | boolean} isError переменная, полученная из LazyQuery
 * @param {Array} toolbarItems массив кнопок в тулбаре
 * @param {String} field_title какое поле объекта будет на заголовке элемента
 * @param {String} field_description какое поле объекта будет описанием
 * @param {Function} handleElementClick функция обработки нажатия на конкретный элемент из списка
 * @param {React.useState | Object} selectedItem state выбранного элемента
 * @param props остальные атрибуты
 * @return {React.FunctionComponent} HTML сайдбар с панелью в content
 */
const ContainerAside = ({
                            dataSource = null,
                            setDataSource=()=>{},
                            search,
                            handleElementClick = () => { message.error('Не передан параметр handleElementClick в ContainerAside') },
                            selectedItem = null,
                            isFetching,
                            isError,
                            toolbarItems= [],
                            field_title = 'name',
                            field_description = 'description',
                            refetch = null,
                            totalElements=0,
                            setTotalElements=()=>{},

                            ...props
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (currentPage > 1) search({page: currentPage, searchQuery});
    }, [currentPage]);

    useEffect(() => {
        if (searchQuery !== '') {
            search({ page: 1, searchQuery });
        } else {
            search({ page: 1, searchQuery: '' });
        }
    }, [searchQuery]);

    useEffect(() => {
        if (searchQuery !== '') {
            setTotalElements(dataSource?.totalElements)
        }
    }, [dataSource]);

    return (
        <Layout className={ca.container} style={{backgroundColor: '#f0f2f5'}}>
            {console.log('DATAOUSRCE -> ', dataSource)}
            <Flex gap={'small'} vertical className={ca.container_leftSide}>
                <Layout className={ca.container_leftSide_header}>
                    <Search
                        size={'large'}
                        onSearch={(e) => setSearchQuery(e)}
                        onPressEnter={(e) => setSearchQuery(e.target.value)}
                        enterButton
                    />
                </Layout>
                <Descriptions
                    size={'small'}
                    bordered={false}
                    column={2}
                    items={[
                        {
                            key: '1',
                            label: 'Всего',
                            children: isFetching ? <Skeleton.Input size={'small'} style={{height: '22px', width: '44px', minHeight: 'unset', minWidth: 'unset'}} /> : totalElements || 0
                        },
                        {
                            key: '2',
                            label: 'Загружено',
                            children: isFetching ? <Skeleton.Input size={'small'} style={{height: '22px', width: '44px', minHeight: 'unset', minWidth: 'unset'}} /> : dataSource?.results?.length || totalElements || 0
                        }
                    ]}
                />
                <Flex id={'leftSide_scrollableDiv'} gap={'small'} vertical className={ca.container_leftSide_scrollBlock}>
                    <InfiniteScroll
                        dataLength={dataSource?.results?.length || 0}
                        next={() => { !isFetching && !isError ? setCurrentPage((prevPage) => prevPage + 1) : "" }}
                        hasMore={dataSource?.results?.length < totalElements}
                        loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                        scrollableTarget={"leftSide_scrollableDiv"}
                    >
                        <List
                            loading={isFetching}
                            dataSource={dataSource?.results}
                            renderItem={(item) => (
                                <List.Item
                                    className={ca.list_item}
                                    key={item.id}
                                    onClick={() => handleElementClick(item)}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: item.id === selectedItem?.id ? '#fcffe6' : 'transparent'
                                    }}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            item?.drawing !== undefined && item?.drawing !== "" ?
                                            <img
                                                width={70}
                                                height={70}
                                                src={item?.drawing.includes('http') || item?.drawing.includes('base64') ? item?.drawing : `data:image/jpeg;base64, ${item?.drawing}`}
                                            /> : <Skeleton.Image size={'small'} style={{ width: '70px', height: '70px' }} />
                                        }
                                        title={item[field_title]}
                                        description={item[field_description]}
                                    />
                                </List.Item>
                            )}
                        />
                    </InfiniteScroll>
                </Flex>
            </Flex>
            <Layout className={ca.container_rightSide}>
                {toolbarItems.length !== 0 ? <Toolbar className={g.toolbar} toolbarItems={toolbarItems} /> : ""}
                <Flex className={g.container} gap="small" style={{ width: '100%', height: 'calc(100% - 53px)' }}>
                    {props.children}
                </Flex>
            </Layout>
        </Layout>
    )
}

export default ContainerAside