import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';

const SettingsScene = () => {
  // console.log('SettingsScene')
  const { gl, camera, scene } = useThree();
  useEffect(() => {
    camera.up.set(0, 0, 1);
    // console.log(camera
    camera.position.set(165.2, -156.2, 87.2);
  }, []);
  return (
    <>
      <directionalLight position={[-165.2, -156.2, 87.2]} intensity={0.5} />
      <directionalLight position={[165.2, 156.2, -87.2]} intensity={0.5} />
      <directionalLight position={[165.2, -156.2, -87.2]} intensity={0.5} />
      <directionalLight position={[165.2, 156.2, 87.2]} intensity={0.5} />
    </>
  );
};

export default SettingsScene;
