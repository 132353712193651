import React, {useEffect, useState} from 'react'
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import {ColorPicker, Flex, List, message, Table, Typography} from "antd";
const { Title, Text } = Typography;
import {guidesApi, useGetLanguageClassesWithChildrenQuery} from "../../services/guidesApi";
import {
    ClearOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    HomeOutlined,
    PlusOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import g from "../../general.module.scss";
import {ModalCreateLanguage, ModalEditLanguage} from "./LanguageModals";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";

const Language = () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedLanguageId, setSelectedLanguageId] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);

    const [refetchList, setRefetchList] = useState();
    const [refetch, setRefetch] = useState(null)

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetchList(!refetchList)
    }

    const [openModalEditLanguage, setOpenModalEditLanguage] = useState(false)
    const handleCloseModalEditLanguage = () => {
        setOpenModalEditLanguage(false)
        setRefetchList(!refetchList)
    }

    const [openModalCreateLanguage, setOpenModalCreateLanguage] = useState(false)
    const handleCloseModalCreateLanguage = () => {
        setOpenModalCreateLanguage(false)
        setRefetchList(!refetchList)
    }

    // const { data: languages, isFetching, isError } = useGetLanguageClassesWithChildrenQuery(currentPage);
    const [search, {data: languages, isFetching, isError}] = guidesApi.endpoints.getLanguages.useLazyQuery();


    const handleToolClick = (branch) => {
        console.log(branch);
        setSelectedLanguageId(branch.id);
        setSelectedLanguage(branch);

        // const parameters = branch.branches.map((item) => ({
        //     key: item.id,
        //     ...item
        // }));
        //
        // setParameters(parameters);
        //
        // setInformation([
        //     { name: 'ID', value: branch.id },
        //     { name: 'Наименование', value: branch.name }
        // ]);
    };

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => {
                setOpenModalCreateLanguage(true)
            }
        },
        {
            icon: <EditOutlined />,
            disabled: selectedLanguage === null,
            onClick: () => {
                setOpenModalEditLanguage(true)
            }
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedLanguage === null,
            onClick: () => {
                setOpenModalConfirmDelete(true)
            }
        },
    ]

    const handleDeleteGroup = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/language/${selectedLanguageId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    message.success('Элемент удален!')
                    setOpenModalConfirmDelete(false)
                    message.info('refetching...')
                    return res
                }
            })
    }

    useEffect(() => {
        if (languages && totalElements === 0) setTotalElements(languages.totalElements);
        setAllData(languages)
    }, [languages]);

    const [allData, setAllData] = useState(null)

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedLanguage}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}
        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedLanguage && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedLanguage?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedLanguage?.name}</Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{flexGrow: 2}} className={g.container_block}>
                <div style={{height: '50%', padding: '10px', overflow: 'auto', position: 'relative', background: 'white'}}>
                    <Table
                        title={() => <Title level={4}>Распределение соединений</Title>}
                        pagination={false}
                        dataSource={parameters}
                        columns={[]}
                    />
                </div>
            </Flex>

            <ModalCreateLanguage 
                callback_open={openModalCreateLanguage}
                callback_close={handleCloseModalCreateLanguage}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedLanguage}
            />
            <ModalEditLanguage
                callback_open={openModalEditLanguage}
                callback_close={handleCloseModalEditLanguage}

                selectedLanguage={selectedLanguage}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedLanguage}
            />
            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/language/${selectedLanguageId}`}

                setAllElements={setAllData}
                selectedElement={selectedLanguage}
                setSelectedElement={setSelectedLanguage}
                setTotalElements={setTotalElements}
            />
        </ContainerAside>
    )
}

export default Language