import { Layout, Flex, Image, Typography, Input, Table, List, Skeleton, Button, message, Descriptions } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { guidesApi, useGetClassesQuery } from '../../services/guidesApi';
import { useEffect, useState } from 'react';
import { Toolbar } from '../../components/toolbar';
import { ClearOutlined, CopyOutlined, DeleteOutlined, EditOutlined, HomeOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import TableGroup from '../../components/Table/TableGroup';
import TableParams from '../../components/Table/TableParams';
import TableGroupParams from '../../components/Table/TableGroupParams';
import ContainerAside from '../../components/ContainerAside/ContainerAside';
import g from '../../general.module.scss';
import CreateClass from '../../components/createClass/createClass';
import { ModalCreateClassGroups, ModalEditClassGroups } from './ClassGroupsModals';
import { CreateGroup } from '../../components/createGroup';
import CreateGroupDrawer from '../../components/createGroup/createGroup';
import ca from './../../components/ContainerAside/ContainerAside.module.scss';
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete';

const { Title, Text } = Typography;
const { Search } = Input;

export const ClassesAndGroups = () => {
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [allClasses, setAllClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [parameters, setParameters] = useState([]);
  const [groups, setGroups] = useState([]);
  const [updateParams, setUpdateParams] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const handleClassClick = (el) => {
    setSelectedClass(el);
    setSelectedClassId(el.id);
    console.log(el);
  };
  useEffect(() => {
    if (selectedClass !== null) {
      setRerenderFlag(false);
      setGroups(selectedClass?.groups);
      setParameters(selectedClass?.parameters);
    }
  }, [selectedClass]);
  useEffect(() => {
    setRerenderFlag(true);
  }, [rerenderFlag]);
  const [search, { data: classes, isFetching, isError }] = guidesApi.endpoints.getClasses.useLazyQuery();

  const loadMoreData = () => {
    if (!isFetching && !isError) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const fetchUpdateParams = async () => {
    const transformedData = updateParams.reduce((acc, item) => {
      const { groupId, ...parameters } = item;
      const existingGroup = acc.find((group) => group.id === groupId);

      if (existingGroup) {
        existingGroup.parameters.push(parameters);
      } else {
        acc.push({ id: groupId, parameters: [parameters] });
      }

      return acc;
    }, []);
    console.log(JSON.stringify(transformedData));
    fetch(`${process.env.REACT_APP_BASE_URL}/guide/${selectedClass?.id}/updateParam`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(transformedData)
    })
      .then((res) => {
        console.log(res);
        if (res.status <= 202) {
          return res.json();
        } else {
          message.error('Ошибка в изменении параметров');
        }
      })
      .then((data) => {
        message.success('Параметры успешно изменены');
        setAllClasses(allClasses.map((l) => (l.id == selectedClass.id ? { ...selectedClass, groups: data } : l)));
        console.log(data, selectedClass);
      });
  };
  const [openModalEditGroup, setOpenModalEditGroup] = useState(false);
  const handleCloseModalEditBranch = () => {
    setOpenModalEditGroup(false);
  };
  useEffect(() => {
    console.log(allClasses);
  }, [allClasses]);
  const [openModalCreateGroup, setOpenModalCreateGroup] = useState(false);
  const handleCloseModalCreateBranch = () => {
    setOpenModalCreateGroup(false);
  };
  const [openModalCreateParams, setOpenModalCreateParams] = useState(false);
  const handleCloseModalCreateParams = () => {
    setOpenModalCreateParams(false);
  };
  useEffect(() => {
    if (currentPage > 1) search({ page: currentPage, searchQuery });
  }, [currentPage]);
  useEffect(() => {
    if (searchQuery !== '') {
      search({ page: 1, searchQuery });
    } else {
      search({ page: 1, searchQuery: '' });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (classes && totalElements === 0) setTotalElements(classes?.totalElements);

    if (classes && classes.results && currentPage !== 1) {
      setAllClasses((prevClasses) => [...prevClasses, ...classes.results]);
    } else if (classes && classes.results && (searchQuery || currentPage === 1)) {
      setAllClasses(classes.results);
    }
  }, [classes]);
  const toolbarItems = [
    {
      icon: <HomeOutlined />,
      onClick: () => {}
    },
    {
      icon: <ReloadOutlined />,
      onClick: () => {}
    },
    {
      icon: <PlusOutlined />,
      onClick: () => {
        setOpenCreate(true);
      }
    },
    {
      icon: <EditOutlined />,
      onClick: () => {
        setOpenEdit(true);
      }
    },
    {
      icon: <CopyOutlined />,
      onClick: () => {}
    },
    {
      icon: <DeleteOutlined />,
      onClick: () => {}
    },
    {
      icon: <ClearOutlined />,
      onClick: () => {}
    }
  ];
  const [openModalConfirmDeleteParams, setOpenModalConfirmDeleteParams] = useState(false);
  const handleCloseModalConfirmDeleteParams = () => {
    setOpenModalConfirmDeleteParams(false);
  };
  const handleDeleteParameter = () => {
    const formData = { ...selectedClass, parameters: selectedClass.parameters.filter((i) => i.name !== selectedParameter.name) };
    fetch(`${process.env.REACT_APP_BASE_URL}/guide/class`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(formData)
    })
      .then((res) => {
        if (res.status === 200) {
          setSelectedClass(null);
          return res.json();
        } else {
          message.error('Ошибка создания');
          setOpenModalConfirmDeleteParams(false);
        }
      })
      .then((data) => {
        console.log(data);
        setAllClasses(allClasses.map((l) => (l.id == selectedClass.id ? { ...data } : l)));
        setSelectedClass(data);
        setSelectedParameter(null);
        setOpenModalConfirmDeleteParams(false);
        message.success(`Успешно создано`);
      });
  };
  const [openModalConfirmDeleteGroups, setOpenModalConfirmDeleteGroups] = useState(false);
  const handleCloseModalConfirmDeleteGroups = () => {
    setOpenModalConfirmDeleteGroups(false);
  };
  const handleDeleteGroup = () => {
    const formData = { ...selectedClass, groups: selectedClass.groups.filter((i) => i.id !== selectedGroup.id) };
    fetch(`${process.env.REACT_APP_BASE_URL}/guide/class`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(formData)
    })
      .then((res) => {
        if (res.status === 200) {
          setSelectedClass(null);
          return res.json();
        } else {
          message.error('Ошибка создания');
          setOpenModalConfirmDeleteGroups(false);
        }
      })
      .then((data) => {
        console.log(data);
        setAllClasses(allClasses.map((l) => (l.id == selectedClass.id ? { ...data } : l)));
        setSelectedClass(data);
        setSelectedGroup(null);
        setOpenModalConfirmDeleteGroups(false);
        message.success(`Успешно создано`);
      });
  };
  return (
    <Layout className={ca.container} style={{ backgroundColor: '#f0f2f5' }}>
      <Flex gap={'small'} vertical className={ca.container_leftSide}>
        <Layout className={ca.container_leftSide_header}>
          <Search size={'large'} onSearch={(e) => setSearchQuery(e)} onPressEnter={(e) => setSearchQuery(e.target.value)} enterButton />
        </Layout>
        <Descriptions
          size={'small'}
          bordered={false}
          column={2}
          items={[
            {
              key: '1',
              label: 'Всего',
              children: isFetching ? (
                <Skeleton.Input size={'small'} style={{ height: '22px', width: '44px', minHeight: 'unset', minWidth: 'unset' }} />
              ) : (
                totalElements || 0
              )
            },
            {
              key: '2',
              label: 'Загружено',
              children: isFetching ? (
                <Skeleton.Input size={'small'} style={{ height: '22px', width: '44px', minHeight: 'unset', minWidth: 'unset' }} />
              ) : (
                allClasses?.length || totalElements || 0
              )
            }
          ]}
        />
        <Flex id={'leftSide_scrollableDiv'} gap={'small'} vertical className={ca.container_leftSide_scrollBlock}>
          <InfiniteScroll
            dataLength={allClasses?.length}
            next={() => {
              !isFetching && !isError ? setCurrentPage((prevPage) => prevPage + 1) : '';
            }}
            hasMore={allClasses?.length < totalElements}
            loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
            scrollableTarget={'leftSide_scrollableDiv'}>
            <List
              loading={isFetching}
              dataSource={allClasses}
              renderItem={(item) => (
                <List.Item
                  className={ca.list_item}
                  key={item.id}
                  onClick={() => handleClassClick(item)}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: item.id === selectedClass?.id ? '#fcffe6' : 'transparent'
                  }}>
                  <List.Item.Meta
                    avatar={
                      item?.drawing ? (
                        <img
                          width={70}
                          height={70}
                          src={
                            item?.drawing.includes('http') || item?.drawing.includes('base64')
                              ? item?.drawing
                              : `data:image/jpeg;base64, ${item?.drawing}`
                          }
                        />
                      ) : (
                        <Skeleton.Image size={'small'} style={{ width: '70px', height: '70px' }} />
                      )
                    }
                    title={item['id']}
                    description={item['name']}
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </Flex>
      </Flex>
      <Layout className={ca.container_rightSide}>
        {toolbarItems.length !== 0 ? <Toolbar className={g.toolbar} toolbarItems={toolbarItems} /> : ''}
        <Flex className={g.container} gap="small" style={{ width: '100%', height: 'calc(100% - 53px)' }}>
          <Layout
            className={g.container_block}
            style={{ minWidth: '30%', background: 'white' }}
            header={<Title level={5}>Основная информация</Title>}
            bordered
            dataSource={selectedClass}>
            {' '}
            <Text type="secondary">{selectedClass?.id}</Text>
            <Text>{selectedClass?.name}</Text>
            {selectedClass?.drawing && <img width={'100%'} src={selectedClass?.drawing} />}
          </Layout>
          <Flex vertical gap="small" style={{ flexGrow: 2, maxWidth: '50vw' }}>
            {groups?.length > 0 && groups && rerenderFlag && (
              <div
                style={{ height: '50%', width: '100%', background: 'white', overflow: 'auto', position: 'relative', background: 'white' }}>
                <h2>Группы {`[${groups?.length ?? 0}]`}</h2>
                <TableGroup
                  setOpenModalConfirmDelete={setOpenModalConfirmDeleteGroups}
                  setSelectedGroup={setSelectedGroup}
                  selectedGroup={selectedGroup}
                  setOpenModalEditGroup={setOpenModalEditGroup}
                  setOpenModalCreateGroup={setOpenModalCreateGroup}
                  data={groups}
                />
              </div>
            )}
            {parameters?.length > 0 && parameters && rerenderFlag && (
              <div style={{ height: '50%', width: '100%', padding: '10px', overflow: 'auto', position: 'relative', background: 'white' }}>
                <h2>Параметры {`[${parameters?.length ?? 0}]`}</h2>
                <TableParams
                  setOpenModalConfirmDelete={setOpenModalConfirmDeleteParams}
                  selectedParameter={selectedParameter}
                  setSelectedParameter={setSelectedParameter}
                  setOpenModalCreateGroup={setOpenModalCreateParams}
                  data={parameters}
                />
              </div>
            )}
            {groups?.length > 0 && groups && rerenderFlag && (
              <div style={{ height: '50%', width: '100%', padding: '10px', overflow: 'auto', position: 'relative', background: 'white' }}>
                <h2>Группы параметров {`[${parameters?.length ?? 0}]`}</h2>
                <TableGroupParams
                  selectedClassId={selectedClassId}
                  setUpdateParams={setUpdateParams}
                  dataTable={groups}
                  columnData={groups}
                />
                {updateParams.length !== 0 && (
                  <Button style={{ width: '100%', position: 'absolute', bottom: 0, left: 0 }} onClick={() => fetchUpdateParams()}>
                    СОХРАНИТЬ
                  </Button>
                )}
              </div>
            )}
          </Flex>
          <CreateClass
            setSelectedClass={setSelectedClass}
            allClasses={allClasses}
            setAllClasses={setAllClasses}
            visible={openCreate}
            onClose={() => setOpenCreate(false)}
            setTotalElements={setTotalElements}
          />
          <CreateClass
            setSelectedClass={setSelectedClass}
            allClasses={allClasses}
            setAllClasses={setAllClasses}
            selectedClass={selectedClass}
            visible={openEdit}
            onClose={() => setOpenEdit(false)}
          />
          <CreateGroupDrawer
            setSelectedClass={setSelectedClass}
            selectedClass={selectedClass}
            visible={openModalCreateGroup}
            allClasses={allClasses}
            setAllClasses={setAllClasses}
            onClose={handleCloseModalCreateBranch}
          />
          <CreateGroupDrawer
            setSelectedClass={setSelectedClass}
            selectedClass={selectedClass}
            selectedGroup={selectedGroup}
            allClasses={allClasses}
            setAllClasses={setAllClasses}
            setSelectedGroup={setSelectedGroup}
            visible={openModalEditGroup}
            onClose={handleCloseModalEditBranch}
          />
          <ModalCreateClassGroups
            allClasses={allClasses}
            setAllClasses={setAllClasses}
            callback_open={openModalCreateParams}
            callback_close={handleCloseModalCreateParams}
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
          />
          <ModalConfirmDelete
            callback_open={openModalConfirmDeleteParams}
            callback_close={handleCloseModalConfirmDeleteParams}
            callback_submit={handleDeleteParameter}
          />
          <ModalConfirmDelete
            callback_open={openModalConfirmDeleteGroups}
            callback_close={handleCloseModalConfirmDeleteGroups}
            callback_submit={handleDeleteGroup}
          />
        </Flex>
      </Layout>
    </Layout>
  );
};
