import {Layout, Flex, Image, Typography, Input, Table, List, Skeleton, Empty, Tag, message, ColorPicker} from 'antd';
import {guidesApi, useGetCutGradeGroupWithChildrenQuery} from '../../services/guidesApi';
import React, { useEffect, useState } from 'react';
import { ClearOutlined, CopyOutlined, DeleteOutlined, EditOutlined, HomeOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import g from "../../general.module.scss";
import {ModalCreateCutGradeGroup, ModalEditCutGradeGroup} from "./CutGradeGroupModals";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";

const { Title, Text } = Typography;

const columns = [
    {
        title: 'Позиция',
        dataIndex: 'pos'
    },
    {
        title: 'Элемент',
        dataIndex: ['tool', 'id'],
        render: (id, record) => (
            <Flex gap="small" vertical>
                <Text>{record.tool.id}</Text>
                <Text>{record.tool.name}</Text>
                <Text>{record.tool.description}</Text>
            </Flex>
        )
    },
    {
        title: 'Количество',
        dataIndex: 'quantity'
    }
];

const CutGradeGroup= () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedCutGradeGroupId, setSelectedCutGradeGroupId] = useState(null);
    const [selectedCutGradeGroup, setSelectedCutGradeGroup] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);

    // const { data: cutGradeGroup, isFetching, isError } = useGetCutGradeGroupWithChildrenQuery(currentPage);
    const [search, {data: cutGradeGroup, isFetching, isError}] = guidesApi.endpoints.getCutGradeGroups.useLazyQuery();

    const handleToolClick = (branch) => {
        console.log(branch);
        setSelectedCutGradeGroupId(branch.id);
        setSelectedCutGradeGroup(branch)

        // const parameters = branch.branches.map((item) => ({
        //     key: item.id,
        //     ...item
        // }));
        //
        // setParameters(parameters);
        //
        // setInformation([
        //     { name: 'ID', value: branch.id },
        //     { name: 'Наименование', value: branch.name }
        // ]);
    };

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => {setOpenModalCreateCutGradeGroup(true)}
        },
        {
            icon: <EditOutlined />,
            disabled: selectedCutGradeGroup === null,
            onClick: () => {setOpenModalEditCutGradeGroup(true)}
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedCutGradeGroup === null,
            onClick: () => { setOpenModalConfirmDelete(true) }
        },
    ]

    const [refetchList, setRefetchList] = useState();

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetchList(!refetchList)
    }

    const [openModalEditCutGradeGroup, setOpenModalEditCutGradeGroup] = useState(false)
    const handleCloseModalEditCutGradeGroup = () => {
        setOpenModalEditCutGradeGroup(false)
        setRefetchList(!refetchList)
    }

    const [openModalCreateCutGradeGroup, setOpenModalCreateCutGradeGroup] = useState(false)
    const handleCloseModalCreateCutGradeGroup = () => {
        setOpenModalCreateCutGradeGroup(false)
        setRefetchList(!refetchList)
    }

    const handleDeleteGroup = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/cutGradeGroup/${selectedCutGradeGroupId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    message.success('Элемент удален!')
                    setOpenModalConfirmDelete(false)
                    message.info('refetching...')
                    return res
                }
            })
    }

    const [cutGrades, setCutGrades] = useState([])
    const [refetch, setRefetch] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/cutGrade`).then(res => res.json()).then(res => setCutGrades(res))
    }, [])

    useEffect(() => {
        if (cutGradeGroup && totalElements === 0) setTotalElements(cutGradeGroup.totalElements);
        setAllData(cutGradeGroup)
    }, [cutGradeGroup]);

    const [allData, setAllData] = useState(null)

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedCutGradeGroup}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}

        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedCutGradeGroup && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedCutGradeGroup?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedCutGradeGroup?.name}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Жесткость'}</Text>
                        <Text>{selectedCutGradeGroup?.hardness || 'Не указано'}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Сила растяжения'}</Text>
                        <Text>{selectedCutGradeGroup?.tensileStrength || 'Не указано'}</Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{ flexGrow: 2 }} className={g.container_div}>
                <Layout style={{  background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={`${g.container_block} ${g.container_block_image}`}>
                    {/* {file ? <Scene url={file} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />} */}
                </Layout>
            </Flex>

            <ModalCreateCutGradeGroup
                callback_open={openModalCreateCutGradeGroup}
                callback_close={handleCloseModalCreateCutGradeGroup}
                cutGrades={cutGrades}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCutGradeGroup}
            />
            <ModalEditCutGradeGroup
                callback_open={openModalEditCutGradeGroup}
                callback_close={handleCloseModalEditCutGradeGroup}

                cutGrades={cutGrades}
                selectedCutGradeGroup={selectedCutGradeGroup}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCutGradeGroup}
            />

            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/cutGradeGroup/${selectedCutGradeGroupId}`}

                setAllElements={setAllData}
                selectedElement={selectedCutGradeGroup}
                setSelectedElement={setSelectedCutGradeGroup}
                setTotalElements={setTotalElements}
            />
        </ContainerAside>
    );
};

export default CutGradeGroup
