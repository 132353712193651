import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import { ProviderViewer } from './components/scene/ContextViewer/ContextViewer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ProviderViewer>
    <ConfigProvider
      locale={ruRU}
      theme={{
        token: {
          colorPrimary: '#A0D911'
        },
        components: {
          Tabs: {
            horizontalMargin: '0px 0px 0p 0px',
            cardBg: 'white'
          },
          Button: {
            colorPrimary: '#A0D911'
          }
        }
      }}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ConfigProvider>
  </ProviderViewer>
);
