import React, { useState } from 'react';
import { Layout, Flex, message, Table, Button, Popconfirm, Select } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Toolbar } from '../../components/toolbar';
import {
  useChangeOrderStatusMutation,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetAllOrdersQuery
} from '../../services/ordersApi';
import { OrderInfo } from '../../components/for_OrderRequests/OrderInfo/OrderInfo';
import { OrderEdit } from '../../components/for_OrderRequests/OrderEdit/OrderEdit';
import { OrderCreate } from '../../components/for_OrderRequests/OrderCreate/OrderCreate';

const { Option } = Select;

export const OrderRequests = () => {
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [orderInfoVisible, setOrderInfoVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [editOrderVisible, setEditOrderVisible] = useState(false);

  // Состояния для фильтров
  const [userNameFilter, setUserNameFilter] = useState('');
  const [orderTypeFilter, setOrderTypeFilter] = useState('');
  const [orderStatusFilter, setOrderStatusFilter] = useState('');

  const { data: orders, isFetching: isFetchingOrders } = useGetAllOrdersQuery();
  const [changeStatus] = useChangeOrderStatusMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [createOrder] = useCreateOrderMutation();

  const handleCreateOrder = async (values) => {
    try {
      await createOrder(values).unwrap();
      setCreateGroupOpen(false);
      message.success('Заказ успешно создан');
    } catch (error) {
      console.log(error);
      message.error('Не удалось создать заказ');
    }
  };

  const handleChangeOrderStatus = async (order) => {
    const { id, name, userName, type } = order;
    try {
      await changeStatus({
        id,
        body: {
          name,
          userName,
          type,
          status: 'Sent'
        }
      }).unwrap();
      message.success('Заказ отправлен');
    } catch (error) {
      console.log(error);
      message.error('Не удалось отправить заказ');
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      await deleteOrder(id).unwrap();
      message.success('Заказ удален');
    } catch (error) {
      console.log(error);
      message.error('Не удалось удалить заказ');
    }
  };

  const filteredOrders = orders?.filter((order) => {
    return (
      (userNameFilter === '' || order.userName === userNameFilter) &&
      (orderTypeFilter === '' || order.type === orderTypeFilter) &&
      (orderStatusFilter === '' || order.status === orderStatusFilter) // Добавлена фильтрация по статусу
    );
  });

  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'name'
    },
    {
      title: 'Составитель',
      dataIndex: 'userName'
    },
    {
      title: 'Тип заказа',
      dataIndex: 'type'
    },
    {
      title: 'Статус',
      dataIndex: 'status'
    },
    {
      title: '',
      dataIndex: '',
      render: (item) => (
        <Flex align="center" gap={'large'}>
          <Button
            onClick={() => {
              setSelectedOrder(item);
              setOrderInfoVisible(true);
            }}
            type="primary">
            Подробнее
          </Button>
          <Button
            onClick={() => {
              setSelectedOrder(item);
              setEditOrderVisible(true);
            }}
            title="Редактирование заказа"
            icon={<EditOutlined />}
          />

          {item.status === 'Created' && (
            <Popconfirm title="Отправить заказ?" onConfirm={() => handleChangeOrderStatus(item)} okText="Да" cancelText="Нет">
              <Button title="Отправить заказ" icon={<RightCircleOutlined />} />
            </Popconfirm>
          )}
          <Popconfirm
            title="Вы уверены, что хотите удалить заказ?"
            onConfirm={() => handleDeleteOrder(item.id)}
            okText="Да"
            cancelText="Нет">
            <Button title="Удаление заказа" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Flex>
      )
    }
  ];

  const toolbarItems = [
    {
      key: 1,
      icon: <PlusOutlined />,
      onClick: () => setCreateGroupOpen(true)
    }
  ];

  const userNames = [...new Set(orders?.map((order) => order.userName))];
  const orderTypes = [...new Set(orders?.map((order) => order.type))];
  const orderStatuses = [...new Set(orders?.map((order) => order.status))]; // Получаем уникальные статусы заказов

  return (
    <Layout style={{ display: 'flex', flexDirection: 'row', background: '#F0F2F5' }}>
      <OrderCreate onSubmit={handleCreateOrder} visible={createGroupOpen} onClose={() => setCreateGroupOpen(false)} />
      <OrderEdit visible={editOrderVisible} onClose={() => setEditOrderVisible(false)} currentValues={selectedOrder} />
      <OrderInfo selectedOrder={selectedOrder} visible={orderInfoVisible} onClose={() => setOrderInfoVisible(false)} />

      <Flex gap="small" vertical style={{ flexGrow: 1, padding: '10px' }}>
        <Toolbar toolbarItems={toolbarItems} />

        {/* Поля для фильтрации с использованием Select */}
        <Flex gap="small" style={{ marginBottom: '10px' }}>
          <Select
            placeholder="Фильтр по составителю"
            value={userNameFilter}
            onChange={(value) => setUserNameFilter(value)}
            style={{ width: 200 }}>
            <Option value="">Все составители</Option>
            {userNames.map((name) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Фильтр по типу заказа"
            value={orderTypeFilter}
            onChange={(value) => setOrderTypeFilter(value)}
            style={{ width: 200 }}>
            <Option value="">Все типы заказов</Option>
            {orderTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Фильтр по статусу заказа"
            value={orderStatusFilter}
            onChange={(value) => setOrderStatusFilter(value)}
            style={{ width: 200 }}>
            <Option value="">Все статусы заказов</Option>
            {orderStatuses.map((status) => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </Flex>

        <Table
          style={{ height: '100%', overflow: 'auto' }}
          rowKey="id"
          columns={columns}
          dataSource={filteredOrders}
          loading={isFetchingOrders}
          pagination={false}
          fixed
        />
      </Flex>
    </Layout>
  );
};
