import { ArrowLeftOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Tabs, message } from 'antd';
import React, { useState, useEffect } from 'react';
import s from "./style.module.css"
import { CreateClassLayout } from './createClassLayout';
const { TabPane } = Tabs;

const CreateClass = ({ allClasses, setAllClasses,setSelectedClass,selectedClass,visible, onClose,setTotalElements }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [classesDrawer, setClassesDrawer] = useState(false);
  const [selectedClassType, setSelectedClassType] = useState(null);
  const [renderFlag, setRenderFlag] = useState(false)

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const [formData, setFormData] = useState({
    id:"",
    classType:{},
    name:"",
    used:"",
    drawing:"",
    parameters:[],
  })
  const fetchCreateClass = async()=>{
    try{
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARAM_CLASS}`,{
         method:"POST",
         headers:{
         "Content-Type":"application/json"
         },
         body:JSON.stringify(formData)
     })
     if(response.ok){
        const json = await response.json()
        setAllClasses([...allClasses,{...json}]);
        setTotalElements((prevValue=>prevValue + 1))
        setSelectedClass(json)
       if(state === true){
         onClose()
       }
       message.success("Успешно создано")
      }else{
       message.error("Ошибка создания")
      }}catch(e){
        console.log(e)
      }
 }
 const fetchEditClass = async(state)=>{
    try{
   const response = await fetch(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARAM_CLASS}`,{
        method:"PUT",
        headers:{
        "Content-Type":"application/json"
        },
        body:JSON.stringify(formData)
    })
  if(response.ok){
    setAllClasses(allClasses.map(l=>l.id==selectedClass.id?{...formData}:l));
    setSelectedClass(formData)
   if(state === true){
     onClose()
   }
   message.success("Успешно изменено")
  }else{
   message.error("Ошибка редактирования")
  }}catch(e){
    console.log(e)
  }
 
 }
 useEffect(()=>{
    console.log(selectedClass)
    if(selectedClass !== null && selectedClass !== undefined){
    setFormData(selectedClass)
    setRenderFlag(false)
  }
},[selectedClass,visible])
useEffect(()=>{
    setRenderFlag(true)
  },[renderFlag])
  return (
    <Drawer
    width={'100vw'}
    placement="left"
    title={
      <Flex align="center" gap="60px">
        <Button onClick={onClose} size="large" icon={<ArrowLeftOutlined />} />
        <Tabs size="large" activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Общие" key="1" />
          <TabPane tab="Параметры" key="2" />
          <TabPane tab="Соединения инструментов" key="3" />
        </Tabs>
      </Flex>
    }
    open={visible}
    closeIcon={false}
    footer={
      <Flex align="center" justify="end" gap="middle">
        <Button onClick={()=>{selectedClass !== null && selectedClass !== undefined ? fetchEditClass(false) :fetchCreateClass(false)}} icon={<SaveOutlined />} type="primary" size="large">
          Создать
        </Button>
        <Button onClick={()=>{
          selectedClass !== null && selectedClass !== undefined ? fetchEditClass(true) :fetchCreateClass(true)
          }} size="large">Сохранить и закрыть</Button>
        <Button onClick={onClose} icon={<CloseCircleOutlined />} danger size="large">
          Отменить
        </Button>
      </Flex>
    }>
    <div>
      {activeTab === '1' && renderFlag &&  <CreateClassLayout selectedClass={selectedClass} classesDrawer={classesDrawer} selectedClassType={selectedClassType} 
      setSelectedClassType={setSelectedClassType} setClassesDrawer={setClassesDrawer} formData={formData}
      setFormData={setFormData}
      />}
    </div>
  </Drawer>
  );
};

export default CreateClass