import React, { useEffect, useState } from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import s from './Table.module.css';

const TableGroupParams = ({ dataTable, columnData, setUpdateParams, selectedClassId }) => {
  const columnHelper = createColumnHelper();

  // Создаем базовые колонки (позиция, наименование, описание)
  const columns = [
    columnHelper.accessor('position', {
      header: 'Позиция',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('name', {
      header: 'Наименование',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('description', {
      header: 'Описание',
      cell: (info) => info.getValue(),
    }),
  ];

  // Добавляем колонки для групп
  columnData?.forEach((element) => {
    columns.push(
      columnHelper.accessor(`${element.id}_isActive`, {
        header: element.name,
        cell: (info) => (
          <input
            type="checkbox"
            checked={info.getValue()}
            onChange={() => handleCheckboxChange(info.row.index, element.id)}
          />
        ),
      })
    );
  });

  // Инициализация данных: объединяем параметры всех групп
  const initializeData = () => {
    let position = 0;
    const uniqueParams = {};

    dataTable.forEach((group) => {
      group.parameters.forEach((param) => {
        if (!uniqueParams[param.name]) {
          uniqueParams[param.name] = {
            position: ++position,
            name: param.name,
            description: param.description,
            groups: [],
          };
        }
        uniqueParams[param.name].groups.push({
          groupId: group.id,
          isActive: param.isActive || false, // Заполняем начальное значение
        });
      });
    });

    return Object.values(uniqueParams);
  };

  const [data, setData] = useState(initializeData);

  // Заполняем setUpdateParams начальными значениями
  useEffect(() => {
    const initialSelectedItems = [];

    data.forEach((row) => {
      row.groups.forEach((group) => {
        initialSelectedItems.push({
          name: row.name,
          description: row.description,
          isActive: group.isActive,
          groupId: group.groupId,
        });
      });
    });

    setUpdateParams(initialSelectedItems);
  }, [data, setUpdateParams]);

  // Обработчик изменения чекбокса
  const handleCheckboxChange = (rowIndex, groupId) => {
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          const updatedRow = { ...row };
          updatedRow.groups = updatedRow.groups.map((group) =>
            group.groupId === groupId
              ? { ...group, isActive: !group.isActive }
              : group
          );

          // Обновляем состояние в setUpdateParams
          setUpdateParams((prevSelectedItems) => {
            console.log(prevSelectedItems)
            return prevSelectedItems.map((selectedItem) =>
              selectedItem.name === updatedRow.name && selectedItem.groupId === groupId
                ? { ...selectedItem, isActive: !selectedItem.isActive }
                : selectedItem
            );
          });

          return updatedRow;
        }
        return row;
      })
    );
  };
useEffect(()=>{
  console.log(data  )
},[data])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  useEffect(() => {
    setData(initializeData());
  }, [selectedClassId]);
  return (
    <div className={s.table_container}>
      {dataTable && (
        <table>
          <thead className={s.cell_container}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className={s.cell_wrapper} key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} style={{ zIndex: 10,minWidth:180, maxHeight:100 }}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>{row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id.endsWith('_isActive')
                    ? flexRender(cell.column.columnDef.cell, {
                        ...cell.getContext(),
                        getValue: () => {
                          const groupId = cell.column.id.replace('_isActive', '');
                          const group = row.original.groups.find(
                            (g) => g.groupId === groupId
                          );
                          return group?.isActive;
                        },
                      })
                    : flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);
};

export default TableGroupParams;