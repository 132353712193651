import React from 'react'
import sp from "../../pages/StartPage/StartPage.module.scss";
import {Link} from "react-router-dom";
import {Layout, Tag, Typography, Skeleton, Button} from "antd";
const { Text, Title } = Typography;
import image_template from '../../content/images/antd_template.svg'
import star from '../../content/images/star.svg'

export const PageItem = ({
                             image,
                             label,
                             tag,
                             isFavourite,
                             handleFavouriteStatus,
                             onClickHandler,
                             url
}) => {
    return (
        <Link className={sp.app_container} to={url} onClick={onClickHandler}>
            <Layout className={sp.app_block}>
                <Layout className={sp.app_block_image} >
                    <img src={!!image ? image : image_template} className={sp.app_image} alt={''} />
                </Layout>
                <Layout className={sp.app_rightSide}>
                    <Layout className={sp.app_header}>
                        <div className={sp.app_header_favourite_block}>
                            <button className={sp.app_header_favourite_button}>
                                <div style={{
                                    backgroundImage: `url(${star})`,
                                    filter: !isFavourite ? 'saturate(0)' : ""
                                }}/>
                            </button>
                        </div>
                        <Tag color={tag?.color} className={sp.app_tag}>{tag?.title}</Tag>
                    </Layout>
                    <Layout className={sp.app_description}>
                        <Text className={sp.app_title}>{label}</Text>
                    </Layout>
                </Layout>
            </Layout>
        </Link>
        // <Link
        //     className={sp.button}
        //     to={url_path}
        //     style={{
        //         backgroundColor: color.includes(')') ? color.split(')')[0] + ',0.7)' : color || 'rgb(240 137 16)',
        //         border: `2px solid ${color.split(')')[0] + ',1)'}`,
        //     }}
        //     onClick={onClickHandler}
        // >
        //     <Layout className={sp.button_content}>
        //         <Layout className={sp.button_image}>
        //             {svg_path !== undefined ? svg_path : <img src={img_path}/>}
        //         </Layout>
        //         <Layout className={sp.text}>{label}</Layout>
        //     </Layout>
        // </Link>
    )
}