import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PageHeader } from './components/PageHeader/PageHeader';
import { Instruments } from './pages/Instruments/Instruments';
import { StantardIssue } from './pages/standardIssue';
import { ToolAssemblies } from './pages/toolAssemblies';
import { UserAdministration } from './pages/userAdministration';
import { ClassesAndGroups } from './pages/classesAndGroups';
import { Layout } from 'antd';
import { StartPage } from './pages/StartPage/StartPage';
import { useDispatch, useSelector } from 'react-redux';
import { EditingCostCenter } from './pages/editingCostCenter';
import { MachineGroups } from './pages/machineGroups';
import { MaterialGroups } from './pages/materialsGroups';
import { DetailClasses } from './pages/detailClasses';
import Branch from './pages/Branch/Branch';
import CompanyType from './pages/CompanyType/CompanyType';
import Language from './pages/Language/Language';
import Countries from './pages/Countries/Countries';
import Company from './pages/Company/Company';
import { ClassType } from './pages/ClassType/ClassType';
import CutGrade from './pages/CutGrade/CutGrade';
import AdapterGroups from './pages/Adapter/Adapter';
import Material from './pages/Material/Material';
import Machine from './pages/Machine/Machine';
import CutGradeGroup from './pages/CutGradeGroup/CutGradeGroup';
import { CostCenters } from './pages/CostCenters/CostCenter';
import { Orders } from './pages/Orders/Orders';
import { OrderRequests } from './pages/OrderRequests/OrderRequests';
import AuthForm from './pages/AuthForm/AuthForm';
import ProtectedRoute from './components/for_Auth/ProtectedRoute';
import { setActiveTab, setTabs } from './features/headerSlice';

const { Content } = Layout;

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    // Восстанавливаем вкладки из localStorage при первого монтировании
    const storedTabs = localStorage.getItem('tabs');
    if (storedTabs) {
      dispatch(setTabs(JSON.parse(storedTabs)));
    }

    // Восстанавливаем активную вкладку
    const storedActiveTab = localStorage.getItem('activeTab');
    if (storedActiveTab) {
      dispatch(setActiveTab(storedActiveTab));
    }
  }, [dispatch]);

  return (
    <Layout style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {pathname === '/' ? (
        <Routes>
          <Route path="/" element={!isAuthenticated ? <AuthForm /> : <Navigate to="/startPage" />} />
        </Routes>
      ) : (
        <>
          <PageHeader />
          <Content style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Routes>
              <Route
                path="/startPage"
                element={
                  <ProtectedRoute>
                    <StartPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/instruments"
                element={
                  <ProtectedRoute>
                    <Instruments />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/toolAssemblies"
                element={
                  <ProtectedRoute>
                    <ToolAssemblies />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/standardIssue"
                element={
                  <ProtectedRoute>
                    <StantardIssue />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/userAdministration"
                element={
                  <ProtectedRoute>
                    <UserAdministration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/orders"
                element={
                  <ProtectedRoute>
                    <Orders />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/classesAndGroups"
                element={
                  <ProtectedRoute>
                    <ClassesAndGroups />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/editingCostCenter"
                element={
                  <ProtectedRoute>
                    <EditingCostCenter />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/machineGroups"
                element={
                  <ProtectedRoute>
                    <MachineGroups />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/materialGroups"
                element={
                  <ProtectedRoute>
                    <MaterialGroups />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/detailClasses"
                element={
                  <ProtectedRoute>
                    <DetailClasses />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/costCenters'}
                element={
                  <ProtectedRoute>
                    <CostCenters />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/orderRequests'}
                element={
                  <ProtectedRoute>
                    <OrderRequests />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/branches'}
                element={
                  <ProtectedRoute>
                    <Branch />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/companyTypes'}
                element={
                  <ProtectedRoute>
                    <CompanyType />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/languages'}
                element={
                  <ProtectedRoute>
                    <Language />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/countries'}
                element={
                  <ProtectedRoute>
                    <Countries />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/companies'}
                element={
                  <ProtectedRoute>
                    <Company />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/classTypes'}
                element={
                  <ProtectedRoute>
                    <ClassType />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/cutGrades'}
                element={
                  <ProtectedRoute>
                    <CutGrade />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/adapters'}
                element={
                  <ProtectedRoute>
                    <AdapterGroups />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/materials'}
                element={
                  <ProtectedRoute>
                    <Material />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/machines'}
                element={
                  <ProtectedRoute>
                    <Machine />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/cutGradeGroups'}
                element={
                  <ProtectedRoute>
                    <CutGradeGroup />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Content>
        </>
      )}
    </Layout>
  );
}

export default App;
