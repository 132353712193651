import { Button, Card, Flex, Form, Input, Modal, Select, Typography, DatePicker, InputNumber } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { ListOfTools } from '../../ListOfTools/ListOfTools';
import { ListOfCostCenters } from '../../ListOfCostCenters/ListOfCostCenters';
import dayjs from 'dayjs';

export const OrderCreate = ({ onSubmit, visible, onClose }) => {
  const [form] = Form.useForm();

  const [listOfCostCentersVisible, setListOfCostCentersVisible] = useState(false);
  const [listOfToolsVisible, setListOfToolsVisible] = useState(false);
  const [listOfTools, setListOfTools] = useState([]);
  const [orderType, setOrderType] = useState(null);
  const [selectedToolId, setSelectedToolId] = useState(null);

  const [costCenterMap, setCostCenterMap] = useState({});
  const [quantityMap, setQuantityMap] = useState({});
  const [dateMap, setDateMap] = useState({});

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  const handleCreate = (values) => {
    const { name, type } = values;

    const itemList = listOfTools.map((tool) => ({
      name: tool.name,
      imageId: 'Image id',
      guideId: tool.id,
      costCenterId: costCenterMap[tool.id]?.id,
      quantity: quantityMap[tool.id],
      deliveryPlannedDate: dateMap[tool.id]?.format('YYYY-MM-DD')
    }));

    onSubmit({
      name,
      type,
      userName: 'testUserName',
      itemList
    });

    form.resetFields();
    onClose();
  };

  const handleCostCenterSelect = (toolId, costCenter) => {
    setCostCenterMap((prev) => ({
      ...prev,
      [toolId]: costCenter
    }));
  };

  const handleDeleteTool = (toolId) => {
    setListOfTools((prev) => prev.filter((tool) => tool.id !== toolId));
    setCostCenterMap((prev) => {
      const { [toolId]: _, ...rest } = prev;
      return rest;
    });
    setQuantityMap((prev) => {
      const { [toolId]: _, ...rest } = prev;
      return rest;
    });
    setDateMap((prev) => {
      const { [toolId]: _, ...rest } = prev;
      return rest;
    });
  };

  const isSubmitDisabled = listOfTools.some((tool) => !costCenterMap[tool.id] || !quantityMap[tool.id] || !dateMap[tool.id]);

  return (
    <Modal footer={false} title="Создание заказа" open={visible} onCancel={onClose}>
      <ListOfTools
        open={listOfToolsVisible}
        onClose={() => setListOfToolsVisible(false)}
        setListOfTools={setListOfTools}
        setListOfToolsVisible={setListOfToolsVisible}
        selectMultiple={true}
        selectedTools={listOfTools} // Передаем выбранные инструменты
      />

      <ListOfCostCenters
        visible={listOfCostCentersVisible}
        onClose={() => setListOfCostCentersVisible(false)}
        setListOfCostCenters={(costCenter) => handleCostCenterSelect(selectedToolId, costCenter)}
        setListOfCostCentersVisible={setListOfCostCentersVisible}
      />
      <Form form={form} name="basic" onFinish={handleCreate}>
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Тип заказа"
          name="type"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Select placeholder="Выберите тип заказа" value={orderType} onChange={(value) => setOrderType(value)} style={{ width: '100%' }}>
            <Select.Option value="Закупка">Закупка</Select.Option>
            <Select.Option value="Обслуживание">Обслуживание</Select.Option>
            <Select.Option value="Изготовление">Изготовление</Select.Option>
          </Select>
        </Form.Item>

        <Button onClick={() => setListOfToolsVisible(true)}>Выбрать инструменты</Button>

        {listOfTools?.map((tool) => (
          <Card bordered={false} style={{ width: '100%', marginBottom: '20px', position: 'relative' }} key={tool.id}>
            <Button
              type="text"
              danger
              icon={<CloseOutlined />}
              onClick={() => handleDeleteTool(tool.id)}
              style={{ position: 'absolute', top: 0, right: 0 }}
            />
            <Flex justify="space-between" align="end" style={{ marginBottom: '20px' }}>
              <Flex align="center" gap="large">
                <Flex vertical>
                  <Typography.Text style={{ color: '#A0D911' }}>{tool?.name}</Typography.Text>
                  <Typography.Text type="secondary">{tool?.description}</Typography.Text>
                </Flex>
              </Flex>
              <Button
                onClick={() => {
                  setSelectedToolId(tool.id);
                  setListOfCostCentersVisible(true);
                }}>
                Выбрать центр затрат
              </Button>
            </Flex>

            {costCenterMap[tool.id] && (
              <Flex gap="large" align="center" style={{ marginBottom: '10px' }}>
                <Typography.Text style={{ color: '#A0D911' }}>{costCenterMap[tool.id]?.name}</Typography.Text>
                <Typography.Text type="secondary">{costCenterMap[tool.id]?.description}</Typography.Text>
              </Flex>
            )}
            <Flex gap="small" align="center">
              <InputNumber
                placeholder="Кол-во"
                min={1}
                value={quantityMap[tool.id]} // Управляемый компонент
                onChange={(value) => setQuantityMap((prev) => ({ ...prev, [tool.id]: value }))}
                style={{ width: '100px' }}
              />
              <DatePicker
                placeholder="Ожидаемая дата"
                value={dateMap[tool.id]} // Управляемый компонент
                onChange={(date) => setDateMap((prev) => ({ ...prev, [tool.id]: date }))}
                disabledDate={disabledDate}
              />
            </Flex>
          </Card>
        ))}

        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button type="primary" htmlType="submit" disabled={isSubmitDisabled}>
            Создать заказ
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
