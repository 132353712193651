import { useEffect } from 'react'
import { directionalLight } from '@react-three/drei';
import { useThree } from '@react-three/fiber'
import * as THREE from 'three'
const BoxStpModel = (MeshName) => {

    console.log('BoxStpModel')
    console.log(MeshName)
    const { gl, camera, scene } = useThree();

    useEffect(() => {
        console.log('MeshName', MeshName)
        if (MeshName.MeshName == "{}") {
            if (scene.getObjectByName("BoxModel").children.length) {
                console.log('delet', scene.getObjectByName("BoxModel").children[0])
                scene.getObjectByName("BoxModel").remove((scene.getObjectByName("BoxModel")).children[0]);

            }
        }
        if (MeshName.MeshName !== null && MeshName.MeshName !== "{}") {
            console.log(MeshName)
            console.log('length', scene.getObjectByName("BoxModel").children.length)
            console.log('length', scene.getObjectByName("BoxModel").children[0])
            if (scene.getObjectByName("BoxModel").children.length) {
                console.log('delet', scene.getObjectByName("BoxModel").children[0])
                scene.getObjectByName("BoxModel").remove((scene.getObjectByName("BoxModel")).children[0]);

            }
            console.log(scene.getObjectByName(MeshName.MeshName))
            const helper = new THREE.BoxHelper(scene.getObjectByName(MeshName.MeshName), 0xffff00)
            helper.transparent = true
            helper.material.depthTest = false
            helper.material.depthWrite = false
            scene.getObjectByName("BoxModel").add(helper)
            console.log("helper", helper)
            console.log("", scene)
        }
        else {
            if (scene.getObjectByName("BoxModel").children.length) {
                console.log('delet', scene.getObjectByName("BoxModel").children[0])
                scene.getObjectByName("BoxModel").remove((scene.getObjectByName("BoxModel")).children[0]);

            }
        }
    }, [MeshName])

    return null
}

export default BoxStpModel