import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Popconfirm, Table, message } from 'antd';
import { useState } from 'react';
import { AddToolInOrder } from '../AddToolInOrder/AddToolInOrder';
import { useGetOrderInfoQuery, useRemoveToolFromOrderMutation } from '../../../services/ordersApi';

const columnsOrder = [
  {
    title: 'Наименование',
    dataIndex: 'name'
  },
  {
    title: 'Составитель',
    dataIndex: 'userName'
  },
  {
    title: 'Статус',
    dataIndex: 'status'
  }
];

export const OrderInfo = ({ selectedOrder, visible, onClose }) => {
  const columnsItems = [
    {
      title: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Наименование',
      dataIndex: 'name'
    },
    {
      title: 'Количество',
      dataIndex: 'quantity'
    },
    {
      title: 'Ожидаемая дата поставки',
      dataIndex: 'deliveryPlannedDate'
    },

    {
      title: '',
      dataIndex: '',
      render: (item) =>
        selectedOrder?.status === 'Created' && (
          <Popconfirm title="Вы уверены, что хотите удалить инструмент?" onConfirm={() => deleteTool(item.id)} okText="Да" cancelText="Нет">
            <Button title="Удаление инструмента" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        )
    }
  ];
  const [addInstrumentVisible, setAddInstrumentVisible] = useState(false);

  const { data: orderInfo, refetch: refetchOrderInfo } = useGetOrderInfoQuery(selectedOrder?.id, {
    skip: !selectedOrder
  });

  const [removeTool] = useRemoveToolFromOrderMutation();

  const deleteTool = async (id) => {
    try {
      await removeTool({
        orderId: selectedOrder.id,
        toolId: id
      }).unwrap();
      await refetchOrderInfo();
      message.success('Инструмент удален из заказа');
    } catch (error) {
      console.log(error);
      message.error('Не удалось удалить инструмент');
    }
  };

  return (
    <Drawer width={'90vw'} height={'100vh'} placement="left" open={visible} onClose={onClose}>
      <AddToolInOrder
        open={addInstrumentVisible}
        onClose={() => setAddInstrumentVisible(false)}
        order={orderInfo}
        refetchOrderInfo={refetchOrderInfo}
      />
      {selectedOrder?.status === 'Created' && (
        <Button onClick={() => setAddInstrumentVisible(true)} icon={<PlusOutlined />} type="primary">
          Добавить инструмент в заказ
        </Button>
      )}
      <Table columns={columnsOrder} dataSource={[orderInfo]} pagination={false} rowKey="id" fixed />
      <Table style={{ flexGrow: '1', overflow: 'auto' }} columns={columnsItems} dataSource={orderInfo?.itemList} pagination={false} fixed />
    </Drawer>
  );
};
