import React from 'react';
import { useChangeRoleMutation, useDeleteRoleMutation, useGetAllRolesQuery, useGetUserInfoQuery } from '../../services/usersApi';
import { Button, Checkbox, Flex, Popconfirm, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export const Roles = ({ userInfo, isFetching, refetch, setSelectedRole, setEditRoleOpen }) => {
  const { data: roles } = useGetAllRolesQuery();
  const [changeRole] = useChangeRoleMutation();
  const [deleteRole] = useDeleteRoleMutation();

  const handleDeleteRole = async (roleName) => {
    try {
      await deleteRole(roleName).unwrap();
      message.success('Роль успешно удалена');

      setTimeout(async () => {
        await refetch();
      }, 500);
    } catch (error) {
      console.error(error);
      message.error('Ошибка при удалении роли');
    }
  };

  const handleGetRoleInfo = async (roleName) => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/admin/roles/getRoleInfo/${roleName}`)
      .then((response) => response.json())
      .then((roleInfo) => setSelectedRole(roleInfo))
      .then(() => setEditRoleOpen(true));
  };

  return (
    <>
      {roles?.roles.map((role) => (
        <Flex gap={'small'} align="center" key={role.id}>
          <Checkbox
            disabled={isFetching}
            key={role}
            value={role}
            onChange={async (e) => {
              const roleAction = e.target.checked ? 'addRole' : 'removeRole';
              try {
                // console.log({ action: roleAction, id: userInfo.id, role });
                await changeRole({ action: roleAction, id: userInfo.id, role }).unwrap();
                message.success(`Роль успешно ${e.target.checked ? 'добавлена' : 'удалена'}`);
              } catch (error) {
                console.error(error);
                message.error(`Ошибка при ${e.target.checked ? 'добавлении' : 'удалении'} роли`);
              }
            }}>
            {role}
          </Checkbox>
          <Button onClick={() => handleGetRoleInfo(role)} type="text" icon={<EditOutlined />}></Button>
          <Popconfirm title="Вы уверены, что хотите удалить роль?" onConfirm={() => handleDeleteRole(role)} okText="Да" cancelText="Нет">
            <Button type="text" icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Flex>
      ))}
    </>
  );
};
