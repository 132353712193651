import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, InputNumber, Layout, message, Modal, Switch, Tooltip, Upload} from "antd";
import g from "../../general.module.scss";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import tool from '../../content/images/tool.svg'
import assembly from '../../content/images/assembly.svg'

export const ModalEditAdapter = ({callback_open, callback_close, selectedAdapter, allElements, setAllElements, setSelectedElement, setTotalElements}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const [toolOrAssembly, setToolOrAssembly] = useState([])

    useEffect(() => {
        setToolOrAssembly(selectedAdapter?.toolOrAssemble === 0 || !!!selectedAdapter?.toolOrAssemble ? []
            : selectedAdapter?.toolOrAssemble === 1 ? ['instrument']
                : selectedAdapter?.toolOrAssemble === 2 ? ['assembly'] : ['instrument', 'assembly'],)
    }, [selectedAdapter]);

    useEffect(() => {
        setCheckedFirstOption(toolOrAssembly.includes('instrument'))
        setCheckedSecondOption(toolOrAssembly.includes('assembly'))
    }, [toolOrAssembly])

    const callback_submit = (body) => {
        console.log(body)
        const result = body
        switch (result.toolOrAssemble.length) {
            case 0:
                result.toolOrAssemble = 0;
                break;
            case 1:
                result.toolOrAssemble = result.toolOrAssemble[0] === 'instrument' ? 1 : 2
                break;
            case 2:
                result.toolOrAssemble = 3
                break;
            default:
                break;
        }

        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/adapter`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    setAllElements({...allElements, results: [...allElements.results.filter(f => f.id !== selectedAdapter.id), body]})
                    // setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    callback_close()
                    setIsWaitingResponse(false)
                    return res
                }
            })
    }

    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64Image(`${reader.result}`)
        };
    };
    const [base64Image, setBase64Image] = useState('')

    useEffect(() => {
        setBase64Image(`${
            selectedAdapter?.drawing &&
            selectedAdapter?.drawing.includes('base64')
                ?
                selectedAdapter?.drawing
                :
                'data:image/jpeg;base64, ' + selectedAdapter?.drawing}` || '')
    }, [selectedAdapter]);

    useEffect(() => {
        try {
            form.setFieldsValue({
                drawing: `${base64Image}`,
            });
        } catch (e) {
            console.log(e)
        }
    }, [base64Image])

    const onChange = e => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };

    const [checkedFirstOption, setCheckedFirstOption] = useState(false)
    const [checkedSecondOption, setCheckedSecondOption] = useState(false)

    return (
        <Modal
            centered
            title={'Изменение адаптера'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            destroyOnClose
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                    name={'createBranch'}
                    initialValues={{
                        id: selectedAdapter?.id,
                        name: selectedAdapter?.name,
                        drawing: selectedAdapter?.drawing,
                        toolOrAssemble:
                            selectedAdapter?.toolOrAssemble === 0 || !!!selectedAdapter?.toolOrAssemble ? []
                                : selectedAdapter?.toolOrAssemble === 1 ? ['instrument']
                                    : selectedAdapter?.toolOrAssemble === 2 ? ['assembly'] : ['instrument', 'assembly'],
                    }}
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="drawing"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input type="file" id={'photoUploader'} onChange={onChange} className={g.modal_inputfile_input}/>
                        <img src={base64Image} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image === ''} onClick={() => {
                            document.getElementById("photoUploader").value = "";
                            setBase64Image('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
                <Form.Item
                    name="toolOrAssemble"
                    label="Используется для"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Checkbox.Group style={{width: '100%', justifyContent: 'space-between'}} className={g.checkbox_group}>
                        <Checkbox onClick={(event) => {
                            setCheckedFirstOption(event.target.checked)
                        }} value={'instrument'}/>
                        <Tooltip title={'Инструментальный'}>
                            <div className={`${g.form_svg_fit} ${checkedFirstOption ? g.form_svg_fit_selected : ""}`}
                                 style={{backgroundImage: `url(${tool})`}}></div>
                        </Tooltip>
                        <Checkbox onClick={(event) => {
                            setCheckedSecondOption(event.target.checked)
                        }} value={'assembly'}/>
                        <Tooltip title={'Сборочный'}>
                            <div className={`${g.form_svg_fit} ${checkedSecondOption ? g.form_svg_fit_selected : ""}`} style={{backgroundImage: `url(${assembly})`}}></div>
                        </Tooltip>
                    </Checkbox.Group>
                </Form.Item>
            </Layout>
        </Modal>
    )
}

export const ModalCreateAdapter = ({callback_open, callback_close, allElements, setAllElements, setTotalElements, setSelectedElement}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        const result = body
        console.log(body)
        switch (result.toolOrAssemble?.length) {
            case undefined:
            case 0:
                result.toolOrAssemble = 0;
                break;
            case 1:
                result.toolOrAssemble = result.toolOrAssemble[0] === 'instrument' ? 1 : 2
                break;
            case 2:
                result.toolOrAssemble = 3
                break;
            default:
                result.toolOrAssemble = 0;
        }
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/adapter`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    console.log(allElements)
                    setAllElements({...allElements, results: [...allElements.results, body]})
                    setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    setIsWaitingResponse(false)
                    callback_close()
                    return res
                }
            })
            .catch(e => {
                message.error(`${e}`)
                setIsWaitingResponse(false)
            })
    }

    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64Image(`${reader.result}`)
        };
    };
    const [base64Image, setBase64Image] = useState('')

    useEffect(() => {
        try {
            form.setFieldsValue({
                drawing: `${base64Image}`,
            });
        } catch (e) {
            console.log(e)
        }
    }, [base64Image])

    const onChange = e => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };

    const [checkedFirstOption, setCheckedFirstOption] = useState(false)
    const [checkedSecondOption, setCheckedSecondOption] = useState(false)


    return (
        <Modal
            centered
            title={'Создание адаптера'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="drawing"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input type="file" id={'photoUploader'} onChange={onChange} className={g.modal_inputfile_input}/>
                        <img src={base64Image} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image === ''} onClick={() => {
                            document.getElementById("photoUploader").value = "";
                            setBase64Image('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
                <Form.Item
                    name="toolOrAssemble"
                    label="Используется для"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Checkbox.Group style={{width: '100%', justifyContent: 'space-between'}} className={g.checkbox_group}>
                        <Checkbox onClick={(event) => {
                            setCheckedFirstOption(event.target.checked)
                        }} value={'instrument'}/>
                        <Tooltip title={'Инструментальный'}>
                            <div className={`${g.form_svg_fit} ${checkedFirstOption ? g.form_svg_fit_selected : ""}`}
                                 style={{backgroundImage: `url(${tool})`}}></div>
                        </Tooltip>
                        <Checkbox onClick={(event) => {
                            setCheckedSecondOption(event.target.checked)
                        }} value={'assembly'}/>
                        <Tooltip title={'Сборочный'}>
                            <div className={`${g.form_svg_fit} ${checkedSecondOption ? g.form_svg_fit_selected : ""}`} style={{backgroundImage: `url(${assembly})`}}></div>
                        </Tooltip>
                    </Checkbox.Group>
                </Form.Item>
            </Layout>
        </Modal>
    )
}