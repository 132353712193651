import { Modal, Input, Button, Form } from 'antd';
import { useCreateStorageMutation } from '../../services/costCenterApi';

export const CreateStorage = ({ createStorageOpen, setCreateStorageOpen, costCenterId }) => {
  const [form] = Form.useForm();

  const [createStorage] = useCreateStorageMutation();

  const handleCreate = async (values) => {
    console.log({ costCenterId, ...values });
    try {
      await createStorage({ costCenterId, ...values }).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    } finally {
      setCreateStorageOpen(false);
    }
  };

  return (
    <Modal footer={false} title="Создание места хранения" open={createStorageOpen} onCancel={() => setCreateStorageOpen(false)}>
      <Form form={form} onFinish={handleCreate} name="basic" autoComplete="off">
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button htmlType="submit" type="primary">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
