import { Layout, Flex, Typography, Input, List, Skeleton, Button, Popconfirm, message, Tabs, Table, Image, Upload } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from 'react';
import { Toolbar } from '../../components/toolbar';
import { BuildOutlined, DeleteOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DxfComponent } from '../../components/dxfViewer';
import { Base64ToImage } from '../../components/base64ToImage';
import { CreateToolAssembly } from '../../components/for_ToolAssemblies/CreateToolAssembly/CreateToolAssembly';
import {
  assemblyToolsApi,
  useDeleteAssembleMutation,
  useDeleteResourceMutation,
  useGetAssembleInfoQuery,
  useUploadResourceMutation
} from '../../services/assemblyTools';
import { EditToolAssembly } from '../../components/for_ToolAssemblies/EditToolAssembly/EditToolAssembly';
import { SceneAssembly } from '../../components/sceneAssembly';
import { BuildingAnAssembly } from '../../components/for_ToolAssemblies/BuildingAnAssembly/BuildingAnAssembly';

const { Title, Text } = Typography;
const { Search } = Input;

export const ToolAssemblies = () => {
  const [filesSelect, setFilesSelect] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [allAssemblies, setAlllAssemblies] = useState([]);
  const [selectedAssembly, setSelectedAssembly] = useState(null);
  const [parameters, setParameters] = useState([]);
  const [information, setInformation] = useState([]);
  const [buildingAnAssemblyVisible, setBuildingAnAssemblyVisible] = useState(false);
  const [createAssemblyVisible, setCerateAssemblyVisible] = useState(false);
  const [editAssemblyVisible, setEditAssemblyVisible] = useState(false);
  const [loadingAssembleInfo, setLoadingAssembleInfo] = useState(false);
  const [stpURLs, setStpURLs] = useState([]);
  const [stpName, setStpName] = useState([]);
  const [gridtoolAssemblies, setGridtoolAssemblies] = useState(0);
  const [activeFileTab, setActiveFileTab] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const toolbarItems = [
    {
      key: 1,
      icon: <PlusOutlined />,
      onClick: () => setCerateAssemblyVisible(true)
    },
    {
      key: 2,
      icon: <EditOutlined />,
      onClick: () => (selectedAssembly ? setEditAssemblyVisible(true) : message.error('Для редактирования необходимо выбрать сборку'))
    },
    {
      key: 3,
      icon: selectedAssembly ? (
        <Popconfirm title="Вы уверены, что хотите удалить сборку?" onConfirm={() => handleDeleteAssemble()} okText="Да" cancelText="Нет">
          <DeleteOutlined />
        </Popconfirm>
      ) : (
        <DeleteOutlined />
      ),
      onClick: () => {
        if (!selectedAssembly) {
          message.error('Для удаления необходимо выбрать сборку');
        }
      }
    }
  ];

  const columns = [
    {
      title: 'Позиция',
      dataIndex: 'pos',
      key: 'id'
    },
    {
      title: 'Элемент',
      dataIndex: ['tool', 'id'],
      key: 'id',
      render: (id, record) => (
        <Flex gap="small" vertical>
          <Text>{record.tool.id}</Text>
          <Text>{record.tool.name}</Text>
          <Text>{record.tool.description}</Text>
        </Flex>
      )
    },
    {
      title: 'Количество',
      dataIndex: 'quantity',
      key: 'id'
    },
    {
      title: (
        <Button onClick={() => setBuildingAnAssemblyVisible(true)} disabled={!selectedAssembly} icon={<BuildOutlined />}>
          Сборка
        </Button>
      ),
      dataIndex: '',
      key: 'id'
    }
  ];

  const fileTypeItems = [
    {
      key: 'image/vnd.dxf',
      label: (
        <Button onClick={() => handleFileButtonClick('image/vnd.dxf')} type="text">
          2D изображение [dxf]
        </Button>
      )
    },
    {
      key: 'model/step',
      label: (
        <Button onClick={() => handleFileButtonClick('model/step')} type="text">
          3D изображение [step]
        </Button>
      )
    },
    {
      key: 'images',
      label: (
        <Button onClick={() => handleFileButtonClick('images')} type="text">
          Изображение [png]
        </Button>
      )
    },
    {
      key: 'params',
      label: (
        <Button onClick={() => handleFileButtonClick('params')} type="text">
          Параметрическое изображение
        </Button>
      )
    }
  ];

  const propsUpload = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList
  };

  const [search, { data: assemblies, isFetching, isError }] = assemblyToolsApi.endpoints.getToolAssemblies.useLazyQuery();
  const { data: assembleInfo, refetch: refetchAssembleInfo } = useGetAssembleInfoQuery(selectedAssembly?.id, {
    skip: !selectedAssembly?.id // Пропускаем запрос, если id не задан
  });
  const [deleteAssembly] = useDeleteAssembleMutation();
  const [uploadResource] = useUploadResourceMutation();
  const [deleteResource] = useDeleteResourceMutation();

  const handleToolClick = (assembly) => {
    console.log(assembly);
    if (assembly?.id !== selectedAssembly?.id) {
      setLoadingAssembleInfo(true);
    }
    setSelectedAssembly(assembly);
  };

  const handleDeleteAssemble = async () => {
    try {
      await deleteAssembly(selectedAssembly.id).unwrap();
      setInformation([]);
      setParameters([]);
      setFilesSelect([]);
      setSelectedFile(null);
      message.success(`Сборка ${selectedAssembly.id} успешно удалена`);
    } catch (error) {
      console.log(error);
      message.error('Не удалось удалить сборку');
    }
  };

  useEffect(() => {
    if (currentPage > 1) search({ page: currentPage, searchQuery: searchQuery.trim() });
  }, [currentPage]);

  useEffect(() => {
    if (searchQuery !== '') {
      search({ page: 1, searchQuery: searchQuery.trim() });
    } else {
      search({ page: 1, searchQuery: '' });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (assemblies) setTotalElements(assemblies?.totalElements);

    if (assemblies && assemblies.results && currentPage !== 1) {
      setAlllAssemblies((prevTools) => [...prevTools, ...assemblies.results]);
    } else if (assemblies && assemblies.results && (searchQuery || currentPage === 1)) {
      setAlllAssemblies(assemblies.results);
    }
  }, [assemblies]);

  useEffect(() => {
    if (assembleInfo) {
      console.log(assembleInfo);
      setLoadingAssembleInfo(false);
      setInformation([
        { name: 'Вес', value: assembleInfo.weight },
        { name: 'Количество режущих кромок', value: assembleInfo.cutCount }
      ]);

      const assembleToolsSortered = [...assembleInfo.assembleTools].sort((a, b) => a.pos - b.pos);

      setParameters(assembleToolsSortered);

      const filesArray = assembleInfo.resources.map(({ name, url, content_type }) => ({
        value: `${process.env.REACT_APP_BASE_URL}/guide/${url}`,
        label: name,
        content_type
      }));

      setFilesSelect(filesArray);

      const stepUrlsArray = assembleInfo.assembleTools.flatMap((item) =>
        item.tool.resources
          .filter((resource) => resource.content_type === 'model/step')
          .map((resource) => `${process.env.REACT_APP_BASE_URL}/guide/` + resource.url)
      );
        const nameSTP= assembleInfo.assembleTools.flatMap((item) =>
          item.tool.id
        );
      setStpURLs(stepUrlsArray);
      setStpName(nameSTP?.reverse())
      setSelectedFile(null);

      let file;
      if (activeFileTab === 'images') {
        file = filesArray.find((f) => f.content_type === 'image/png' || f.content_type === 'image/jpg' || f.content_type === 'image/jpeg');
      } else {
        file = filesArray.find((f) => f.content_type === activeFileTab);
      }
      if (file) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
        if (activeFileTab) message.error('Файл отсутствует');
      }
    }
  }, [assembleInfo]);

  const handleFileButtonClick = (contentType) => {
    setActiveFileTab(contentType);
    let file;
    if (contentType === 'images') {
      file = filesSelect.find((f) => f.content_type === 'image/png' || f.content_type === 'image/jpg' || f.content_type === 'image/jpeg');
    } else {
      file = filesSelect.find((f) => f.content_type === contentType);
    }

    if (file) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      message.error('Файл не найден');
    }
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error('Выберите файл для загрузки.');
      return;
    }

    const formaData = new FormData();
    fileList.forEach((file) => {
      formaData.append('file', file);
    });

    setUploading(true);
    const params = new URLSearchParams({
      name: `${selectedAssembly?.id}`
    });

    try {
      await uploadResource({
        id: selectedAssembly?.id,
        params,
        body: formaData
      }).unwrap();
      message.success('Файл успешно загружен.');
    } catch (error) {
      message.error(`Ошибка во время загрузки: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteResource = async () => {
    try {
      await deleteResource({
        assembleId: selectedAssembly?.id,
        responseId: selectedFile.value.split('resource/')[1]
      }).unwrap();
      message.success('Файл успешно удален.');
    } catch (error) {
      message.error(`Ошибка во время удаления: ${error.message}`);
    }
  };

  return (
    <Layout style={{ display: 'flex', flexDirection: 'row', background: 'rgb(240, 242, 245)' }}>
      <BuildingAnAssembly
        assembleInfo={assembleInfo}
        visible={buildingAnAssemblyVisible}
        onClose={() => setBuildingAnAssemblyVisible(false)}
        refetchAssembleInfo={refetchAssembleInfo}
        stpName={stpName} 
        setStpName={setStpName}
      />

      <CreateToolAssembly visible={createAssemblyVisible} onClose={() => setCerateAssemblyVisible(false)} />
      <EditToolAssembly
        visible={editAssemblyVisible}
        onClose={() => setEditAssemblyVisible(false)}
        currentValues={assembleInfo}
        refetchAssembleInfo={() => refetchAssembleInfo()}
      />

      <Flex gap="small" vertical style={{ width: '350px', height: '100%', background: 'white' }}>
        <div style={{ padding: '10px', background: 'white', position: 'sticky', top: 0, zIndex: 1 }}>
          <Search
            size={'large'}
            onSearch={(e) => setSearchQuery(e)}
            onPressEnter={(e) => setSearchQuery(e.target.value)}
            enterButton
            placeholder="Поиск по ID"
          />
        </div>
        <Flex id="scrollableDiv" gap="small" vertical style={{ width: '100%', height: '100%', padding: '0 8px', overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={allAssemblies.length}
            next={() => {
              !isFetching && !isError ? setCurrentPage((prevPage) => prevPage + 1) : '';
            }}
            hasMore={allAssemblies.length < totalElements}
            loader={<Skeleton avatar paragraph={{ rows: 5 }} active />}
            scrollableTarget="scrollableDiv">
            <List
              loading={isFetching}
              dataSource={allAssemblies}
              renderItem={(assembly) => (
                <List.Item
                  key={assembly.id}
                  onClick={() => handleToolClick(assembly)}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: assembly.id === selectedAssembly?.id ? '#fcffe6' : 'transparent'
                  }}>
                  <List.Item.Meta
                    avatar={
                      <Image
                        width={70}
                        height={70}
                        src={
                          assembly?.group?.drawing?.includes('http') || assembly?.group?.drawing?.includes('base64')
                            ? assembly?.group?.drawing
                            : ``
                        }
                      />
                    }
                    title={<Text style={{ color: '#A0D911' }}>{assembly.id}</Text>}
                    description={<Text>{assembly.name}</Text>}
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </Flex>
      </Flex>

      <Flex gap="small" vertical style={{ flexGrow: 1, padding: '10px' }}>
        <Toolbar toolbarItems={toolbarItems} />
        <Flex gap="small" style={{ width: '100%', height: 'calc(100% - 53px)', background: 'rgb(240, 242, 245)' }}>
          <List
            loading={loadingAssembleInfo}
            style={{ width: '350px', background: 'white' }}
            header={<Title level={5}>Основная информация</Title>}
            bordered
            dataSource={information}
            renderItem={(item) => (
              <List.Item>
                <Text type="secondary">{item.name}</Text>
                <Text>{item.value}</Text>
              </List.Item>
            )}
          />
          <Flex vertical gap="small" style={{ flexGrow: 2 }}>
            <Flex
              vertical
              justify="center"
              align="center"
              style={{
                height: '50%',
                background: 'white',
                position: 'relative'
              }}>
              <Flex align="center" gap={'large'}>
                <Tabs activeKey={activeFileTab} items={fileTypeItems} style={{ background: 'white' }} size="small" />
                <Upload {...propsUpload} previewFile={null} progress={null} itemRender={() => <></>}>
                  <Button disabled={!activeFileTab} icon={<UploadOutlined />}>
                    Select File
                  </Button>
                </Upload>
                <Button type="primary" onClick={handleUpload} disabled={fileList.length === 0} loading={uploading}>
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Flex>
              <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                {selectedFile && (
                  <Button onClick={handleDeleteResource} danger style={{ position: 'absolute', top: 0, right: 0, zIndex: 5 }}>
                    Удалить
                  </Button>
                )}
                {console.log(stpURLs)}
                {console.log(selectedFile)}
                {activeFileTab === 'model/step' ? (              
                <SceneAssembly urlAssembly={stpURLs} setUrlAssembly={setStpURLs}  gridZ={gridtoolAssemblies} setGridZ={setGridtoolAssemblies}    stpName={stpName} 
                setStpName={setStpName} />
                ) : selectedFile && selectedFile.content_type === 'image/vnd.dxf' ? (
                  <DxfComponent fileName={selectedFile.label} base64Path={selectedFile.value} />
                ) : (selectedFile && selectedFile.content_type === 'image/png') ||
                  (selectedFile && selectedFile.content_type === 'image/jpeg') ? (
                  <Flex style={{ width: '100%', height: '100%' }} justify="center" align="center">
                    <Base64ToImage url={selectedFile?.value} />
                  </Flex>
                ) : (
                  <></>
                )}
              </div>
            </Flex>
            <div style={{ height: '50%', overflow: 'auto', position: 'relative', background: 'white' }}>
              <Table pagination={false} dataSource={parameters} columns={columns} loading={loadingAssembleInfo} />
            </div>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};
