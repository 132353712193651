import React, { useEffect, useState } from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import s from './style.module.css';

const Parameters = ({ dataTable, setParams }) => {
  const columnHelper = createColumnHelper();

  // Создаем базовые колонки (позиция, наименование, описание)
  const columns = [
    columnHelper.accessor('position', {
      header: 'Позиция',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('name', {
      header: 'Наименование',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('description', {
      header: 'Описание',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('isActive', {
      header: 'Активен',
      cell: (info) => (
        <input
          type="checkbox"
          checked={info.getValue()}
          onChange={() => handleCheckboxChange(info.row.index)}
        />
      ),
    }),
  ];

  const setUpdateParams = (rowIndex) => {
    setData((prevData) => {
      const updatedParams = prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            isActive: !row.isActive, // Переключаем состояние isActive
          };
        }
        return row;
      });
  
      const selectedParams = updatedParams
        .filter((row) => row.isActive) // Оставляем только активные параметры
        .map((row) => ({
          name: row.name,
          description: row.description,
          isActive: row.isActive,
          isObligatory: false, // Добавляем любые дополнительные свойства, которые необходимы
        }));
      setParams(selectedParams)
    
  
      return updatedParams;
    });
  };
  const handleCheckboxChange = (rowIndex) => {
    setUpdateParams(rowIndex);
  };

  // Инициализация данных
  const initializeData = () => {
    let position = 0;
    return dataTable.map((param) => ({
      position: ++position,
      name: param.name,
      description: param.description,
      isActive: param.isActive || false,
    }));
  };

  const [data, setData] = useState(initializeData);

  useEffect(() => {
    setData(initializeData());
  }, [dataTable]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={s.table_container}>
      <table>
        <thead className={s.cell_container}>
          {table?.getHeaderGroups().map((headerGroup) => (
            <tr className={s.cell_wrapper} key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className={s.th} key={header.id} style={{ zIndex: 10 }}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table?.getRowModel()?.rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className={s.td} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Parameters;