import { Modal, Input, Button, Form } from 'antd';
import { useEffect } from 'react';

export const EditGroup = ({ onSubmit, visible, onClose, currentValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentValues) {
      form.setFieldsValue(currentValues);
    }
  }, [currentValues, form]);

  const handleUpdate = (values) => {
    onSubmit(values);
    onClose();
  };

  return (
    <Modal footer={false} title="Создать группу" open={visible} onCancel={onClose}>
      <Form form={form} name="basic" onFinish={handleUpdate}>
        <Form.Item label="ID" name="id">
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
