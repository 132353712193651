import React, { useMemo } from 'react'
import * as THREE from 'three'

const SpriteSpheres = ({ point }) => {
	const text = 'x:' + String(point[0].toFixed(2)) + '\n' + ' y:' + String(point[1].toFixed(2)) + '\n' + ' z:' + String(point[2].toFixed(2))
	const spritePosition = [point[0], point[1] + 2, point[2]]

	const canvas = useMemo(() => {
		const width = 400 // Увеличиваем ширину канваса
		const height = 200 // Увеличиваем высоту канваса
		const borderRadius = 80 // Радиус закругления углов
		const borderWidth = 16 // Ширина границы
		const borderColor = '#0c60ff' // Цвет границы
		const canvas = document.createElement('canvas')
		canvas.width = width
		canvas.height = height
		const context = canvas.getContext('2d')

		// Функция для рисования закругленного прямоугольника
		function drawRoundedRect(ctx, x, y, w, h, r) {
			ctx.beginPath()
			ctx.moveTo(x + r, y)
			ctx.lineTo(x + w - r, y)
			ctx.quadraticCurveTo(x + w, y, x + w, y + r)
			ctx.lineTo(x + w, y + h - r)
			ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h)
			ctx.lineTo(x + r, y + h)
			ctx.quadraticCurveTo(x, y + h, x, y + h - r)
			ctx.lineTo(x, y + r)
			ctx.quadraticCurveTo(x, y, x + r, y)
			ctx.closePath()
		}

		// Рисуем фон с закругленными углами
		context.fillStyle = '#183453a1' // Цвет фона
		drawRoundedRect(context, borderWidth / 2, borderWidth / 2, width - borderWidth, height - borderWidth, borderRadius)
		context.fill()

		// Рисуем границу с закругленными углами
		context.strokeStyle = borderColor // Цвет границы
		context.lineWidth = borderWidth // Ширина границы
		drawRoundedRect(context, borderWidth / 2, borderWidth / 2, width - borderWidth, height - borderWidth, borderRadius)
		context.stroke()

		// Рисуем текст
		context.fillStyle = 'white' // Цвет текста
		context.font = '60px sans-serif' // Увеличиваем размер шрифта
		context.textAlign = 'center'
		context.textBaseline = 'middle'
		const lines = text.split('\n')
		const lineHeight = 60 // Высота строки
		const initialY = height / 2 - ((lines.length - 1) * lineHeight) / 2 // Начальная позиция Y для центрирования текста
		lines.forEach((line, index) => {
			const y = initialY + index * lineHeight
			context.fillText(line, width / 2, y)
		})

		return canvas
	}, [text])

	const texture = useMemo(() => new THREE.CanvasTexture(canvas), [canvas])

	return (
		<sprite renderOrder={3} position={spritePosition} scale={[3, 1.5, 1]} name={'HELP'}>
			<spriteMaterial attach='material' map={texture} depthTest={false} depthWrite={false} transparent={true} />
		</sprite>
	)
}

export default SpriteSpheres
