import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login/authenticate',
        method: 'POST',
        body: credentials
      })
    }),
    refresh: builder.mutation({
      query: (refreshToken) => ({
        url: '/auth/login/refresh',
        method: 'POST',
        body: { refreshToken }
      })
    })
  })
});

export const { useLoginMutation, useRefreshMutation } = authApi;
export default authApi;
