import { useEffect, useState } from 'react'
import { directionalLight } from '@react-three/drei';
import { useThree } from '@react-three/fiber'
import * as THREE from 'three'

const PointingModel = () => {
    console.log("PointingModel")
    const { pointer, camera, scene, gl } = useThree()
    const [hoveredMesh, setHoveredMesh] = useState(null)

    useEffect(() => {
        const canvas = gl.domElement
        const raycaster = new THREE.Raycaster()
        const defaultOpacity = 1
        const fadeOpacity = 0.3
        const updateMaterialsOpacity = (hoveredObject) => {
            // Проходим по всем объектам сцены
            console.log(hoveredObject)
            if (hoveredObject == null) {
                console.log("null")
                scene.traverse((object) => {
                    if (object instanceof THREE.Mesh) {
                        console.log(object)
                        if (object.material.transparent === true) {
                            object.material.transparent = false
                            object.material.needsUpdate = true
                        }
                    }
                })
            }
            else {
                scene.traverse((object) => {
                    console.log(object)
                    if (object instanceof THREE.Mesh) {  // Убрали проверку (object !== hoveredObject)
                        // Проверяем является ли материал массивом
                        if (Array.isArray(object.material)) {
                            object.material.forEach(material => {

                                if (object === hoveredObject) {
                                    if (material.transparent === false) {
                                        material.transparent = true
                                        material.needsUpdate = true
                                    }
                                    // material.depthTest = false
                                    // material.depthWrite = false
                                    // Устанавливаем высокий renderOrder, чтобы объект отрисовывался последним
                                    // object.renderOrder = 999;
                                    // Включаем правильную отрисовку прозрачности
                                    // object.material.blending = THREE.NormalBlending;
                                    // object.material.needsUpdate = true;
                                    material.opacity = defaultOpacity

                                }
                                else {
                                    if (material.transparent === false) {
                                        material.transparent = true
                                        material.needsUpdate = true
                                    }
                                    // object.renderOrder = 0;
                                    // material.depthTest = fasle
                                    // material.depthWrite = true
                                    material.opacity = fadeOpacity

                                }
                                // Изменили логику установки прозрачности
                                // material.opacity = object === hoveredObject ? defaultOpacity : fadeOpacity
                            })
                        } else if (object.material) {
                            // Если материал не прозрачный, делаем его прозрачным

                            if (object === hoveredObject) {

                                if (object.material.transparent === false) {
                                    object.material.transparent = true
                                    object.material.needsUpdate = true
                                }
                                // Устанавливаем высокий renderOrder, чтобы объект отрисовывался последним
                                // object.renderOrder = 999;
                                // Включаем правильную отрисовку прозрачности
                                // object.material.blending = THREE.NormalBlending;
                                // object.material.needsUpdate = true;
                                // object.material.depthTest = false
                                // object.material.depthWrite = false

                                object.material.opacity = defaultOpacity
                            }
                            else {
                                if (object.material.transparent === false) {
                                    object.material.transparent = true
                                    object.material.needsUpdate = true
                                }
                                // object.renderOrder = 0;
                                // object.material.depthTest = false
                                // object.material.depthWrite = true

                                object.material.opacity = fadeOpacity
                            }

                            // Изменили логику установки прозрачности
                            // object.material.opacity = object === hoveredObject ? defaultOpacity : fadeOpacity
                        }
                    }
                })
            }
        }


        const handlePointerMove = () => {
            raycaster.setFromCamera(pointer, camera)
            const intersects = raycaster.intersectObjects(scene.children, true)

            if (intersects.length > 0) {
                let currentMesh = intersects[0].object

                // Ищем родительский Mesh объект, если текущий объект не является Mesh


                if (currentMesh instanceof THREE.Mesh) {
                    if (hoveredMesh !== currentMesh) {
                        setHoveredMesh(currentMesh)
                        updateMaterialsOpacity(currentMesh)
                    }
                }
            } else {
                // Если курсор не на объекте, возвращаем всем нормальную прозрачность
                if (hoveredMesh) {
                    setHoveredMesh(null)
                    updateMaterialsOpacity(null)
                }
            }
        }

        canvas.addEventListener('pointermove', handlePointerMove)
        return () => {
            canvas.removeEventListener('pointermove', handlePointerMove)
            // Возвращаем всем объектам исходную прозрачность при размонтировании
            // updateMaterialsOpacity(null)
        }
    }, [pointer, camera, scene, hoveredMesh])

    return null
}

export default PointingModel
