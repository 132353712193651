import { ArrowLeftOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Tabs } from 'antd';
import React, { useState } from 'react';
import { General } from './general';

const { TabPane } = Tabs;

export const EditInstrument = ({ visible, onClose }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [classesDrawer, setClassesDrawer] = useState(false);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <Drawer
      width={'100vw'}
      placement="left"
      title={
        <Flex align="center" gap="60px">
          <Button onClick={onClose} size="large" icon={<ArrowLeftOutlined />} />
          <Tabs size="large" activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab="Общие" key="1" />
            <TabPane tab="Технические параметры" key="2" />
            <TabPane tab="Информация" key="3" />
          </Tabs>
        </Flex>
      }
      open={visible}
      closeIcon={false}
      footer={
        <Flex align="center" justify="end" gap="middle">
          <Button icon={<SaveOutlined />} type="primary" size="large">
            Сохранить
          </Button>
          <Button size="large">Сохранить и закрыть</Button>
          <Button icon={<CloseCircleOutlined />} danger size="large">
            Отменить
          </Button>
        </Flex>
      }>
      <div>
        {activeTab === '1' && <General classesDrawer={classesDrawer} setClassesDrawer={setClassesDrawer} />}
      </div>
    </Drawer>
  );
};
