import { Modal, Input, Button, Form } from 'antd';

export const CreateGroup = ({ onSubmit, visible, onClose }) => {
  const [form] = Form.useForm();

  const handleCreate = (values) => {
    onSubmit(values);
    form.resetFields();
    onClose();
  };

  return (
    <Modal footer={false} title="Создать группу" open={visible} onCancel={onClose}>
      <Form form={form} name="basic" onFinish={handleCreate}>
        <Form.Item
          label="ID"
          name="id"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
