import React from 'react'
import {Layout, message, Modal} from "antd";
import g from '../../general.module.scss'

const ModalConfirmDelete = ({
                                callback_open,
                                callback_submit = null ,
                                callback_close,
                                url_delete,


                                setAllElements = () => { console.error('missing property') },
                                selectedElement,
                                setSelectedElement = () => { console.error('missing property') },
                                setTotalElements = () => { console.error('missing property') },

                            }) => {
    const callback_delete = () => {
        fetch(url_delete, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    let allElements = null;
                    setAllElements((prevElements) => {
                        const result = {...prevElements, results: prevElements.results.filter(f => f.id !== selectedElement?.id)}
                        allElements = result
                        return result
                    })
                    setTotalElements((prevTotalElements) => prevTotalElements - 1)
                    setSelectedElement(allElements[0] || null)

                    message.success('Элемент удален!')
                    callback_close()
                    return res
                }
            })
    }

    return (
        <Modal
            centered
            title={'Удаление'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            onOk={callback_submit === null ?callback_delete : callback_submit}
        >
            <Layout className={g.modal_layout}>
                <span>Вы уверены, что хотите удалить?</span>
            </Layout>
        </Modal>
    )
}

export default ModalConfirmDelete