import React, { useEffect, useState, useRef } from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'
import s from "./Table.module.css"
import { PlusOutlined, ClearOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
const TableParams = ({selectedParameter, setOpenModalConfirmDelete, setSelectedParameter,data, setOpenModalCreateGroup}) => {
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('name', {
            header: 'Наименование',
            cell: info => info.getValue(),
        }), columnHelper.accessor('description', {
            header: 'Тип', cell: info => info.getValue(),
        }),
        // columnHelper.accessor('op', {
        //     header: 'Оп',
        //     cell: info => info.getValue(),
        // }), columnHelper.accessor('na2', {
        //     header: 'На...', cell: info => info.getValue(),
        // }),
        // columnHelper.accessor('pr1', {
        //     header: 'Пр...',
        //     cell: info => info.getValue(),
        // }), columnHelper.accessor('pr2', {
        //     header: 'Пр...', cell: info => info.getValue(),
        // }),
        // columnHelper.accessor('pr3', {
        //     header: 'Пр...',
        //     cell: info => info.getValue(),
        // }), columnHelper.accessor('ti', {
        //     header: 'Ти...', cell: info => info.getValue(),
        // }),
        // columnHelper.accessor('na3', {
        //     header: 'На...',
        //     cell: info => info.getValue(),
        // }), columnHelper.accessor('ka', {
        //     header: 'Ка...', cell: info => info.getValue(),
        // }),
        // columnHelper.accessor('che', {
        //     header: 'Че...',
        //     cell: info => info.getValue(),
        // }), columnHelper.accessor('ot', {
        //     header: 'От...',
        //     cell: info => info.getValue(),
        
    ];

    const table = useReactTable({
        data, columns,
        getCoreRowModel: getCoreRowModel(),
    })
    const tableRef = useRef(null);
    const [remainingWidth, setRemainingWidth] = useState('auto');
 useEffect(()=>{
     if(tableRef.current){
        const tableWidth = tableRef.current.offsetWidth;
        const cells = tableRef.current.querySelectorAll('thead th:not(:last-child)')
        let occupiedWidth = 0;
        cells.forEach(element => {
            occupiedWidth += element.offsetWidth
        }); 
        const lastCellWidth = tableWidth - (occupiedWidth / cells.length);
        setRemainingWidth(`${lastCellWidth}px`)
    }
    },[data])
    const toolbarItems = [
        {
          icon: <PlusOutlined />,
          disabled:false,
          onClick: () => {setOpenModalCreateGroup(true)}
        },
        {
            icon: <ClearOutlined />,
            disabled:selectedParameter === null,
            onClick: () => {setOpenModalConfirmDelete(true)}
          },

      ];
    return (
        <div className={s.table_container}>
           
 <Layout className={s.container_rightSide}>
             {toolbarItems.map((item) => (
        <Button key={item.key} size='large' type="text" disabled={item?.disabled} icon={item.icon} onClick={item.onClick}></Button>
      ))}
            </Layout>
            {data !== null && data !== undefined &&
        <table ref={tableRef}>
            <thead className={s.cell_container}>{table?.getHeaderGroups().map(headerGroup => (
                <tr className={s.cell_wrapper} key={headerGroup.id}>{headerGroup.headers.map(header => (
                    <th key={header.id}>{header.isPlaceholder
                        ? null : flexRender(
                            header.column.columnDef.header, header.getContext()
                        )}  </th>
                ))}</tr>
            ))}        </thead>
            <tbody>          {table?.getRowModel()?.rows.map(row => (
                <tr key={row.id}>              {row.getVisibleCells().map(cell => (
                    <td onClick={()=>{
                        console.log(cell.row.original)
                        if(selectedParameter?.name === cell.row.original.name){
                            setSelectedParameter(null)
                        }else{
                            setSelectedParameter(cell.row.original)}}} key={cell.id}
                            style={{width:cell.column.id === "empty" ? remainingWidth : "", backgroundColor:selectedParameter?.name === cell.row.original.name ? "orange" :""}}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>))}
                </tr>))}
            </tbody>        
            </table>}
            </div>
    )
}

export default TableParams