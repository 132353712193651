import React, { useEffect, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import Sphere from './Sphere';
import SpriteSpheres from './SpriteSpheres';
import Line from './Line';
import SpriteLine from './SpriteLine';
import * as THREE from 'three';

const Ruler = (props) => {
  // Получаем доступ к WebGL-окну из @react-three/fiber
  const { gl, camera, scene } = useThree();
  // Получаем контекст измерителя из ContextViewer

  const {
    SphereMass,
    setSphereMass,
    LineMass,
    setLineMass,
    SpriteSpheresMass,
    setSpriteSphereMass,
    SpriteLineMass,
    setSpriteLineMass,
    ClickScene,
    setClickScene,
    SummaLine,
    setSummaLine,
    PointToPointRuler,
    PointToTwoPointsRuler,
    SpecifyTheLineRuler,
    RegionRuler,
    CornerRuler,
    AmountRuler,
    ActionRuler
  } = props;

  useEffect(() => {
    // console.log(PointToPointRuler);
    const canvas = gl.domElement;
    canvas.removeEventListener('click', handleClick);
    setSphereMass((prevSpheres) => (prevSpheres = []));
    setLineMass((prevLines) => (prevLines = []));
    setSpriteSphereMass((prevSpriteSphereMass) => (prevSpriteSphereMass = []));
    setSpriteLineMass((prevSprites) => (prevSprites = []));
    // GohandleClick(PointToPointRuler)
    if (PointToPointRuler || PointToTwoPointsRuler || SpecifyTheLineRuler || RegionRuler || CornerRuler || AmountRuler) {
      setClickScene(0);
      setSummaLine(0);
      canvas.addEventListener('click', handleClick);
    }
  }, [PointToPointRuler, PointToTwoPointsRuler, SpecifyTheLineRuler, RegionRuler, CornerRuler, AmountRuler, ActionRuler]);

  const handleClick = useCallback(
    (event) => {
      const rect = gl.domElement.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length !== 0) {
        let intersection = null;

        // Выбор объекта, не являющегося вспомогательным
        for (let i = 0; i < intersects.length; i++) {
          if (
            intersects[i].object.name !== 'HELP' &&
            intersects[i].object.type !== 'GridHelper' &&
            intersects[i].object.type !== 'AxesHelper'
          ) {
            intersection = intersects[i];

            break;
          }
        }

        if (intersection) {
          // console.log(intersection);
          const intersectedObject = intersection.object;
          const position = intersection.point;

          const geometry = intersectedObject.geometry;
          const vertices = geometry.attributes.position.array;

          let closestPointOnEdge = null;
          let minDist = Infinity;

          for (let i = 0; i < vertices.length; i += 3) {
            const v1 = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
            const v2 = new THREE.Vector3(vertices[i + 3], vertices[i + 4], vertices[i + 5]);

            // Преобразуем вершины в мировые координаты
            v1.applyMatrix4(intersectedObject.matrixWorld);
            v2.applyMatrix4(intersectedObject.matrixWorld);

            // Найдем ближайшую точку на ребре к точке пересечения
            const closestPoint = closestPointOnLineSegment(v1, v2, position);
            const distance = position.distanceTo(closestPoint);

            if (distance < minDist) {
              minDist = distance;
              closestPointOnEdge = closestPoint;
            }
          }

          if (closestPointOnEdge) {
            setSphereMass((prevSphereMass) => [
              ...prevSphereMass,
              <Sphere key={prevSphereMass.length} position={closestPointOnEdge} uuidData={intersectedObject.uuid} />
            ]);
            setSpriteSphereMass((prevSpriteSphereMass) => [
              ...prevSpriteSphereMass,
              <SpriteSpheres key={prevSpriteSphereMass.length} point={closestPointOnEdge.toArray()} />
            ]);
            setClickScene((prevClickScene) => prevClickScene + 1);
          }
        }
      }
    },
    [camera, scene]
  );

  // Функция для нахождения ближайшей точки на отрезке (краю)
  function closestPointOnLineSegment(v1, v2, p) {
    const line = new THREE.Vector3().subVectors(v2, v1);
    const lenSq = line.lengthSq();
    if (lenSq === 0) return v1;

    const t = Math.max(0, Math.min(1, new THREE.Vector3().subVectors(p, v1).dot(line) / lenSq));
    return new THREE.Vector3().addVectors(v1, line.multiplyScalar(t));
  }
  useEffect(() => {
    if (PointToPointRuler) {
      // console.log(intersection)
      if (SphereMass.length % 2 === 0 && SphereMass.length > 0) {
        setLineMass((prevLine) => [
          ...prevLine,
          <Line
            key={prevLine.length}
            start={[
              SphereMass[SphereMass.length - 1].props.position.x,
              SphereMass[SphereMass.length - 1].props.position.y,
              SphereMass[SphereMass.length - 1].props.position.z
            ]}
            end={[
              SphereMass[SphereMass.length - 2].props.position.x,
              SphereMass[SphereMass.length - 2].props.position.y,
              SphereMass[SphereMass.length - 2].props.position.z
            ]}
            color="#0c60ff"
          />
        ]);

        const pointpriteLine = [];
        pointpriteLine[0] = (SphereMass[SphereMass.length - 1].props.position.x + SphereMass[SphereMass.length - 2].props.position.x) / 2;
        pointpriteLine[1] = (SphereMass[SphereMass.length - 1].props.position.y + SphereMass[SphereMass.length - 2].props.position.y) / 2;
        pointpriteLine[2] = (SphereMass[SphereMass.length - 1].props.position.z + SphereMass[SphereMass.length - 2].props.position.z) / 2;

        const lengthLine = Math.sqrt(
          Math.pow(SphereMass[SphereMass.length - 2].props.position.x - SphereMass[SphereMass.length - 1].props.position.x, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.y - SphereMass[SphereMass.length - 1].props.position.y, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.z - SphereMass[SphereMass.length - 1].props.position.z, 2)
        );

        setSpriteLineMass((prevSprites) => [
          ...prevSprites,
          <SpriteLine key={prevSprites.length} point={pointpriteLine} lengthLine={lengthLine} />
        ]);
      }
    }
    if (PointToTwoPointsRuler) {
      if (ClickScene === 2 && SphereMass.length > 0) {
        // console.log(SphereMass.length);
        setLineMass((prevLine) => [
          ...prevLine,
          <Line
            key={prevLine.length}
            start={[
              SphereMass[SphereMass.length - 1].props.position.x,
              SphereMass[SphereMass.length - 1].props.position.y,
              SphereMass[SphereMass.length - 1].props.position.z
            ]}
            end={[
              SphereMass[SphereMass.length - 2].props.position.x,
              SphereMass[SphereMass.length - 2].props.position.y,
              SphereMass[SphereMass.length - 2].props.position.z
            ]}
            color="#0c60ff"
          />
        ]);

        const pointpriteLine = [];
        pointpriteLine[0] = (SphereMass[SphereMass.length - 1].props.position.x + SphereMass[SphereMass.length - 2].props.position.x) / 2;
        pointpriteLine[1] = (SphereMass[SphereMass.length - 1].props.position.y + SphereMass[SphereMass.length - 2].props.position.y) / 2;
        pointpriteLine[2] = (SphereMass[SphereMass.length - 1].props.position.z + SphereMass[SphereMass.length - 2].props.position.z) / 2;

        const lengthLine = Math.sqrt(
          Math.pow(SphereMass[SphereMass.length - 2].props.position.x - SphereMass[SphereMass.length - 1].props.position.x, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.y - SphereMass[SphereMass.length - 1].props.position.y, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.z - SphereMass[SphereMass.length - 1].props.position.z, 2)
        );

        setSpriteLineMass((prevSprites) => [
          ...prevSprites,
          <SpriteLine key={prevSprites.length} point={pointpriteLine} lengthLine={lengthLine} />
        ]);
      }
      if (ClickScene === 3 && SphereMass.length > 0) {
        // console.log(SphereMass.length);
        setLineMass((prevLine) => [
          ...prevLine,
          <Line
            key={prevLine.length}
            start={[
              SphereMass[SphereMass.length - 1].props.position.x,
              SphereMass[SphereMass.length - 1].props.position.y,
              SphereMass[SphereMass.length - 1].props.position.z
            ]}
            end={[
              SphereMass[SphereMass.length - 3].props.position.x,
              SphereMass[SphereMass.length - 3].props.position.y,
              SphereMass[SphereMass.length - 3].props.position.z
            ]}
            color="#0c60ff"
          />
        ]);

        const pointpriteLine = [];
        pointpriteLine[0] = (SphereMass[SphereMass.length - 1].props.position.x + SphereMass[SphereMass.length - 3].props.position.x) / 2;
        pointpriteLine[1] = (SphereMass[SphereMass.length - 1].props.position.y + SphereMass[SphereMass.length - 3].props.position.y) / 2;
        pointpriteLine[2] = (SphereMass[SphereMass.length - 1].props.position.z + SphereMass[SphereMass.length - 3].props.position.z) / 2;

        const lengthLine = Math.sqrt(
          Math.pow(SphereMass[SphereMass.length - 3].props.position.x - SphereMass[SphereMass.length - 1].props.position.x, 2) +
            Math.pow(SphereMass[SphereMass.length - 3].props.position.y - SphereMass[SphereMass.length - 1].props.position.y, 2) +
            Math.pow(SphereMass[SphereMass.length - 3].props.position.z - SphereMass[SphereMass.length - 1].props.position.z, 2)
        );

        setSpriteLineMass((prevSprites) => [
          ...prevSprites,
          <SpriteLine key={prevSprites.length} point={pointpriteLine} lengthLine={lengthLine} />
        ]);
        setClickScene(0);
      }
    }
    if (SpecifyTheLineRuler) {
      if (SphereMass.length > 1) {
        setLineMass((prevLine) => [
          ...prevLine,
          <Line
            key={prevLine.length}
            start={[
              SphereMass[SphereMass.length - 1].props.position.x,
              SphereMass[SphereMass.length - 1].props.position.y,
              SphereMass[SphereMass.length - 1].props.position.z
            ]}
            end={[
              SphereMass[SphereMass.length - 2].props.position.x,
              SphereMass[SphereMass.length - 2].props.position.y,
              SphereMass[SphereMass.length - 2].props.position.z
            ]}
            color="#0c60ff"
          />
        ]);

        const pointpriteLine = [];
        pointpriteLine[0] = (SphereMass[SphereMass.length - 1].props.position.x + SphereMass[SphereMass.length - 2].props.position.x) / 2;
        pointpriteLine[1] = (SphereMass[SphereMass.length - 1].props.position.y + SphereMass[SphereMass.length - 2].props.position.y) / 2;
        pointpriteLine[2] = (SphereMass[SphereMass.length - 1].props.position.z + SphereMass[SphereMass.length - 2].props.position.z) / 2;

        const lengthLine = Math.sqrt(
          Math.pow(SphereMass[SphereMass.length - 2].props.position.x - SphereMass[SphereMass.length - 1].props.position.x, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.y - SphereMass[SphereMass.length - 1].props.position.y, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.z - SphereMass[SphereMass.length - 1].props.position.z, 2)
        );
        setSummaLine((prevClickScene) => prevClickScene + lengthLine);

        setSpriteLineMass((prevSprites) => [
          ...prevSprites,
          <SpriteLine key={prevSprites.length} point={pointpriteLine} lengthLine={SummaLine} />
        ]);
      }
    }
    if (AmountRuler) {
      if (SphereMass.length % 2 === 0 && SphereMass.length > 0) {
        setLineMass((prevLine) => [
          ...prevLine,
          <Line
            key={prevLine.length}
            start={[
              SphereMass[SphereMass.length - 1].props.position.x,
              SphereMass[SphereMass.length - 1].props.position.y,
              SphereMass[SphereMass.length - 1].props.position.z
            ]}
            end={[
              SphereMass[SphereMass.length - 2].props.position.x,
              SphereMass[SphereMass.length - 2].props.position.y,
              SphereMass[SphereMass.length - 2].props.position.z
            ]}
            color="#0c60ff"
          />
        ]);

        const pointpriteLine = [];
        pointpriteLine[0] = (SphereMass[SphereMass.length - 1].props.position.x + SphereMass[SphereMass.length - 2].props.position.x) / 2;
        pointpriteLine[1] = (SphereMass[SphereMass.length - 1].props.position.y + SphereMass[SphereMass.length - 2].props.position.y) / 2;
        pointpriteLine[2] = (SphereMass[SphereMass.length - 1].props.position.z + SphereMass[SphereMass.length - 2].props.position.z) / 2;

        const lengthLine = Math.sqrt(
          Math.pow(SphereMass[SphereMass.length - 2].props.position.x - SphereMass[SphereMass.length - 1].props.position.x, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.y - SphereMass[SphereMass.length - 1].props.position.y, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.z - SphereMass[SphereMass.length - 1].props.position.z, 2)
        );
        setSummaLine((prevClickScene) => prevClickScene + lengthLine);

        setSpriteLineMass((prevSprites) => [
          ...prevSprites,
          <SpriteLine key={prevSprites.length} point={pointpriteLine} lengthLine={lengthLine} />
        ]);
      }
    }
    if (CornerRuler) {
      if (ClickScene === 2 && SphereMass.length > 0) {
        // console.log(SphereMass.length);
        setLineMass((prevLine) => [
          ...prevLine,
          <Line
            key={prevLine.length}
            start={[
              SphereMass[SphereMass.length - 1].props.position.x,
              SphereMass[SphereMass.length - 1].props.position.y,
              SphereMass[SphereMass.length - 1].props.position.z
            ]}
            end={[
              SphereMass[SphereMass.length - 2].props.position.x,
              SphereMass[SphereMass.length - 2].props.position.y,
              SphereMass[SphereMass.length - 2].props.position.z
            ]}
            color="#0c60ff"
          />
        ]);

        const pointpriteLine = [];
        pointpriteLine[0] = (SphereMass[SphereMass.length - 1].props.position.x + SphereMass[SphereMass.length - 2].props.position.x) / 2;
        pointpriteLine[1] = (SphereMass[SphereMass.length - 1].props.position.y + SphereMass[SphereMass.length - 2].props.position.y) / 2;
        pointpriteLine[2] = (SphereMass[SphereMass.length - 1].props.position.z + SphereMass[SphereMass.length - 2].props.position.z) / 2;

        const lengthLine = Math.sqrt(
          Math.pow(SphereMass[SphereMass.length - 2].props.position.x - SphereMass[SphereMass.length - 1].props.position.x, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.y - SphereMass[SphereMass.length - 1].props.position.y, 2) +
            Math.pow(SphereMass[SphereMass.length - 2].props.position.z - SphereMass[SphereMass.length - 1].props.position.z, 2)
        );

        // setSpriteLineMass(prevSprites => [...prevSprites, <SpriteLine key={prevSprites.length} point={pointpriteLine} lengthLine={lengthLine} />])
      }
      if (ClickScene === 3 && SphereMass.length > 0) {
        // console.log(SphereMass.length);
        setLineMass((prevLine) => [
          ...prevLine,
          <Line
            key={prevLine.length}
            start={[
              SphereMass[SphereMass.length - 1].props.position.x,
              SphereMass[SphereMass.length - 1].props.position.y,
              SphereMass[SphereMass.length - 1].props.position.z
            ]}
            end={[
              SphereMass[SphereMass.length - 3].props.position.x,
              SphereMass[SphereMass.length - 3].props.position.y,
              SphereMass[SphereMass.length - 3].props.position.z
            ]}
            color="#0c60ff"
          />
        ]);

        const pointpriteLine = [];

        pointpriteLine[0] = (SphereMass[SphereMass.length - 1].props.position.x + SphereMass[SphereMass.length - 2].props.position.x) / 2;
        pointpriteLine[1] = (SphereMass[SphereMass.length - 1].props.position.y + SphereMass[SphereMass.length - 2].props.position.y) / 2;
        pointpriteLine[2] = (SphereMass[SphereMass.length - 1].props.position.z + SphereMass[SphereMass.length - 2].props.position.z) / 2;

        var ugl1 = new THREE.Vector3(
          SphereMass[SphereMass.length - 1].props.position.x - SphereMass[SphereMass.length - 3].props.position.x,
          SphereMass[SphereMass.length - 1].props.position.y - SphereMass[SphereMass.length - 3].props.position.y,
          SphereMass[SphereMass.length - 1].props.position.z - SphereMass[SphereMass.length - 3].props.position.z
        );
        var ugl2 = new THREE.Vector3(
          SphereMass[SphereMass.length - 2].props.position.x - SphereMass[SphereMass.length - 3].props.position.x,
          SphereMass[SphereMass.length - 2].props.position.y - SphereMass[SphereMass.length - 3].props.position.y,
          SphereMass[SphereMass.length - 2].props.position.z - SphereMass[SphereMass.length - 3].props.position.z
        );

        var ugl = THREE.MathUtils.radToDeg(ugl1.angleTo(ugl2));
        // var Stringugl=String((ugl*100/Math.PI).toFixed(3)+"°")

        const lengthLine = Math.sqrt(
          Math.pow(SphereMass[SphereMass.length - 3].props.position.x - SphereMass[SphereMass.length - 1].props.position.x, 2) +
            Math.pow(SphereMass[SphereMass.length - 3].props.position.y - SphereMass[SphereMass.length - 1].props.position.y, 2) +
            Math.pow(SphereMass[SphereMass.length - 3].props.position.z - SphereMass[SphereMass.length - 1].props.position.z, 2)
        );

        setSpriteLineMass((prevSprites) => [
          ...prevSprites,
          <SpriteLine key={prevSprites.length} point={pointpriteLine} lengthLine={ugl} />
        ]);
        setClickScene(0);
      }
    }
  }, [SphereMass]);
  // Используем эффект для регистрации/.unregistering события клика
  useEffect(() => {
    // console.log(SummaLine);
  }, [SummaLine]);

  // Функция обрабатывающая событие клика
  return (
    <>
      {SphereMass}
      {LineMass}
      {SpriteSpheresMass}
      {SpriteLineMass}
    </>
  );
};
export default Ruler;
