import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import StepModel from './StepModel';
import useContextViewer from './ContextViewer/ContextViewer';
import Ruler from './Ruler/Ruler';
import Sphere from './Ruler/Sphere';
import SpriteSpheres from './Ruler/SpriteSpheres';
import Line from './Ruler/Line';
import SpriteLine from './Ruler/SpriteLine';
import { Button } from 'antd';
import { ColumnWidthOutlined } from '@ant-design/icons';
import SettingsScene from './SettingsScene';
import StaticSpriteSize from './Size/StaticSpriteSize';
import StaticSize from './Size/StaticSize';

const styles = {
  position: 'absolute',
  top: '50px',
  zIndex: '3',
  left: '10px',
  display: 'flex',
  justifContent: 'spaceBetween',
  marginBottom: '10%'
};

const CustomAxesHelper = (props) => {
  return <axesHelper renderOrder={2} args={[1000,50]} {...props} />;
};
export const Scene = ({ url, setBlob3D }) => {
  // console.log(url)
  const {
    setPointToPointRuler,
    setPointToTwoPointsRuler,
    setSpecifyTheLineRuler,
    setCornerRuler,
    setAmountRuler,
    setRegionRuler,
    setActionRuler,

    intersections,
    SphereMass,
    setSphereMass,
    LineMass,
    setLineMass,
    SpriteSpheresMass,
    setSpriteSphereMass,
    SpriteLineMass,
    setSpriteLineMass,
    ClickScene,
    setClickScene,
    SummaLine,
    setSummaLine,
    PointToPointRuler,
    PointToTwoPointsRuler,
    SpecifyTheLineRuler,
    RegionRuler,
    CornerRuler,
    AmountRuler,
    ActionRuler
  } = useContextViewer();

  return (
    <>
      <Canvas>
        <SettingsScene />
        <StaticSize/>
        <OrbitControls />
        <StepModel setActionRuler={setActionRuler} url={url} setBlob3D={setBlob3D} />
        <CustomAxesHelper />
        <Ruler
          setSphereMass={setSphereMass}
          SphereMass={SphereMass}
          LineMass={LineMass}
          setLineMass={setLineMass}
          SpriteSpheresMass={SpriteSpheresMass}
          setSpriteSphereMass={setSpriteSphereMass}
          SpriteLineMass={SpriteLineMass}
          setSpriteLineMass={setSpriteLineMass}
          ClickScene={ClickScene}
          setClickScene={setClickScene}
          SummaLine={SummaLine}
          setSummaLine={setSummaLine}
          PointToPointRuler={PointToPointRuler}
          PointToTwoPointsRuler={PointToTwoPointsRuler}
          SpecifyTheLineRuler={SpecifyTheLineRuler}
          RegionRuler={RegionRuler}
          CornerRuler={CornerRuler}
          AmountRuler={AmountRuler}
          ActionRuler={ActionRuler}
        />

        {intersections.map((point, i, uuid) => (
          <Sphere key={`sphere-${i}`} position={point} uuidData={uuid} />
        ))}
        {intersections.map((start, end, index) => (
          <Line key={`line-${index}`} start={start} end={end} color="red" />
        ))}
        {intersections.map((point, index) => (
          <SpriteSpheres point={point} key={`sprite-${index}`} />
        ))}
        {intersections.map((point, index) => (
          <SpriteLine
            AmountRuler={AmountRuler}
            CornerRuler={CornerRuler}
            SpecifyTheLineRuler={SpecifyTheLineRuler}
            SummaLine={SummaLine}
            SpriteLineMass={SpriteLineMass}
            ClickScene={ClickScene}
            point={point}
            key={`sprite-${index}`}
          />
        ))}
      </Canvas>
      <Button
        style={{ position: 'absolute', bottom: '10px', right: '10px' }}
        onClick={() => {
          setActionRuler(!ActionRuler);
          setPointToPointRuler(false);
          setPointToTwoPointsRuler(false);
          setSpecifyTheLineRuler(false);
          setRegionRuler(false);
          setCornerRuler(false);
          setAmountRuler(false);
        }}
        className="Button"
        icon={<ColumnWidthOutlined />}></Button>
      {ActionRuler && (
        <div className="RulerButton">
          <div style={styles}>
            <Button
              onClick={() => {
                setPointToPointRuler(!PointToPointRuler);
                setPointToTwoPointsRuler(false);
                setSpecifyTheLineRuler(false);
                setRegionRuler(false);
                setCornerRuler(false);
                setAmountRuler(false);
              }}
              icon={<ColumnWidthOutlined />}></Button>
            <Button
              onClick={() => {
                setPointToPointRuler(false);
                setPointToTwoPointsRuler(!PointToTwoPointsRuler);
                setSpecifyTheLineRuler(false);
                setRegionRuler(false);
                setCornerRuler(false);
                setAmountRuler(false);
              }}
              icon={<ColumnWidthOutlined />}></Button>
            <Button
              onClick={() => {
                setPointToPointRuler(false);
                setPointToTwoPointsRuler(false);
                setSpecifyTheLineRuler(!SpecifyTheLineRuler);
                setRegionRuler(false);
                setCornerRuler(false);
                setAmountRuler(false);
              }}
              icon={<ColumnWidthOutlined />}></Button>
            <Button
              onClick={() => {
                setPointToPointRuler(false);
                setPointToTwoPointsRuler(false);
                setSpecifyTheLineRuler(false);
                setRegionRuler(false);
                setCornerRuler(!CornerRuler);
                setAmountRuler(false);
              }}
              icon={<ColumnWidthOutlined />}></Button>
            <Button
              onClick={() => {
                setPointToPointRuler(false);
                setPointToTwoPointsRuler(false);
                setSpecifyTheLineRuler(false);
                setRegionRuler(false);
                setCornerRuler(false);
                setAmountRuler(!AmountRuler);
              }}
              icon={<ColumnWidthOutlined />}></Button>
          </div>
        </div>
      )}
    </>
  );
};
