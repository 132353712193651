import {Layout, Flex, Image, Typography, Input, Table, List, Skeleton, Empty, Tag, message} from 'antd';
import {guidesApi, useGetAdapterClassesWithChildrenQuery} from '../../services/guidesApi';
import React, { useEffect, useState } from 'react';
import { ClearOutlined, CopyOutlined, DeleteOutlined, EditOutlined, HomeOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import g from "../../general.module.scss";
import {ModalCreateAdapter, ModalEditAdapter} from "./AdapterModals";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";

const { Title, Text } = Typography;

const columns = [
    {
        title: 'Позиция',
        dataIndex: 'pos'
    },
    {
        title: 'Элемент',
        dataIndex: ['tool', 'id'],
        render: (id, record) => (
            <Flex gap="small" vertical>
                <Text>{record.tool.id}</Text>
                <Text>{record.tool.name}</Text>
                <Text>{record.tool.description}</Text>
            </Flex>
        )
    },
    {
        title: 'Количество',
        dataIndex: 'quantity'
    }
];

const AdapterGroups = () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedAdapterId, setSelectedAdapterId] = useState(null);
    const [selectedAdapter, setSelectedAdapter] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);

    //const { data: adapter, isFetching, isError } = useGetAdapterClassesWithChildrenQuery(currentPage);
    const [search, {data: adapter, isFetching, isError}] = guidesApi.endpoints.getAdapters.useLazyQuery();


    const handleToolClick = (branch) => {
        console.log(branch);
        setSelectedAdapterId(branch.id);
        setSelectedAdapter(branch)
        console.log(branch)

        // const parameters = branch.branches.map((item) => ({
        //     key: item.id,
        //     ...item
        // }));
        //
        // setParameters(parameters);
        //
        // setInformation([
        //     { name: 'ID', value: branch.id },
        //     { name: 'Наименование', value: branch.name }
        // ]);
    };

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => {setOpenModalCreateAdapter(true)}
        },
        {
            icon: <EditOutlined />,
            disabled: selectedAdapter === null,
            onClick: () => {setOpenModalEditAdapter(true)}
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedAdapter === null,
            onClick: () => { setOpenModalConfirmDelete(true) }
        },
    ]

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetch(!refetch)
    }

    const [openModalEditAdapter, setOpenModalEditAdapter] = useState(false)
    const handleCloseModalEditAdapter = () => {
        setOpenModalEditAdapter(false)
        setRefetch(!refetch)
    }

    const [openModalCreateAdapter, setOpenModalCreateAdapter] = useState(false)
    const handleCloseModalCreateAdapter = () => {
        setOpenModalCreateAdapter(false)
        setRefetch(!refetch)
    }


    useEffect(() => {
        if (adapter && totalElements === 0) setTotalElements(adapter.totalElements);
        setAllData(adapter)
    }, [adapter]);
    const [refetch, setRefetch] = useState(null);

    const [allData, setAllData] = useState(null)

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedAdapter}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}
        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedAdapter && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedAdapter?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedAdapter?.name}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Используется для'}</Text>
                        <Text style={{display: 'flex', flexWrap: 'wrap'}}>
                            {selectedAdapter?.toolOrAssemble === 1 || selectedAdapter?.toolOrAssemble === 3  ? <Tag color={'blue'}>Инструмент</Tag> : ""}
                            {selectedAdapter?.toolOrAssemble === 2 || selectedAdapter?.toolOrAssemble === 3  ? <Tag color={'pink'}>Сборка</Tag> : ""}
                            {!!!selectedAdapter?.toolOrAssemble || selectedAdapter?.toolOrAssemble === 0 ? <Tag>Не используется</Tag> : ""}
                        </Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{ flexGrow: 2 }} className={g.container_div}>
                <Layout style={{  background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={`${g.container_block} ${g.container_block_image}`}>
                    {/* {file ? <Scene url={file} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />} */}
                    <Image className={g.container_block_image_div} src={selectedAdapter?.drawing?.includes('base64') ? selectedAdapter?.drawing : `data:image/jpeg;base64,${selectedAdapter?.drawing}`} />
                </Layout>
            </Flex>

            <ModalCreateAdapter
                callback_open={openModalCreateAdapter}
                callback_close={handleCloseModalCreateAdapter}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedAdapter}

            />
            <ModalEditAdapter
                callback_open={openModalEditAdapter}
                callback_close={handleCloseModalEditAdapter}

                selectedAdapter={selectedAdapter}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedAdapter}
            />

            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/adapter/${selectedAdapter?.id}`}

                setAllElements={setAllData}
                selectedElement={selectedAdapter}
                setSelectedElement={setSelectedAdapter}
                setTotalElements={setTotalElements}
            />
        </ContainerAside>
    );
};

export default AdapterGroups
