import { ArrowLeftOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Tabs, message } from 'antd';
import React, { useState, useEffect } from 'react';
import CreateCopyInstrumentLayout from './CreateCopyInstrumentLayout';
import ChooseInfo from './ChooseInfo';
import s from './style.module.css';
const { TabPane } = Tabs;

const CreateCopyInstrument = ({ tool, setTool, setTotalElements, allTools, setAllTools, visible, onClose }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [classesDrawer, setClassesDrawer] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [renderFlag, setRenderFlag] = useState(false);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const [checkboxState, setCheckboxState] = useState({
    parameters: true,
    documents: true,
    information: true,
    specification: true
  });
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    description: '',
    weight: '',
    countStage: '',
    countCuts: '',
    group: {},
    adapter: {},
    cutGrade: {},
    parameters: [],
    resources: [{}]
  });

  const editHandler = async (state) => {
    const newFormData = { ...formData };
    if (!checkboxState.parameters) {
      delete newFormData.parameters;
    }
    if (!checkboxState.documents) {
      delete newFormData.resources;
    }
    if (!checkboxState.information) {
      delete newFormData.adapter;
      delete newFormData.cutGrade;
      delete newFormData.countStage;
    }
    if (!checkboxState.parameters) {
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARAM_TOOL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newFormData)
      });
      if (response.ok) {
        const json = await response.json();
        setAllTools([...allTools, { ...json }]);
        setTotalElements((prevValue) => prevValue + 1);
        setTool(json);
        if (state === true) {
          onClose();
        }
        console.log(response);
        message.success('Инструмент успешно создан');
      } else {
        message.error('Ошибка создания');
      }
      console.log(newFormData, response);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    console.log(tool);
    if (tool !== null && tool !== undefined) {
      setRenderFlag(false);
      console.log(tool);
      setFormData(tool);
      setSelectedClass(tool?.tmdClass);
      setSelectedGroup(tool?.group);
    }
  }, [tool, visible]);
  useEffect(() => {
    setRenderFlag(true);
  }, [renderFlag]);
  return (
    <Drawer
      width={'100vw'}
      placement="left"
      title={
        <Flex align="center" gap="60px">
          <Button onClick={onClose} size="large" icon={<ArrowLeftOutlined />} />
          <Tabs size="large" activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab="Общие" key="1" />
          </Tabs>
        </Flex>
      }
      open={visible}
      closeIcon={false}
      footer={
        <Flex align="center" justify="end" gap="middle">
          <Button
            onClick={() => {
              editHandler(false);
            }}
            icon={<SaveOutlined />}
            type="primary"
            size="large">
            Создать
          </Button>
          <Button
            onClick={() => {
              editHandler(true);
            }}
            size="large">
            Сохранить и закрыть
          </Button>
          <Button onClick={onClose} icon={<CloseCircleOutlined />} danger size="large">
            Отменить
          </Button>
        </Flex>
      }>
      <div>
        {activeTab === '1' && renderFlag && (
          <div className={s.infoWrapper}>
            <CreateCopyInstrumentLayout
              tool={tool}
              fileList={fileList}
              setFileList={setFileList}
              uploading={uploading}
              setSelectedGroup={setSelectedGroup}
              selectedGroup={selectedGroup}
              classesDrawer={classesDrawer}
              selectedClass={selectedClass}
              setSelectedClass={setSelectedClass}
              setClassesDrawer={setClassesDrawer}
              formData={formData}
              setFormData={setFormData}
            />
            <ChooseInfo checkboxState={checkboxState} setCheckboxState={setCheckboxState} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default CreateCopyInstrument;
