import { ArrowLeftOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, message, Tabs } from 'antd';
import React, { useState } from 'react';
import { General } from './general';
import { useCreateOperationPlanMutation } from '../../services/operationPlan';
import { ProcessingSteps } from './processingSteps';

const { TabPane } = Tabs;

export const CreateOperationPlan = ({ tool, visible, onClose }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [classesDrawer, setClassesDrawer] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [name, setName] = useState(null);
  const [materialId, setMaterialId] = useState(null);
  const [materialName, setMaterialName] = useState(null);

  const [createOperationPlan] = useCreateOperationPlanMutation();

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const save = async () => {
    try {
      await createOperationPlan({
        detailId: tool.id,
        plan: { name, planId, materialId, materialName, processingSteps: [] }
      }).unwrap();

      message.success('Операционный план успешно создан');
    } catch (e) {
      message.error('Не удалось создать операционный план');
    }
  };

  const saveAndClose = async () => {
    try {
      await createOperationPlan({
        detailId: tool.id,
        plan: { name, planId, materialId, materialName, processingSteps: [] }
      }).unwrap();

      onClose();
      message.success('Операционный план успешно создан');
    } catch (e) {
      message.error('Не удалось создать операционный план');
    }
  };

  return (
    <Drawer
      width={'100vw'}
      placement="left"
      title={
        <Flex align="center" gap="60px">
          <Button onClick={onClose} size="large" icon={<ArrowLeftOutlined />} />
          <Tabs size="large" activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab="Общие" key="1" />
            <TabPane tab="Шаг обработки" key="2" />
          </Tabs>
        </Flex>
      }
      open={visible}
      closeIcon={false}
      footer={
        activeTab === '1' ? (
          <Flex align="center" justify="end" gap="middle">
            <Button
              onClick={save}
              icon={<SaveOutlined />}
              disabled={!planId || !name || !materialId || !materialName}
              type="primary"
              size="large">
              Создать план
            </Button>
            <Button onClick={saveAndClose} disabled={!planId || !name || !materialId || !materialName} size="large">
              Создать план и закрыть
            </Button>
            <Button onClick={onClose} icon={<CloseCircleOutlined />} danger size="large">
              Отменить
            </Button>
          </Flex>
        ) : (
          false
        )
      }>
      <div style={{ height: '100%' }}>
        {activeTab === '1' && (
          <General
            setPlanId={setPlanId}
            setName={setName}
            setMaterialId={setMaterialId}
            setMaterialName={setMaterialName}
            tool={tool}
            classesDrawer={classesDrawer}
            setClassesDrawer={setClassesDrawer}
          />
        )}
        {activeTab === '2' && <ProcessingSteps tool={tool} />}
      </div>
    </Drawer>
  );
};
