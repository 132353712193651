import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCenter: {}
};

const editingCostCenterSlice = createSlice({
  name: 'editingCostCenter',
  initialState,
  reducers: {
    setSelectCenter(state, action) {
      state.selectedCenter = action.payload;
    }
  }
});

export default editingCostCenterSlice.reducer;
export const { setSelectCenter } = editingCostCenterSlice.actions;
