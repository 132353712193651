import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import Line from './Ruler/Line';
import Sphere from './Ruler/Sphere';
import SpriteSize from './Size/SpriteSize';
const Size = (obj) => {
  console.log('Size');
  console.log(obj.obj);

  const { scene } = useThree();

  // useEffect(() => {
  console.log(scene);

  // Compute the bounding box
  const bbox = new THREE.Box3().setFromObject(obj.obj);
  // Init your size variable
  const bboxSize = new THREE.Vector3(bbox);
  // Get the size
  bbox.getSize(bboxSize);
  // Now use bboxSize (x,y,z)
  console.log(bboxSize);
  console.log(bbox);

  const color = '#A0D911';
  //  ;
  console.log('obj', obj.obj.children)
  var MaxZ, MinZ, MaxX, MaxY, MinX, MinY;
  obj.obj.children.forEach((mesh) => {

    // Получаем атрибут вершин из BufferGeometry
    const positionAttribute = mesh.geometry.getAttribute('position');

    // Обходим все вершины
    for (let i = 0; i < positionAttribute.count; i++) {
      // Получаем координаты точки в мировой системе координат
      const worldPosition = new THREE.Vector3();

      worldPosition.fromBufferAttribute(positionAttribute, i).applyMatrix4(mesh.matrixWorld);

      // Проверяем координату 
      if (worldPosition.z === bbox.max.z) {
        if (!MaxZ) {
          MaxZ = worldPosition
        }
      }
      if (worldPosition.z === bbox.min.z) {
        if (!MinZ) {
          MinZ = worldPosition
        }
      }
      if (worldPosition.x === bbox.max.x) {
        if (!MaxX) {
          MaxX = worldPosition
        }
      }
      if (worldPosition.x === bbox.min.x) {
        if (!MinX) {
          MinX = worldPosition
        }
      }
      if (worldPosition.y === bbox.max.y) {
        if (!MaxY) {
          MaxY = worldPosition
        }
      }
      if (worldPosition.y === bbox.min.y) {

        if (!MinY) {
          MinY = worldPosition
        }
      }
    }
  })


  // },[scene])
  const axisDeviationZ = 5;
  const axisDeviationY = -5;
  const axisDeviationX = -5;
  const axisY = Math.max(Math.abs(bbox.max.y), Math.abs(bbox.min.y)) + 5
  const axisX = Math.max(Math.abs(bbox.max.x), Math.abs(bbox.min.x)) + 5
  const axisZ = Math.max(Math.abs(bbox.max.z), Math.abs(bbox.min.z)) + 5
  const leng1 = Math.sqrt(Math.pow(bbox.max.x - bbox.min.x, 2) + Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2));
  const leng2 = Math.sqrt(Math.pow(0 - 0, 2) + Math.pow(bbox.max.y - bbox.min.y, 2) + Math.pow(0 - 0, 2));
  const leng3 = Math.sqrt(Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2) + Math.pow(bbox.max.z - bbox.min.z, 2));
  const maxleng = Math.max(leng3, Math.max(leng1, leng2));
  var adaptate = Math.round(maxleng / 50);
  if (adaptate === 0) {
    adaptate = 1;
  }
  adaptate = adaptate + 1;
  return (
    <>
      <gridHelper rotation={[Math.PI / 2, 0, 0]} position={[0, 0, bbox.min.z - adaptate]} renderOrder={1} args={[500, 50]} />

      <Line start={[MaxX.x, (MaxX.y + MinX.y) / 2, (MaxX.z + MinX.z) / 2 + axisZ]} end={[MinX.x, (MaxX.y + MinX.y) / 2, (MaxX.z + MinX.z) / 2 + axisZ]} color={color} />

      <Line start={[MaxX.x, (MaxX.y + MinX.y) / 2, (MaxX.z + MinX.z) / 2]} end={[MaxX.x, (MaxX.y + MinX.y) / 2, (MaxX.z + MinX.z) / 2 + axisZ]} color={color} />

      <Line start={[MinX.x, (MaxX.y + MinX.y) / 2, (MaxX.z + MinX.z) / 2]} end={[MinX.x, (MaxX.y + MinX.y) / 2, (MaxX.z + MinX.z) / 2 + axisZ]} color={color} />

      <Line
        start={[MaxX.x, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2]}
        end={[MaxX.x - 1, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2 - 0.5]}
        color={color}
      />
      <Line
        start={[MaxX.x, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2]}
        end={[MaxX.x - 1, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2 + 0.5]}
        color={color}
      />

      <Line
        start={[MinX.x, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2]}
        end={[MinX.x + 1, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2 - 0.5]}
        color={color}
      />
      <Line
        start={[MinX.x, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2]}
        end={[MinX.x + 1, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2 + 0.5]}
        color={color}
      />

      <Sphere position={[MaxX.x, (MaxX.y + MinX.y) / 2, (MaxX.z + MinX.z) / 2]} color={color} opacity={1} />
      <Sphere position={[MinX.x, (MaxX.y + MinX.y) / 2, (MaxX.z + MinX.z) / 2]} color={color} opacity={1} />
      {/* <SpriteSpheres point={[bbox.max.x, 0, 0]} reclamationX={3} colorback={color} colorTexst='black' />
            <SpriteSpheres point={[bbox.min.x, 0, 0]} reclamationX={-3} colorback={color} colorTexst='black' /> */}

      {/* <SpriteLine point={[(bbox.max.x + bbox.min.x) / 2 , 0, axisDeviationZ + bbox.max.z]} lengthLine={Math.sqrt(Math.pow(bbox.max.x - bbox.min.x, 2) + Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2))} a={0} color={color} colorback={color} colorTexst='black' adaptate={adaptate} size={true}/> */}

      <SpriteSize
        point={[(MaxX.x + MinX.x) / 2, (MaxX.y + MinX.y) / 2, axisZ + (MaxX.z + MinX.z) / 2]}
        textSize={Math.sqrt(Math.pow(bbox.max.x - bbox.min.x, 2) + Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2))}
        c={1.5}
        color={color}
        colorback={color}
        colorTexst="black"
        adaptate={adaptate}
      />

      <Line start={[(MaxY.x + MinY.x) / 2 - axisX, MaxY.y, (MaxY.z + MinY.z) / 2]} end={[(MaxY.x + MinY.x) / 2 - axisX, MinY.y, (MaxY.z + MinY.z) / 2]} color={color} />

      <Line start={[(MaxY.x + MinY.x) / 2 - axisX, MaxY.y, (MaxY.z + MinY.z) / 2]} end={[(MaxY.x + MinY.x) / 2, MaxY.y, (MaxY.z + MinY.z) / 2]} color={color} />

      <Line start={[(MaxY.x + MinY.x) / 2 - axisX, MinY.y, (MaxY.z + MinY.z) / 2]} end={[(MaxY.x + MinY.x) / 2, MinY.y, (MaxY.z + MinY.z) / 2]} color={color} />

      <Line
        start={[(MaxY.x + MinY.x) / 2 - axisX, MaxY.y, (MaxY.z + MinY.z) / 2]}
        end={[(MaxY.x + MinY.x) / 2 - axisX + 0.5, MaxY.y - 1, (MaxY.z + MinY.z) / 2]}
        color={color}
      />
      <Line
        start={[(MaxY.x + MinY.x) / 2 - axisX, MaxY.y, (MaxY.z + MinY.z) / 2]}
        end={[(MaxY.x + MinY.x) / 2 - axisX - 0.5, MaxY.y - 1, (MaxY.z + MinY.z) / 2]}
        color={color}
      />

      <Line
        start={[(MaxY.x + MinY.x) / 2 - axisX, MinY.y, (MaxY.z + MinY.z) / 2]}
        end={[(MaxY.x + MinY.x) / 2 - axisX + 0.5, MinY.y + 1, (MaxY.z + MinY.z) / 2]}
        color={color}
      />
      <Line
        start={[(MaxY.x + MinY.x) / 2 - axisX, MinY.y, (MaxY.z + MinY.z) / 2]}
        end={[(MaxY.x + MinY.x) / 2 - axisX - 0.5, MinY.y + 1, (MaxY.z + MinY.z) / 2]}
        color={color}
      />

      <Sphere position={[(MaxY.x + MinY.x) / 2, MaxY.y, (MaxY.z + MinY.z) / 2]} color={color} opacity={1} />
      <Sphere position={[(MaxY.x + MinY.x) / 2, MinY.y, (MaxY.z + MinY.z) / 2]} color={color} opacity={1} />

      <SpriteSize
        point={[(MaxY.x + MinY.x) / 2 - axisX, (MaxY.y + MinY.y) / 2, (MaxY.z + MinY.z) / 2]}
        textSize={Math.sqrt(Math.pow(0 - 0, 2) + Math.pow(bbox.max.y - bbox.min.y, 2) + Math.pow(0 - 0, 2))}
        a={-2.3}
        color={color}
        colorback={color}
        colorTexst="black"
        adaptate={adaptate}
      />

      {/* <Line start={[0, bbox.min.y + axisDeviationX, bbox.max.z]} end={[0, bbox.min.y + axisDeviationX, bbox.min.z]} color={color} />

      <Line start={[0, bbox.min.y + axisDeviationX, bbox.max.z]} end={[0, 0, bbox.max.z]} color={color} />

      <Line start={[0, bbox.min.y + axisDeviationX, bbox.min.z]} end={[0, 0, bbox.min.z]} color={color} />

      <Line
        start={[0, bbox.min.y + axisDeviationX, bbox.max.z]}
        end={[0, bbox.min.y + axisDeviationX + 0.5, bbox.max.z - 1]}
        color={color}
      />
      <Line
        start={[0, bbox.min.y + axisDeviationX, bbox.max.z]}
        end={[0, bbox.min.y + axisDeviationX - 0.5, bbox.max.z - 1]}
        color={color}
      />

      <Line
        start={[0, bbox.min.y + axisDeviationX, bbox.min.z]}
        end={[0, bbox.min.y + axisDeviationX + 0.5, bbox.min.z + 1]}
        color={color}
      />
      <Line
        start={[0, bbox.min.y + axisDeviationX, bbox.min.z]}
        end={[0, bbox.min.y + axisDeviationX - 0.5, bbox.min.z + 1]}
        color={color}
      /> */}
      <Line start={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, MaxZ.z]} end={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, MinZ.z]} color={color} />

      <Line start={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2), MaxZ.z]} end={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, MaxZ.z]} color={color} />

      <Line start={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2), MinZ.z]} end={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, MinZ.z]} color={color} />

      <Line
        start={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, MaxZ.z]}
        end={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY + 0.5, MaxZ.z - 1]}
        color={color}
      />
      <Line
        start={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, MaxZ.z]}
        end={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY - 0.5, MaxZ.z - 1]}
        color={color}
      />

      <Line
        start={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, MinZ.z]}
        end={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY + 0.5, MinZ.z + 1]}
        color={color}
      />
      <Line
        start={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, MinZ.z]}
        end={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY - 0.5, MinZ.z + 1]}
        color={color}
      />

      <Sphere position={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2), MaxZ.z]} color={color} opacity={1} />
      <Sphere position={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2), MinZ.z]} color={color} opacity={1} />

      <SpriteSize
        point={[(MaxZ.x + MinZ.x) / 2, ((MaxZ.y + MinZ.y) / 2) - axisY, (MaxZ.z + MinZ.z) / 2]}
        textSize={Math.sqrt(Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2) + Math.pow(bbox.max.z - bbox.min.z, 2))}
        b={-2.3}
        color={color}
        colorback={color}
        colorTexst="black"
        adaptate={adaptate}
      />
    </>
  );
};

export default Size;
