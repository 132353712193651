import { Button, Flex } from 'antd';
import g from '../../general.module.scss'

export const Toolbar = ({ toolbarItems }) => {
  return (
    <Flex gap="small" align='center' className={g.toolbar}>
      {toolbarItems.map((item) => (
        <Button key={item.key} size='large' type="text" disabled={item?.disabled} icon={item.icon} onClick={item.onClick}></Button>
      ))}
    </Flex>
  );
};
