import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    ColorPicker,
    Form,
    Input,
    InputNumber,
    Layout,
    message,
    Modal,
    Select,
    Switch,
    Upload
} from "antd";
import g from "../../general.module.scss";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import tool from '../../content/images/tool.svg'
import assembly from '../../content/images/assembly.svg'

export const ModalEditMaterial = ({callback_open, callback_close, selectedMaterial, materialClasses , allElements, setAllElements, setSelectedElement, setTotalElements}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        const result = {...body, materialClass: {id: body?.materialClass}}
        console.log('BEFORE ->', body)
        console.log('AFTER ->', result)
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/material`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    setAllElements({...allElements, results: [...allElements.results.filter(f => f.id !== selectedMaterial.id), body]})
                    // setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    callback_close()
                    setIsWaitingResponse(false)
                    return res
                }
            })
    }

    return (
        <Modal
            centered
            title={'Изменение материала'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            destroyOnClose
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                    name={'createBranch'}
                    initialValues={{
                        id: selectedMaterial?.id,
                        name: selectedMaterial?.name,
                        hardness: selectedMaterial?.hardness,
                        tensileStrength: selectedMaterial?.tensileStrength,
                        materialClass: {label: selectedMaterial?.materialClass?.name, value: selectedMaterial?.materialClass?.id},
                    }}
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="hardness"
                    label="Жесткость"
                    rules={[
                        {required: false},
                    ]}
                >
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item
                    name="tensileStrength"
                    label="Сила растяжения"
                    rules={[
                        {required: false},
                    ]}
                >
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item
                    name="materialClass"
                    label="Класс материала"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Select
                        options={materialClasses?.results?.map((el,i) => {
                            return (
                                {
                                    label: el?.name,
                                    value: el?.id
                                }
                            )
                        })}
                    />
                </Form.Item>
            </Layout>
        </Modal>
    )
}

export const ModalCreateMaterial = ({callback_open, callback_close, materialClasses, allElements, setAllElements, setTotalElements, setSelectedElement}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        const result = {...body, materialClass: {id: body.materialClass}}
        console.log('BEFORE ->', body)
        console.log('AFTER ->', result)
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/material`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    console.log(allElements)
                    setAllElements({...allElements, results: [...allElements.results, body]})
                    setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    setIsWaitingResponse(false)
                    callback_close()
                    return res
                }
            })
            .catch(e => {
                message.error(`${e}`)
                setIsWaitingResponse(false)
            })
    }

    return (
        <Modal
            centered
            title={'Создание адаптера'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="hardness"
                    label="Жесткость"
                    rules={[
                        {required: false},
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    name="tensileStrength"
                    label="Сила растяжения"
                    rules={[
                        {required: false},
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    name="materialClass"
                    label="Класс материала"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Select
                        options={materialClasses?.results?.map((el,i) => {
                            return (
                                {
                                    label: el?.name,
                                    value: el?.id
                                }
                            )
                        })}
                    />
                </Form.Item>
            </Layout>
        </Modal>
    )
}