import { Modal, Input, Button, Form, Select } from 'antd';
import { useCreateRoleMutation, useCreateUserMutation } from '../../services/usersApi';

export const CreateRole = ({ createRoleOpen, setCreateRoleOpen }) => {
  const [form] = Form.useForm();

  const [createRole] = useCreateRoleMutation();

  const handleCreate = async (values) => {
    try {
      await createRole(values).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    } finally {
      setCreateRoleOpen(false);
    }
  };

  return (
    <Modal footer={false} title="Создание новой роли" open={createRoleOpen} onCancel={() => setCreateRoleOpen(false)}>
      <Form form={form} onFinish={handleCreate} name="basic" autoComplete="off">
        <Form.Item
          label="Наименование"
          name="roleName"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button htmlType="submit" type="primary">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
