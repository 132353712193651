import sp from './StartPage.module.scss';
import { addTab, setActiveTab } from '../../features/headerSlice';
import { useDispatch } from 'react-redux';
import { PageItem } from '../../components/StartPageComponents/StartPageComponents';

import { Layout, Radio, Typography } from 'antd';
import { useEffect, useState } from 'react';
const { Title } = Typography;

import assembly from '../../content/images/assembly.svg';
import dispenser from '../../content/images/dispenser.svg';
import tool from '../../content/images/tool.svg';
import tool_list from '../../content/images/tool_list.svg';
import users_manager from '../../content/images/users_manager.svg';
import warehouse from '../../content/images/warehouse.svg';
import star from '../../content/images/star.svg';
import grid from '../../content/images/grid.svg';

export const StartPage = () => {
  const dispatch = useDispatch();

  const handleClick = (tab) => {
    dispatch(setActiveTab(tab.key));
    dispatch(addTab(tab));
  };

  const [favouriteSwitch, setFavouriteSwitch] = useState(null);
  const [lastSelectedApp, setLastSelectedApp] = useState(null);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    setApps([
      {
        key: '1',
        label: 'Инструменты',
        img_path: `${tool}`,
        url_path: '/instruments',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: true,
        onClickHandler: () => {
          handleClick({
            key: 'instruments',
            label: 'Инструменты',
            closable: true,
            path: '/instruments'
          });
        }
      },
      {
        key: '2',
        label: 'Инструментальные сборки',
        img_path: `${assembly}`,
        url_path: '/toolAssemblies',
        tag: { title: 'Цех', color: 'orange' },
        isFavourite: true,
        onClickHandler: () => {
          handleClick({
            key: 'toolAssemblies',
            label: 'Инструментальные сборки',
            closable: true,
            path: '/toolAssemblies'
          });
        }
      },
      {
        key: '3',
        label: 'Стандартная выдача',
        img_path: `${dispenser}`,
        url_path: '/standardIssue',
        tag: { title: 'Цех', color: 'orange' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'standardIssue',
            label: 'Стандартная выдача',
            closable: true,
            path: '/standardIssue'
          });
        }
      },
      {
        key: '4',
        label: 'Управление пользователем',
        img_path: `${users_manager}`,
        url_path: '/userAdministration',
        tag: { title: 'Планирование инструмента', color: 'pink' },
        isFavourite: true,
        onClickHandler: () => {
          handleClick({
            key: 'userAdministration',
            label: 'Управление пользователем',
            closable: true,
            path: '/userAdministration'
          });
        }
      },
      {
        key: 'orders',
        label: 'Минимальный размер заявки склада',
        img_path: `${users_manager}`,
        url_path: '/orders',
        tag: { title: 'Планирование инструмента', color: 'pink' },
        isFavourite: true,
        onClickHandler: () => {
          handleClick({
            key: 'orders',
            label: 'Минимальный размер заявки склада',
            closable: true,
            path: '/orders'
          });
        }
      },
      {
        key: '5',
        label: 'Классы и группы',
        img_path: `${warehouse}`,
        url_path: '/classesAndGroups',
        tag: { title: 'Планирование инструмента', color: 'pink' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'classesAndGroups',
            label: 'Классы и группы',
            closable: true,
            path: '/classesAndGroups'
          });
        }
      },
      {
        key: '6',
        label: 'Корректировка склада',
        img_path: `${warehouse}`,
        url_path: '/editingCostCenter',
        tag: { title: 'Планирование инструмента', color: 'pink' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'editingCostCenter',
            label: 'Корректировка склада',
            closable: true,
            path: '/editingCostCenter'
          });
        }
      },
      {
        key: '7',
        label: 'Группы станков',
        img_path: `${tool_list}`,
        url_path: '/machineGroups',
        tag: { title: 'Цех', color: 'orange' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'machineGroups',
            label: 'Группы станков',
            closable: true,
            path: '/machineGroups'
          });
        }
      },
      {
        key: '8',
        label: 'Группы материалов',
        img_path: `${tool_list}`,
        url_path: '/materialGroups',
        tag: { title: 'Цех', color: 'orange' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'materialGroups',
            label: 'Группы материалов',
            closable: true,
            path: '/materialGroups'
          });
        }
      },
      {
        key: '9',
        label: 'Классы деталей',
        img_path: `${tool_list}`,
        url_path: '/detailClasses',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'detailClasses',
            label: 'Классы деталей',
            closable: true,
            path: '/detailClasses'
          });
        }
      },
      {
        key: 'costCenters',
        label: 'Центры затрат',
        img_path: '',
        url_path: '/costCenters',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: true,
        onClickHandler: () => {
          handleClick({
            key: 'costCenters',
            label: 'Центры затрат',
            closable: true,
            path: '/costCenters'
          });
        }
      },
      {
        key: 'orderRequests',
        label: 'Заявки на заказ',
        img_path: '',
        url_path: '/orderRequests',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: true,
        onClickHandler: () => {
          handleClick({
            key: 'orderRequests',
            label: 'Заявки на заказ',
            closable: true,
            path: '/orderRequests'
          });
        }
      },
      {
        key: '10',
        label: 'Отрасли',
        img_path: '',
        url_path: '/branches',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'branches',
            label: 'Отрасли',
            closable: true,
            path: '/branches'
          });
        }
      },
      {
        key: '12',
        label: 'Типы компаний',
        img_path: `${tool_list}`,
        url_path: '/companyTypes',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'companyTypes',
            label: 'Типы компаний',
            closable: true,
            path: '/companyTypes'
          });
        }
      },
      {
        key: '13',
        label: 'Языки',
        img_path: '',
        url_path: '/languages',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'languages',
            label: 'Языки',
            closable: true,
            path: '/languages'
          });
        }
      },
      {
        key: '14',
        label: 'Страны',
        img_path: '',
        url_path: '/countries',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'countries',
            label: 'Страны',
            closable: true,
            path: '/countries'
          });
        }
      },
      {
        key: '15',
        label: 'Компании',
        img_path: '',
        url_path: '/companies',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'companies',
            label: 'Компании',
            closable: true,
            path: '/companies'
          });
        }
      },
      {
        key: '16',
        label: 'Типы классов',
        img_path: `${tool_list}`,
        url_path: '/classTypes',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'classTypes',
            label: 'Типы классов',
            closable: true,
            path: '/classTypes'
          });
        }
      },
      {
        key: '17',
        label: 'Режущие кромки',
        img_path: ``,
        url_path: '/cutGrades',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'cutGrades',
            label: 'Режущие кромки',
            closable: true,
            path: '/cutGrades'
          });
        }
      },
      {
        key: '19',
        label: 'Адаптеры',
        img_path: ``,
        url_path: '/adapters',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'adapters',
            label: 'Адаптеры',
            closable: true,
            path: '/adapters'
          });
        }
      },
      {
        key: '20',
        label: 'Материалы',
        img_path: ``,
        url_path: '/materials',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'materials',
            label: 'Материалы',
            closable: true,
            path: '/materials'
          });
        }
      },
      {
        key: '21',
        label: 'Оборудование',
        img_path: ``,
        url_path: '/machines',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'machines',
            label: 'Оборудование',
            closable: true,
            path: '/machines'
          });
        }
      },
      {
        key: '22',
        label: 'Группы режущих сплавов',
        img_path: ``,
        url_path: '/cutGradeGroups',
        tag: { title: 'Исходные данные', color: 'geekblue' },
        isFavourite: false,
        onClickHandler: () => {
          handleClick({
            key: 'cutGradeGroups',
            label: 'Группы режущих сплавов',
            closable: true,
            path: '/cutGradeGroups'
          });
        }
      }
    ]);
  }, []);

  useEffect(() => {
    let apps = localStorage.getItem('favourites_apps');
    if (!!!apps) localStorage.setItem('favourites_apps', '');
  }, [favouriteSwitch]);

  const handleFavouriteStatus = (label) => {
    setLastSelectedApp(label);
    setFavouriteSwitch(!favouriteSwitch);
  };
  const handleChangeAppsFilter = () => {};

  return (
    <Layout className={sp.container} style={{ gap: '16px' }}>
      <Layout className={sp.container_block} style={{ gap: '16px' }}>
        <Layout className={sp.container_title}>
          <img className={sp.container_title_img} src={star} />
          <Title className={sp.container_title_text}>Избранные приложения</Title>
        </Layout>
        <Layout className={sp.apps_grid}>
          {apps
            .filter((f) => f.isFavourite === true)
            .map((component, i) => {
              return (
                <PageItem
                  key={component.key}
                  image={component.img_path}
                  label={component.label}
                  tag={component.tag}
                  handleFavouriteStatus={() => {
                    handleFavouriteStatus(component.label);
                  }}
                  isFavourite={component.isFavourite}
                  onClickHandler={component.onClickHandler}
                  url={component.url_path}
                />
              );
            })}
        </Layout>
      </Layout>
      <Layout className={sp.container_block} style={{ gap: '16px' }}>
        <Layout className={sp.container_title}>
          <img className={sp.container_title_img} src={grid} />
          <Layout className={sp.container_title_withExtra}>
            <Title className={sp.container_title_text}>Все приложения</Title>
            <Radio.Group className={sp.container_extra_radio} onChange={handleChangeAppsFilter}>
              <Radio.Button value="planning">Планирование инструмента</Radio.Button>
              <Radio.Button value="catalogs">Каталоги</Radio.Button>
              <Radio.Button value="logists">Логистика</Radio.Button>
              <Radio.Button value="cex">Цех</Radio.Button>
              <Radio.Button value="ishodn">Исходные данные</Radio.Button>
            </Radio.Group>
          </Layout>
        </Layout>
        <Layout className={sp.apps_grid}>
          {apps.map((component, i) => {
            return (
              <PageItem
                key={component.key}
                image={component.img_path}
                label={component.label}
                tag={component.tag}
                handleFavouriteStatus={() => {
                  handleFavouriteStatus(component.label);
                }}
                isFavourite={component.isFavourite}
                onClickHandler={component.onClickHandler}
                url={component.url_path}
              />
            );
          })}
        </Layout>
      </Layout>
    </Layout>
  );
};
