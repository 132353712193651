import { Checkbox, Flex, Typography } from 'antd'
import React, {useEffect, useState} from 'react'
import s from "./style.module.css"
const ChooseInfo = ({checkboxState, setCheckboxState}) => {

    return (
    <div className={s.chooseInfo__container}>
          <Typography.Title level={3} style={{ borderBottom: '1px solid #f0f0f0', paddingBottom: 10 }}>
          Зависимая информация
        </Typography.Title>
        <div className={s.chooseInfo__checkbox_wrapper}><Checkbox  checked={checkboxState.parameters} onChange={(e)=>setCheckboxState({...checkboxState,parameters:e.target.checked})}/> <span className={s.chooseInfo__checkbox_text}>Параметры</span></div>
        <div className={s.chooseInfo__checkbox_wrapper}><Checkbox checked={checkboxState.documents} onChange={(e)=>setCheckboxState({...checkboxState,documents:e.target.checked})}/> <span className={s.chooseInfo__checkbox_text}>Документы</span></div>
        <div className={s.chooseInfo__checkbox_wrapper}><Checkbox checked={checkboxState.information} onChange={(e)=>setCheckboxState({...checkboxState,information:e.target.checked})}/> <span className={s.chooseInfo__checkbox_text}>Информация</span></div>
        <div className={s.chooseInfo__checkbox_wrapper}><Checkbox checked={checkboxState.specification} onChange={(e)=>setCheckboxState({...checkboxState,specification:e.target.checked})}/> <span className={s.chooseInfo__checkbox_text}>Спецификация</span></div>
    </div>
  )
}

export default ChooseInfo