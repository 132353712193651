import React, {useEffect, useState} from 'react'
import {
    guidesApi,
    useGetCompanyClassesWithChildrenQuery,
} from "../../services/guidesApi";
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import {ColorPicker, Flex, Layout, List, message, Table, Tag, Typography} from "antd";
const { Title, Text } = Typography;
import {
    ClearOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    HomeOutlined,
    PlusOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import g from '../../general.module.scss'
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";
import {Link} from "react-router-dom";
import {ModalCreateCompany, ModalEditCompany} from "./CompanyModals";

const Company = () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);
    const [detailSelectedCompany, setDetailSelectedCompany] = useState(null)
    const [refetch, setRefetch] = useState(null)

    // const { data: companies, isFetching, isError } = useGetCompanyClassesWithChildrenQuery(currentPage);
    const [search, {data: companies, isFetching, isError}] = guidesApi.endpoints.getCompanies.useLazyQuery();


    const handleToolClick = (branch) => {
        console.log(branch);
        setSelectedCompanyId(branch.id);
        setSelectedCompany(branch);

        // const parameters = branch.branches.map((item) => ({
        //     key: item.id,
        //     ...item
        // }));
        //
        // setParameters(parameters);
        //
        // setInformation([
        //     { name: 'ID', value: branch.id },
        //     { name: 'Наименование', value: branch.name }
        // ]);
    };

    const [refetchList, setRefetchList] = useState();

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetchList(!refetchList)
    }

    const [openModalEditCompany, setOpenModalEditCompany] = useState(false)
    const handleCloseModalEditCompany = () => {
        setOpenModalEditCompany(false)
        setRefetchList(!refetchList)
    }

    const [openModalCreateCompany, setOpenModalCreateCompany] = useState(false)
    const handleCloseModalCreateCompany = () => {
        setOpenModalCreateCompany(false)
        setRefetchList(!refetchList)
    }

    useEffect(() => {
        if (typeof refetchList === 'boolean') {
            message.info('refetching list... (empty code)')
        }
    }, [refetchList]);

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => { setOpenModalCreateCompany(true) }
        },
        {
            icon: <EditOutlined />,
            disabled: selectedCompany === null,
            onClick: () => { setOpenModalEditCompany(true) }
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedCompany === null,
            onClick: () => { setOpenModalConfirmDelete(true) }
        },
    ]

    useEffect(() => {
        if (selectedCompanyId !== '') {
            fetch(`${process.env.REACT_APP_BASE_URL}/guide/company/${selectedCompanyId}`)
                .then(res => {
                    return res
                })
                .then(res => res.json())
                .then(res => {
                    setDetailSelectedCompany(res)
                })
        }
    }, [selectedCompanyId]);

    useEffect(() => {
        console.log(detailSelectedCompany)
    }, [detailSelectedCompany]);

    const handleDeleteGroup = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/company/${selectedCompanyId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    message.success('Элемент удален!')
                    setOpenModalConfirmDelete(false)
                    message.info('refetching...')
                    return res
                }
            })
    }

    const [branches, setBranches] = useState([])
    const [companyTypes, setCompanyTypes] = useState([])
    const [languages, setLanguages] = useState([])
    const [countries, setCountries] = useState([])

    useEffect(() => {
        if (companies && totalElements === 0) setTotalElements(companies.totalElements);
        setAllData(companies)
    }, [companies])

    useEffect(() => {
        if (openModalCreateCompany || openModalEditCompany) {
            fetch(`${process.env.REACT_APP_BASE_URL}/guide/branch`).then(res => res.json()).then(res => setBranches(res))
            fetch(`${process.env.REACT_APP_BASE_URL}/guide/companyType`).then(res => res.json()).then(res => setCompanyTypes(res))
            fetch(`${process.env.REACT_APP_BASE_URL}/guide/language`).then(res => res.json()).then(res => setLanguages(res))
            fetch(`${process.env.REACT_APP_BASE_URL}/guide/country`).then(res => res.json()).then(res => setCountries(res))
        }
    }, [openModalCreateCompany, openModalEditCompany])

    const [allData, setAllData] = useState([])

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedCompany}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'shortName'}
            field_description={'firmCode'}
        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {detailSelectedCompany && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{detailSelectedCompany?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Сокращенное наименование'}</Text>
                        <Text>{detailSelectedCompany?.shortName}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Код фирмы'}</Text>
                        <Text>{detailSelectedCompany?.firmCode || 'Отсутствует'}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Отрасль'}</Text>
                        <Text>
                            <Link to={`/branches/${detailSelectedCompany?.branch.id}`}>
                                <Tag color={'green'}>{detailSelectedCompany?.branch.name || 'Отсутствует'}</Tag>
                            </Link>
                        </Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Тип компании'}</Text>
                        <Text>
                            <Link to={`/companyTypes/${detailSelectedCompany?.companyType.id}`}>
                                <Tag color={'green'}>{detailSelectedCompany?.companyType.name || 'Отсутствует'}</Tag>
                            </Link>
                        </Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Город'}</Text>
                        <Text>
                            {detailSelectedCompany?.companyList?.city}
                        </Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Страна телефона'}</Text>
                        <Text>
                            {detailSelectedCompany?.companyList?.phoneCountry}
                        </Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Телефон'}</Text>
                        <Text>
                            <Link to={`tel:${detailSelectedCompany?.companyList?.phone}`}>{detailSelectedCompany?.companyList?.phone}</Link>
                        </Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Страна'}</Text>
                        <Text>
                            <Link to={`/countries/${detailSelectedCompany?.companyType.id}`}>
                                <Tag color={'geekblue'}>{detailSelectedCompany?.companyType.name || 'Отсутствует'}</Tag>
                            </Link>
                        </Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Язык'}</Text>
                        <Text>
                            <Link to={`/languages/${detailSelectedCompany?.companyType.language?.id}`}>
                                <Tag color={'cyan'}>{detailSelectedCompany?.companyType.language?.name || 'Отсутствует'}</Tag>
                            </Link>
                        </Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{flexGrow: 2}} className={g.container_div}>
                <Flex className={g.container_div} style={{height: '100%', gap: '8px'}}>
                    <Layout className={g.container_block}>
                        <Title level={5}>Адрес для выставления счета</Title>

                    </Layout>
                    <Layout className={g.container_block}>
                        <Title level={5}>Контактная персона</Title>

                    </Layout>
                </Flex>
                <Layout className={g.container_block} style={{height: '100%'}}>
                    <Title level={5}>Условия</Title>

                </Layout>
            </Flex>
            
            <ModalConfirmDelete 
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/company/${selectedCompanyId}`}

                setAllElements={setAllData}
                selectedElement={selectedCompany}
                setSelectedElement={setSelectedCompany}
                setTotalElements={setTotalElements}
            />

            <ModalCreateCompany
                callback_open={openModalCreateCompany}
                callback_close={handleCloseModalCreateCompany}

                branches={branches}
                companyTypes={companyTypes}
                languages={languages}
                countries={countries}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCompany}
            />

            <ModalEditCompany
                callback_open={openModalEditCompany}
                callback_close={handleCloseModalEditCompany}
                selectedCompany={detailSelectedCompany}

                branches={branches}
                companyTypes={companyTypes}
                languages={languages}
                countries={countries}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCompany}
            />
        </ContainerAside>
    )
}

export default Company