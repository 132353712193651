import { Modal, Select, Button, Form, InputNumber, message } from 'antd';
import { costCenterApi } from '../../services/costCenterApi';
import { useEffect, useState } from 'react';

const { Option } = Select;

export const TransferProduct = ({
  selectedCostCenter,
  transferOpen,
  setTransferOpen,
  dataSource,
  fromId,
  toId,
  storages,
  setEndTransition
}) => {
  const [form] = Form.useForm();

  const [storage, setStorage] = useState('');
  const [storageFrom, setStorageFrom] = useState(null);
  const [remainsFrom, setRemainsFrom] = useState(null);
  const [storagesFrom, setStoragesFrom] = useState(null);
  const [transactionProduct, {}] = costCenterApi.useTransactionProductMutation();

  const handleCreate = async (values) => {
    const { countNew, countUsed, storage, storageFrom } = values;
    try {
      await transactionProduct({
        itemDto: {
          guideId: dataSource.guideId
        },
        toId: toId,
        newStorageId: storage,
        what: remainsFrom.itemBatchId,
        fromId: fromId,
        oldStorageId: storageFrom,
        countNew,
        countUsed
      }).unwrap();
      setRemainsFrom(null);
      form.resetFields();
      message.success('Изделие успешно добавлено');
      setTimeout(() => {
        setEndTransition(true);
      }, 1000);
    } catch (error) {
      console.log(error);
      message.error('Не удалось добавить изделие');
      setTransferOpen(false);
    }
  };

  const handleGetRemainsStorageFrom = (storage) => {
    setStorageFrom(storage);
    fetch(`${process.env.REACT_APP_BASE_URL}/stock/items/storage/${storage}?guide=${dataSource.guideId}`)
      .then((res) => res.json())
      .then((data) => {
        setRemainsFrom(data);
      });
  };

  useEffect(() => {
    if (dataSource) {
      fetch(`${process.env.REACT_APP_BASE_URL}/stock/items?guide=${dataSource?.guideId}&cost-center=${selectedCostCenter?.id}`)
        .then((res) => res.json())
        .then((data) => {
          setStoragesFrom(data);
        });
    }
  }, [dataSource]);

  useEffect(() => {
    // Сброс значений при открытии формы
    if (transferOpen) {
      form.resetFields();
      setRemainsFrom(null);
      setStorageFrom(null);
    }
  }, [transferOpen]);
  // console.log(remainsFrom)
  return (
    <Modal footer={false} title={`Передача инструмента ${dataSource?.guideId}`} open={transferOpen} onCancel={() => setTransferOpen(false)}>
      <Form
        form={form}
        name="basic"
        onFinish={handleCreate}
        initialValues={{
          storageFrom: '',
          countNew: 0,
          countUsed: 0
        }}>
        <Form.Item label="Новых" name="countNew">
          <InputNumber
            disabled={!remainsFrom}
            min={0}
            max={remainsFrom?.countNew}
            onChange={(value) => form.setFieldsValue({ countNew: value })}
            rules={[
              {
                required: true,
                message: 'Обязательное поле!'
              }
            ]}
          />
          {` (Max ${remainsFrom ? remainsFrom[0].countNew : '?'})`}
        </Form.Item>
        <Form.Item label="Использованных" name="countUsed">
          <InputNumber
            disabled={!remainsFrom}
            min={0}
            max={remainsFrom?.countUsed}
            onChange={(value) => form.setFieldsValue({ countUsed: value })}
            rules={[
              {
                required: true,
                message: 'Обязательное поле!'
              }
            ]}
          />
          {` (Max ${remainsFrom ? remainsFrom[0].countUsed : '?'})`}
        </Form.Item>
        <Form.Item
          label="Место хранения (откуда)"
          name="storageFrom"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Select
            onChange={handleGetRemainsStorageFrom}
            options={storagesFrom?.map((i) => ({ label: i?.storageName, value: i?.storageId }))}></Select>
        </Form.Item>
        <Form.Item
          label="Место хранения (куда)"
          name="storage"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Select>
            {storages?.map((i) => (
              <Option key={i?.id} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
