import { Button, Drawer, List, Input, Pagination, Typography, Layout, Flex, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { guidesApi } from '../../services/guidesApi';
import { merge } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';

const { Search } = Input;

export const ListOfClassesAndGroups = ({ open, onClose, setClasses, selectMultiple = false, selectedTools }) => {
  const [totalElements, setTotalElements] = useState(0);
  const [allGroups, setAllGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(selectMultiple ? [] : null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Количество элементов на странице

  const [search, { data: groups, isFetching, isError }] = guidesApi.endpoints.getClasses.useLazyQuery();

  // Обновление выделенной группы при получении новых выбранных инструментов
  useEffect(() => {
    if (selectMultiple) {
      setSelectedGroup(selectedTools || []);
    }
  }, [selectedTools]);

  const handleToolClick = (tool) => {
    if (selectMultiple) {
      const isSelected = selectedGroup.find((t) => t.id === tool.id);
      const newSelectedGroup = isSelected ? selectedGroup.filter((t) => t.id !== tool.id) : [...selectedGroup, tool];

      setSelectedGroup(newSelectedGroup);
    } else {
      setSelectedGroup(tool);
      setClasses(tool);
      onClose();
    }
  };

  const selectMultipleTools = () => {
    setClasses(selectedGroup);
    onClose();
  };

  const loadGroups = async (page, query) => {
    try {
      const result = await search({ page, searchQuery: query }).unwrap();
      if (page === 1) {
        setAllGroups(result.results);
      } else {
        setAllGroups((prevGroups) => merge([], prevGroups, result.results));
      }
      setTotalElements(result.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    setCurrentPage(1);
    setAllGroups([]);
    loadGroups(1, value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    loadGroups(page, searchQuery);
  };

  useEffect(() => {
    loadGroups(currentPage, searchQuery);
  }, [searchQuery, currentPage]);

  return (
    <Drawer
      width={'fit-content'}
      open={open}
      onClose={onClose}
      placement="left"
      title="Список инструментов"
      bodyStyle={{ padding: '16px 20px 20px 10px' }}
      footer={
        selectMultiple && (
          <Flex align="center" gap={'small'} justify="end">
            <Button type="primary" onClick={selectMultipleTools}>
              Выбрать
            </Button>
            <Button onClick={onClose} icon={<CloseOutlined />} danger />
          </Flex>
        )
      }>
      <Flex vertical gap={'middle'} style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <div style={{ background: 'white', position: 'sticky', top: 0, zIndex: 1 }}>
          <Search
            size={'large'}
            onSearch={handleSearch}
            onPressEnter={(e) => handleSearch(e.target.value)}
            placeholder="Поиск по ID"
            enterButton
          />
        </div>
        <Flex vertical gap={'small'} style={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <List
            loading={isFetching}
            dataSource={allGroups}
            renderItem={(tool) => (
              <List.Item
                key={tool.id}
                onClick={() => handleToolClick(tool)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: selectMultiple
                    ? selectedGroup.some((t) => t.id === tool.id)
                      ? '#fcffe6'
                      : 'transparent'
                    : tool.id === selectedGroup?.id
                    ? '#fcffe6'
                    : 'transparent'
                }}>
                <List.Item.Meta
                  avatar={
                    <Image
                      width={70}
                      height={70}
                      src={tool?.drawing?.includes('http') || tool?.drawing?.includes('base64') ? tool?.drawing : ``}
                    />
                  }
                  title={<Typography.Text style={{ color: '#A0D911' }}>{tool.id}</Typography.Text>}
                  description={<Typography.Text>{tool.name}</Typography.Text>}
                />
              </List.Item>
            )}
          />

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalElements}
            onChange={handlePageChange}
            showSizeChanger={false}
            style={{ marginTop: '16px', textAlign: 'center', width: '450px' }}
          />
        </Flex>
      </Flex>
    </Drawer>
  );
};
