import React, { createContext, useState, useContext } from 'react';

// Создание контекста
const ContextViewer = createContext(null);

// Провайдер контекста
export const ProviderViewer = ({ children }) => {

  const [Test, setTest] = useState(false);
  const [two, setTwo] = useState(false);
  const [Panelon, setPanelOn] = useState(false);

  const [ActionRuler, setActionRuler] = useState(false);

  const [PointToPointRuler, setPointToPointRuler] = useState(false);
  const [PointToTwoPointsRuler, setPointToTwoPointsRuler] = useState(false);
  const [SpecifyTheLineRuler, setSpecifyTheLineRuler] = useState(false);
  const [RegionRuler, setRegionRuler] = useState(false);
  const [CornerRuler, setCornerRuler] = useState(false);
  const [AmountRuler, setAmountRuler] = useState(false);

  const [ClickScene, setClickScene] = useState(0);
  const [SummaLine, setSummaLine] = useState(0);

  const [SphereMass, setSphereMass] = useState([]);
  const [LineMass, setLineMass] = useState([]);
  const [SpriteSpheresMass, setSpriteSphereMass] = useState([]);
  const [SpriteLineMass, setSpriteLineMass] = useState([]);
  const [intersections, setIntersections] = useState([]);

  // Объект для передачи текущих значений и функций для их обновления
  const contextValue = {
    Test,
    setTest,
    two,
    setTwo,
    Panelon,
    setPanelOn,

    ActionRuler,
    setActionRuler,

    PointToPointRuler,
    setPointToPointRuler,
    PointToTwoPointsRuler,
    setPointToTwoPointsRuler,
    SpecifyTheLineRuler,
    setSpecifyTheLineRuler,
    RegionRuler,
    setRegionRuler,
    CornerRuler,
    setCornerRuler,
    AmountRuler,
    setAmountRuler,

    ClickScene,
    setClickScene,
    SummaLine,
    setSummaLine,

    SphereMass,
    setSphereMass,
    intersections,
    LineMass,
    setLineMass,
    SpriteSpheresMass,
    setSpriteSphereMass,
    SpriteLineMass,
    setSpriteLineMass
  };

  return <ContextViewer.Provider value={contextValue}>{children}</ContextViewer.Provider>;
};

// // Хук для использования контекста
const useContextViewer = () => useContext(ContextViewer);
export default useContextViewer;
