import React, { useRef, useState, useEffect, useCallback, memo } from 'react';
import { LoadStep } from '../../../StepLoader';
import { Canvas, useThree } from '@react-three/fiber';
import * as THREE from 'three'
// import { Helper } from '@react-three/drei'
const LoaadModelPlus = memo(({ name, url, nameGroup, setGridZ, gridZ, setBoxModelCard = null }) => {
    // console.log(url)
    const [AssemblyX1, setAssemblyX1] = useState(0)
    const [obj, setObj] = useState(null);
    const bboxMinZRef = useRef(null);
    const { gl, camera, scene } = useThree();
    const calculateAssemblyX = useCallback((mainObject) => {
        const bbox = new THREE.Box3().setFromObject(mainObject)
        const bboxSize = new THREE.Vector3(bbox)
        bbox.getSize(bboxSize)
        console.log(bbox)
        if (nameGroup > 0) {


            setAssemblyX1(prev => prev + bbox.max.x) // Используем функциональное обновление


        }
        bboxMinZRef.current = bbox.min.z;
        setGridZ(prevGridZ => {
            if (bbox.min.z < prevGridZ) {
                return bbox.min.z;
            }
            return prevGridZ;
        })
    }, [nameGroup, setGridZ]);
    useEffect(() => {
        let mounted = true;
        if (scene.getObjectByName("BoxModel").children.length) {
            console.log('delet', scene.getObjectByName("BoxModel").children[0])
            scene.getObjectByName("BoxModel").remove((scene.getObjectByName("BoxModel")).children[0]);

        }
        async function load() {
            try {
                const mainObject = await LoadStep(url);
                if (mounted) {
                    setObj(mainObject);
                    calculateAssemblyX(mainObject);
                }
            } catch (error) {
                console.error('Error loading model:', error, url);
            }
        }

    load();

        return () => {
            mounted = false;
        };
    }, [url, calculateAssemblyX]);
    useEffect(() => {
        console.log('AssemblyX1', AssemblyX1)
    }, [AssemblyX1]);
    if (!obj) return null;

    return (
        <group name={name} position={[(-1) * AssemblyX1, 0, 0]}>    <primitive object={obj} />
        </group>
    );
});

export default LoaadModelPlus;
