import React, { useRef, useState, useEffect} from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'
import s from "./Table.module.css"
import { PlusOutlined, EditOutlined, ClearOutlined } from '@ant-design/icons';
import { Button, Flex, Layout } from 'antd';
import { Toolbar } from '../toolbar';
const TableGroup = ({setOpenModalConfirmDelete,setOpenModalEditGroup, setOpenModalCreateGroup ,data, setSelectedGroup, selectedGroup}) => {
    const tableRef = useRef(null);
    const [remainingWidth, setRemainingWidth] = useState('auto');
    const columnHelper = createColumnHelper();
    console.log(data)
    const columns = [
        columnHelper.accessor('id', {
            header: 'ID', cell: info => info.getValue(),
        }),
        columnHelper.accessor('name', {
            header: 'Наименование',
            cell: info => info.getValue(),
        })
       ];

    const table = useReactTable({
        data, columns,
        getCoreRowModel: getCoreRowModel(),
    })
    useEffect(()=>{
     if(tableRef.current){
        const tableWidth = tableRef.current.offsetWidth;
        const cells = tableRef.current.querySelectorAll('thead th:not(:last-child)')
        let occupiedWidth = 0;
        cells.forEach(element => {
            occupiedWidth += element.offsetWidth
        }); 
        const lastCellWidth = tableWidth - (occupiedWidth / 2);
        setRemainingWidth(`${lastCellWidth}px`)
    }
    },[data])
    const toolbarItems = [
        {
          icon: <PlusOutlined />,
          disabled:false,
          onClick: () => {setOpenModalCreateGroup(true)}
        },
        {
          icon: <EditOutlined />,
          disabled:selectedGroup === null,
          onClick: () => {setOpenModalEditGroup(true)}
        },
        {
            icon: <ClearOutlined />,
            disabled:selectedGroup === null,
            onClick: () => {setOpenModalConfirmDelete(true)}
          },
      ];
    return (
        <div className={s.table_container}>
             <Layout className={s.container_rightSide}>
             {toolbarItems.map((item) => (
        <Button key={item.key} size='large' type="text" disabled={item?.disabled} icon={item.icon} onClick={item.onClick}></Button>
      ))}
            </Layout>
            {data !== undefined && data !== null &&
        <table ref={tableRef}>
            <thead className={s.cell_container}>{table?.getHeaderGroups().map(headerGroup => (
                <tr className={s.cell_wrapper} key={headerGroup.id}>{headerGroup.headers.map(header => (
                    <th key={header.id}>{header.isPlaceholder
                        ? null : flexRender(
                            header.column.columnDef.header, header.getContext()
                        )}  </th>
                ))}</tr>
            ))}        </thead>
            <tbody>          {table?.getRowModel()?.rows.map(row => (
                <tr key={row.id}>              {row.getVisibleCells().map(cell => (
                    <td onClick={()=>{
                        if(selectedGroup?.id === cell.row.original.id){
                            setSelectedGroup(null)
                        }else{
                        setSelectedGroup(cell.row.original)}}} key={cell.id} style={{width:cell.column.id === "empty" ? remainingWidth : "", backgroundColor:selectedGroup?.id === cell.row.original.id ? "orange" :""}}>{flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>))}
                </tr>))}
            </tbody>        </table>}
            </div>
    )
}

export default TableGroup