import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    ColorPicker,
    Form,
    Input,
    InputNumber,
    Layout,
    message,
    Modal, Select,
    Space,
    Switch,
    Upload
} from "antd";
import g from "../../general.module.scss";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import tool from '../../content/images/tool.svg'
import assembly from '../../content/images/assembly.svg'
import TextArea from "antd/es/input/TextArea";
import {count} from "react-table/src/aggregations";
import c from './Company.module.scss'

export const ModalEditCompany = ({
                                     callback_open,
                                     callback_close,
                                     selectedCompany,
                                     allElements,
                                     setAllElements,
                                     setSelectedElement,
                                     setTotalElements,
                                     branches,
                                     companyTypes,
                                     languages,
                                     countries,
}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        console.log('BEFORE -> ', body)
        const result = {}
        result['id'] = `${body.id}`
        result['shortName'] = `${body.shortName}`
        result['firmCode'] = `${body.firmCode}`
        result['branch'] = {id: `${body.branch.value}`}
        result['companyType'] = {id: `${body.companyType.value}`}
        result['companyList'] = {
            id: `${body.id}`,
            description: `${body.description}`,
            city: `${body.city}`,
            phoneCountry: `${body.phoneCountry}`,
            phone: `${body.phone}`,
            country: {
                id: `${body.country.value}`,
            },
            language: {
                id: `${body.language.value}`
            }
        }
        console.log('AFTER -> ',result)
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/company`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                    setIsWaitingResponse(false)
                } else {
                    setAllElements({...allElements, results: [...allElements.results.filter(f => f.id !== selectedCompany.id), body]})
                    // setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)
                    callback_close()
                    setIsWaitingResponse(false)
                    return res
                }
            })
    }

    return (
        <Modal
            centered
            title={'Изменение компании'}
            open={callback_open}
            onCancel={callback_close}
            width={1024}
            destroyOnClose
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: '100%',
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                    name={'createBranch'}
                    initialValues={{
                        id: selectedCompany?.id,
                        shortName: selectedCompany?.shortName,
                        firmCode: selectedCompany?.firmCode,
                        city: selectedCompany?.companyList?.city,
                        description: selectedCompany?.companyList?.description,
                        phoneCountry: selectedCompany?.companyList?.phoneCountry,
                        phone: selectedCompany?.companyList?.phone,
                        branch: {title: selectedCompany?.branch?.name, value: selectedCompany?.branch?.id},
                        companyType: {title: selectedCompany?.companyType?.name, value: selectedCompany?.companyType?.id},
                        country: {title: selectedCompany?.companyList?.country?.name, value: selectedCompany?.companyList?.country?.id},
                        language: {title: selectedCompany?.companyList?.language?.name, value: selectedCompany?.companyList?.language?.id}
                    }}
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={`${g.modal_formLayout} ${c.form_container}`}>
                <Space>
                    <Form.Item
                        name="id"
                        label="Идентификатор"
                        rules={[
                            {required: false},
                        ]}
                    >
                        <InputNumber disabled style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        name="shortName"
                        label="Сокращенное наименование"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        name="firmCode"
                        label="Код фирмы"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>
                </Space>
                <Space>
                    <Form.Item
                        name="branch"
                        label="Отрасль компании"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Select
                            options={branches?.results?.map((country, i) => {
                                return (
                                    {
                                        label: country.name,
                                        value: country.id
                                    }
                                )
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="companyType"
                        label="Тип компании"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Select
                            options={companyTypes?.results?.map((country, i) => {
                                return (
                                    {
                                        label: country.name,
                                        value: country.id
                                    }
                                )
                            })}
                        />
                    </Form.Item>
                </Space>
                <Form.Item
                    name="description"
                    label="Описание компании"
                    rules={[
                        {required: true},
                    ]}
                >
                    <TextArea />
                </Form.Item>
                <Space>
                    <Form.Item
                        name="city"
                        label="Город"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phoneCountry"
                        label="Телефонный код страны"
                        rules={[
                            {required: true},
                            {type: 'phone'}
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Телефон"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Space>
                <Space>
                    <Form.Item
                        name="country"
                        label="Страна"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Select
                            options={countries?.results?.map((country, i) => {
                                return (
                                    {
                                        label: country.name,
                                        value: country.id
                                    }
                                )
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="language"
                        label="Язык"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Select
                            options={languages?.results?.map((country, i) => {
                                return (
                                    {
                                        label: country.name,
                                        value: country.id
                                    }
                                )
                            })}
                        />
                    </Form.Item>
                </Space>
            </Layout>
        </Modal>
    )
}

export const ModalCreateCompany = ({
                                       callback_open,
                                       callback_close,
                                       allElements,
                                       setAllElements,
                                       setTotalElements,
                                       setSelectedElement,
                                       branches=[],
                                       companyTypes=[],
                                       languages=[],
                                       countries=[],
}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        console.log('BEFORE -> ', body)
        const result = {}
        result['id'] = `${body.id}`
        result['shortName'] = `${body.shortName}`
        result['firmCode'] = `${body.firmCode}`
        result['branch'] = {id: `${body.branch}`}
        result['companyType'] = {id: `${body.companyType}`}
        result['companyList'] = {
            id: `${body.id}`,
            description: `${body.description}`,
            city: `${body.city}`,
            phoneCountry: `${body.phoneCountry}`,
            phone: `${body.phone}`,
            country: {
                id: `${body.country}`,
            },
            language: {
                id: `${body.language}`
            }
        }
        console.log('AFTER -> ',result)
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/company`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    console.log(allElements)
                    setAllElements({...allElements, results: [...allElements.results, body]})
                    setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)
                    setIsWaitingResponse(false)
                    callback_close()
                    return res
                }
            })
            .catch(e => {
                message.error(`${e}`)
                setIsWaitingResponse(false)
            })
    }

    return (
        <Modal
            centered
            title={'Создание компании'}
            open={callback_open}
            onCancel={callback_close}
            width={1024}
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: '100%',
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={`${g.modal_formLayout} ${c.form_container}`}>
                <Space>
                    <Form.Item
                        name="id"
                        label="Идентификатор"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        name="shortName"
                        label="Сокращенное наименование"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        name="firmCode"
                        label="Код фирмы"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>
                </Space>
                <Space>
                    <Form.Item
                        name="branch"
                        label="Отрасль компании"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Select
                            options={branches?.results?.map((country, i) => {
                                return (
                                    {
                                        label: country.name,
                                        value: country.id
                                    }
                                )
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="companyType"
                        label="Тип компании"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Select
                            options={companyTypes?.results?.map((country, i) => {
                                return (
                                    {
                                        label: country.name,
                                        value: country.id
                                    }
                                )
                            })}
                        />
                    </Form.Item>
                </Space>
                <Form.Item
                    name="description"
                    label="Описание компании"
                    rules={[
                        {required: true},
                    ]}
                >
                    <TextArea />
                </Form.Item>
                <Space>
                    <Form.Item
                        name="city"
                        label="Город"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phoneCountry"
                        label="Телефонный код страны"
                        rules={[
                            {required: true},
                            {type: 'phone'}
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Телефон"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Space>
                <Space>
                    <Form.Item
                        name="country"
                        label="Страна"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Select
                            options={countries?.results?.map((country, i) => {
                                return (
                                    {
                                        label: country.name,
                                        value: country.id
                                    }
                                )
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="language"
                        label="Язык"
                        rules={[
                            {required: true},
                        ]}
                    >
                        <Select
                            options={languages?.results?.map((country, i) => {
                                return (
                                    {
                                        label: country.name,
                                        value: country.id
                                    }
                                )
                            })}
                        />
                    </Form.Item>
                </Space>
            </Layout>
        </Modal>
    )
}