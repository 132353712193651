import { combineReducers, configureStore } from '@reduxjs/toolkit';
import headerSlice from '../features/headerSlice';
import editingCostCenterSlice from '../features/editingCostCenterSlice';
import { costCenterApi } from '../services/costCenterApi';
import { guidesApi } from '../services/guidesApi';
import { stockApi } from '../services/operationPlan';
import { warehousesApi } from '../services/warehouses';
import { usersApi } from '../services/usersApi';
import { orderApi } from '../services/ordersApi';
import { assemblyToolsApi } from '../services/assemblyTools';
import authSlice from '../features/authSlice';
import authApi from '../services/authApi';

const rootReducer = combineReducers({
  header: headerSlice,
  editingCostCenter: editingCostCenterSlice,
  auth: authSlice,
  [costCenterApi.reducerPath]: costCenterApi.reducer,
  [guidesApi.reducerPath]: guidesApi.reducer,
  [stockApi.reducerPath]: stockApi.reducer,
  [warehousesApi.reducerPath]: warehousesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [assemblyToolsApi.reducerPath]: assemblyToolsApi.reducer,
  [authApi.reducerPath]: authApi.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(costCenterApi.middleware)
      .concat(guidesApi.middleware)
      .concat(stockApi.middleware)
      .concat(warehousesApi.middleware)
      .concat(usersApi.middleware)
      .concat(orderApi.middleware)
      .concat(assemblyToolsApi.middleware)
      .concat(authApi.middleware)
});
