import React from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../features/authSlice';
import { useLoginMutation } from '../../services/authApi';
import { Button, Flex, Form, Image, Input, Typography, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import authBackground from '../../content/images/authBackground.png';
import logo from '../../content/images/logo_full.svg';

const AuthForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loginMutation] = useLoginMutation();

  const handleSubmit = async (values) => {
    const { username, password } = values;
    try {
      const data = await loginMutation({ username, password }).unwrap();
      dispatch(
        login({
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
          idToken: data.id_token
        })
      );
    } catch (error) {
      message.error('Неверный логин или пароль')
      console.error('Failed to log in: ', error);
    }
  };

  return (
    <Flex
      vertical
      gap={'large'}
      justify="center"
      align="center"
      style={{ height: '100vh', backgroundImage: `url(${authBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <Image src={logo} preview={false} style={{ width: '250px' }} />
      <Form
        form={form}
        onFinish={handleSubmit}
        name="basic"
        style={{
          maxWidth: '600px',
          width: '100%',
          background: 'rgba(26, 30, 36, 0.69)',
          padding: '30px 60px',
          borderRadius: '8px'
        }}>
        <Typography.Title level={2} style={{ color: 'white', marginBottom: '20px' }}>
          Авторизация в системе
        </Typography.Title>
        <Form.Item
          label={<span style={{ color: 'white' }}>Логин</span>}
          name="username"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: ''
            }
          ]}>
          <Input size="large" placeholder="Имя" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: 'white' }}>Пароль</span>}
          name="password"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: ''
            }
          ]}>
          <Input.Password size="large" placeholder="Пароль" prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button size="large" htmlType="submit" type="primary" style={{ width: '100%', color: 'rgba(0, 0, 0, 0.65)' }}>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default AuthForm;
