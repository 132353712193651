import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['user', 'role', 'userInfo'],
  endpoints: (builder) => ({
    getAllRoles: builder.query({
      query: () => `${process.env.REACT_APP_PARAM_ADMIN}/roles`,
      providesTags: (result) =>
        result ? [...result.roles.map(({ id }) => ({ type: 'role', id })), { type: 'role', id: 'LIST' }] : [{ type: 'role', id: 'LIST' }]
    }),

    createRole: builder.mutation({
      query: (role) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/roles/createRole`,
        method: 'POST',
        body: role
      }),
      invalidatesTags: (result, error) => [{ type: 'role', id: 'LIST' }]
    }),

    editRole: builder.mutation({
      query: ({ roleName, body }) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/roles/editRole/${roleName}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: () => [{ type: 'role', id: 'LIST' }]
    }),

    deleteRole: builder.mutation({
      query: (roleName) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/roles/deleteRole/${roleName}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => [{ type: 'role', id: 'LIST' }]
    }),

    getAllUsers: builder.query({
      query: () => `${process.env.REACT_APP_PARAM_ADMIN}/users/all`,
      providesTags: (result) =>
        result ? [...result.users.map(({ id }) => ({ type: 'user', id })), { type: 'user', id: 'LIST' }] : [{ type: 'user', id: 'LIST' }]
    }),

    getUserInfo: builder.query({
      query: (id) => `${process.env.REACT_APP_PARAM_ADMIN}/users/user/${id}`,
      providesTags: (result, error, id) => [{ type: 'userInfo', id }]
    }),

    createUser: builder.mutation({
      query: (user) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/users/createUser`,
        method: 'POST',
        body: user
      }),
      invalidatesTags: [{ type: 'user', id: 'LIST' }]
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/users/deleteUser/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['user']
    }),

    changeRole: builder.mutation({
      query: ({ action, id, role }) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/users/${action}/${id}/${role}`,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'user', id: 'LIST' }]
    }),

    editUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/users/editUser/${id}/edit`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['user']
    }),

    activationUser: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/users/active/${id}`,
        method: 'PUT'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'userInfo', id }]
    }),

    deactivationUser: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_ADMIN}/users/inactive/${id}`,
        method: 'PUT'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'userInfo', id }]
    })
  })
});

export const {
  useGetAllRolesQuery,
  useGetAllUsersQuery,
  useGetUserInfoQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useChangeRoleMutation,
  useEditRoleMutation,
  useDeleteRoleMutation,
  useEditUserMutation,
  useCreateRoleMutation,
  useActivationUserMutation,
  useDeactivationUserMutation
} = usersApi;
