import { ArrowLeftOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Tabs, message } from 'antd';
import React, { useState, useEffect } from 'react';
import s from './style.module.css';
import { CreateGroupLayout } from './createGroupLayout';
import Parameters from './Parameters';
const { TabPane } = Tabs;

const CreateGroupDrawer = ({
  allClasses,
  setAllClasses,
  setSelectedClass,
  selectedClass,
  selectedGroup,
  setSelectedGroup,
  visible,
  onClose
}) => {
  const [params, setParams] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [classesDrawer, setClassesDrawer] = useState(false);
  const [selectedClassType, setSelectedClassType] = useState(null);
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    tdmClass: {},
    disabled: false,
    used: '',
    drawing: '',
    parameters: []
  });
  const fetchCreateClass = async (state) => {
    console.log(formData);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/guide/group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const json = await response.json();
        setAllClasses(
          allClasses.map((l) => (l.id == selectedClass.id ? { ...selectedClass, groups: [...selectedClass.groups, { ...json }] } : l))
        );
        setSelectedClass({ ...selectedClass, groups: [...selectedClass.groups, { ...json }] });
        if (state === true) {
          onClose();
        }
        message.success('Успешно создано');
      } else {
        message.error('Ошибка создания');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchEditClass = async (state) => {
    console.log(formData);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/guide/group`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const json = await response.json();
        const newGroup = selectedClass.groups.filter((i) => i.id !== json.id);
        console.log(json);
        newGroup.push(json);
        setAllClasses(allClasses.map((l) => (l.id == selectedClass.id ? { ...selectedClass, groups: [...newGroup] } : l)));
        setSelectedClass({ ...selectedClass, groups: [...newGroup] });
        setSelectedGroup(null);
        if (state === true) {
          onClose();
        }
        message.success('Успешно изменено');
      } else {
        message.error('Ошибка редактирования');
      }
    } catch (e) {
      console.log(selectedClass);
      console.log(e);
    }
  };
  useEffect(() => {
    console.log(selectedClass);
    setRenderFlag(false);
    if (selectedGroup !== null && selectedGroup !== undefined) {
      setFormData({ ...selectedGroup, tdmClass: { id: selectedClass?.id } });
    } else {
      setFormData({ ...formData, tdmClass: { id: selectedClass?.id } });
    }
  }, [selectedGroup, visible]);
  useEffect(() => {
    setFormData({ ...formData, parameters: params });
  }, [params]);
  useEffect(() => {
    setRenderFlag(true);
  }, [renderFlag]);

  return (
    <Drawer
      width={'100vw'}
      placement="left"
      title={
        <Flex align="center" gap="60px">
          <Button onClick={onClose} size="large" icon={<ArrowLeftOutlined />} />
          <Tabs size="large" activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab="Общие" key="1" />
            <TabPane tab="Параметры" key="2" />
            <TabPane tab="Соединения инструментов" key="3" />
          </Tabs>
        </Flex>
      }
      open={visible}
      closeIcon={false}
      footer={
        <Flex align="center" justify="end" gap="middle">
          <Button
            onClick={() => {
              selectedGroup !== null && selectedGroup !== undefined ? fetchEditClass(false) : fetchCreateClass(false);
            }}
            icon={<SaveOutlined />}
            type="primary"
            size="large">
            Создать
          </Button>
          <Button
            onClick={() => {
              selectedGroup !== null && selectedGroup !== undefined ? fetchEditClass(true) : fetchCreateClass(true);
            }}
            size="large">
            Сохранить и закрыть
          </Button>
          <Button onClick={onClose} icon={<CloseCircleOutlined />} danger size="large">
            Отменить
          </Button>
        </Flex>
      }>
      <div>
        {activeTab === '1' && renderFlag && (
          <CreateGroupLayout
            selectedClass={selectedClass}
            selectedGroup={selectedGroup}
            classesDrawer={classesDrawer}
            selectedClassType={selectedClassType}
            setSelectedClassType={setSelectedClassType}
            setClassesDrawer={setClassesDrawer}
            formData={formData}
            setFormData={setFormData}
          />
        )}
        {activeTab === '2' && (
          <Parameters dataTable={selectedClass?.parameters} columnData={selectedClass?.parameters} setParams={setParams} />
        )}
      </div>
    </Drawer>
  );
};

export default CreateGroupDrawer;
