import { Flex, Typography, Modal, Button, message, Popconfirm } from 'antd';
import { costCenterApi, useDeleteCostCenterMutation } from '../../services/costCenterApi';
import { DeleteTwoTone } from '@ant-design/icons';
import { useEffect } from 'react';

const { Text, Link } = Typography;

export const CostCenterModal = ({ isModalOpen, setIsModalOpen, setSelected }) => {
  const { data } = costCenterApi.useGetAllCostCentersQuery(undefined);
  const [deleteCostCenter] = useDeleteCostCenterMutation();

  const getCostCenter = async (el) => {
    setSelected(el);
  };

  const handleDeleteCostCenter = async ({ id }) => {
    try {
      await deleteCostCenter(id).unwrap();
      message.success('Место хранения удалено');
    } catch (error) {
      // console.log(error);
      message.error('Место хранения не удалено');
    }
  };
  useEffect(() => {
    // console.log(data);
  }, [data]);

  return (
    <Modal footer={false} title="Список центров затрат" open={isModalOpen} onCancel={() => setIsModalOpen(false)}>
      {data &&
        data.map((el) => (
          <Flex
            gap="small"
            justify="space-between"
            align="center"
            key={el.id}
            style={{
              cursor: 'pointer',
              marginBottom: '10px',
              borderBottom: '1px solid #d9d9d9',
              padding: '3px'
            }}>
            <Flex gap="small">
              <Link onClick={() => getCostCenter(el)}>{el.name}</Link>
              <Text>{el.type}</Text>
            </Flex>
            <Popconfirm
              title="Удаление центра затрат"
              description="Вы уверены, что хотите удалить центр затрат?"
              placement="left"
              onConfirm={() => handleDeleteCostCenter(el)}
              okText="Ок"
              cancelText="Отмена">
              <Button danger icon={<DeleteTwoTone twoToneColor="#ff4d4f" />}></Button>
            </Popconfirm>
          </Flex>
        ))}
    </Modal>
  );
};
