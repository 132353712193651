import { Modal, Input, Button, Form, Select } from 'antd';
import { useCreateUserMutation } from '../../services/usersApi';

export const CreateUser = ({ createUserOpen, setCreateUserOpen }) => {
  const [form] = Form.useForm();

  const [createUser] = useCreateUserMutation();

  const handleCreate = async (values) => {
    try {
      await createUser({ ...values, roleNameList: [values.roleNameList] }).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    } finally {
      setCreateUserOpen(false);
    }
  };

  return (
    <Modal footer={false} title="Создание пользователя" open={createUserOpen} onCancel={() => setCreateUserOpen(false)}>
      <Form form={form} onFinish={handleCreate} name="basic" autoComplete="off">
        <Form.Item
          label="Логин"
          name="username"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            },
            {
              min: 5,
              message: 'Логин должен содержать минимум 5 символов!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Пароль"
          name="password"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            },
            {
              min: 5,
              message: 'Пароль должен содержать минимум 5 символов!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Имя"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Фамилия"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Роль"
          name="roleNameList"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Select>
            <Select.Option value="Программист ЧПУ">Программист ЧПУ</Select.Option>
            <Select.Option value="Администратор склада">Администратор склада</Select.Option>
            <Select.Option value="Кладовщик">Кладовщик</Select.Option>
            <Select.Option value="Инженер по инструменту и оснастке">Инженер по инструменту и оснастке</Select.Option>
            <Select.Option value="Начальник производства">Начальник производства</Select.Option>
            <Select.Option value="Метролог">Метролог</Select.Option>
            <Select.Option value="Главный метролог">Главный метролог</Select.Option>
            <Select.Option value="Оператор ЧПУ">Оператор ЧПУ</Select.Option>
            <Select.Option value="Инженер-технолог">Инженер-технолог</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!',
              type: 'email'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button htmlType="submit" type="primary">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
