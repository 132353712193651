import React, { useMemo, useState } from 'react'
import * as THREE from 'three'

const SpriteSize = ({ point, textSize, a = 0, b = 0, c = 0, color = '#0c60ff', colorback = '#183453a1', colorTexst = 'white', adaptate = 1 }) => {

	if (textSize == 0) {
		textSize = '0'
	} else {
		textSize = String(textSize.toFixed(2))
	}

	const spritePosition = [point[0] + a, point[1] + b, point[2] + c]
	const canvas = useMemo(() => {

		const width = 80 * (10 * adaptate)// Увеличенная ширина канваса
		const height = 40 * (10 * adaptate) // Увеличенная высота канваса
		const borderRadius = 40 // Радиус закругления углов
		const borderWidth = 8 // Ширина границы
		const borderColor = colorTexst// "black"//color // Цвет границы
		const canvas = document.createElement('canvas')
		canvas.width = width
		canvas.height = height
		const context = canvas.getContext('2d')

		// Функция для рисования закругленного прямоугольника
		function drawRoundedRect(ctx, x, y, w, h, r) {
			ctx.beginPath()
			ctx.moveTo(x + r, y)
			ctx.lineTo(x + w - r, y)
			ctx.quadraticCurveTo(x + w, y, x + w, y + r)
			ctx.lineTo(x + w, y + h - r)
			ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h)
			ctx.lineTo(x + r, y + h)
			ctx.quadraticCurveTo(x, y + h, x, y + h - r)
			ctx.lineTo(x, y + r)
			ctx.quadraticCurveTo(x, y, x + r, y)
			ctx.closePath()
		}

		// Рисуем фон с закругленными углами
		context.fillStyle = "rgba(255, 255, 255, 0)"
		//"#ff00000f"//colorback // Цвет фона
		drawRoundedRect(context, borderWidth / 2, borderWidth / 2, width - borderWidth, height - borderWidth, borderRadius)
		context.fill()

		// Рисуем границу с закругленными углами
		context.strokeStyle = borderColor // Цвет границы
		context.lineWidth = borderWidth // Ширина границы
		drawRoundedRect(context, borderWidth / 2, borderWidth / 2, width - borderWidth, height - borderWidth, borderRadius)

		// Рисуем текст
		context.fillStyle = '#A0D911'// colorTexst // Цвет текста
		context.font = String(24 * (10 * adaptate)) + 'px sans-serif' // Увеличенный размер шрифта
		context.textAlign = 'center'
		context.textBaseline = 'middle'
		const lines = textSize.split('\n')
		const lineHeight = 120 // Высота строки
		const initialY = height / 2 - ((lines.length - 1) * lineHeight) / 2 // Начальная позиция Y для центрирования текста
		lines.forEach((line, index) => {
			const y = initialY + index * lineHeight
			context.fillText(line, width / 2, y)
		})

		return canvas
	}, [textSize])

	const texture = useMemo(() => new THREE.CanvasTexture(canvas), [canvas])

	return (
		<sprite renderOrder={3} position={spritePosition} scale={[0.3 * (10 * adaptate), 0.15 * (10 * adaptate), 0.1 * (10 * adaptate)]} name={'HELP'}>
			<spriteMaterial name={textSize} attach='material' map={texture} depthTest={false} depthWrite={false} transparent={true} />
		</sprite>
	)
}

export default SpriteSize
