import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const assemblyToolsApi = createApi({
  reducerPath: 'assemblyToolsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['tools', 'assemble'],
  endpoints: (builder) => ({
    getToolAssemblies: builder.query({
      query: ({ page, searchQuery }) =>
        `${process.env.REACT_APP_PARAM_ASSEMBLE}?page=${page}&size=200&includeChildren=true${
          searchQuery !== '' ? `&filter=id-${searchQuery}` : ''
        }`,
      providesTags: (result) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'assemble', id })), { type: 'assemble', id: 'LIST' }]
          : [{ type: 'assemble', id: 'LIST' }],
      keepUnusedDataFor: 60 * 60
    }),

    getAssembleInfo: builder.query({
      query: (id) => `${process.env.REACT_APP_PARAM_ASSEMBLE}/${id}`,
      providesTags: (result, error, id) => [{ type: 'assemble', id }]
    }),

    getAvailableAssemblyTools: builder.query({
      query: ({ id, connectorType }) => `${process.env.REACT_APP_PARAM_TOOL}/findCompound/${id}/${connectorType}`,
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'tools', id })), { type: 'tools', id: 'LIST' }] : [{ type: 'tools', id: 'LIST' }]
    }),

    createAssemble: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_PARAM_ASSEMBLE}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: 'assemble', id: 'LIST' }]
    }),

    editAssemble: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_PARAM_ASSEMBLE}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: 'assemble', id: 'LIST' }]
    }),

    deleteAssemble: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_ASSEMBLE}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'assemble', id: 'LIST' }]
    }),

    addToolToAssemble: builder.mutation({
      query: ({ assembleId, body }) => ({
        url: `${process.env.REACT_APP_PARAM_ASSEMBLE}/${assembleId}/add-tools`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: 'assemble', id: 'LIST' }]
    }),

    uploadResource: builder.mutation({
      query: ({ id, params, body }) => ({
        url: `${process.env.REACT_APP_PARAM_ASSEMBLE}/${id}/resource?${params.toString()}`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'assemble', id }]
    }),

    deleteResource: builder.mutation({
      query: ({ assembleId, responseId }) => ({
        url: `${process.env.REACT_APP_PARAM_ASSEMBLE}/${assembleId}/resource/${responseId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { assembleId }) => [{ type: 'assemble', id: assembleId }]
    })
  })
});

export const {
  useGetAvailableAssemblyToolsQuery,
  useCreateAssembleMutation,
  useEditAssembleMutation,
  useDeleteAssembleMutation,
  useGetAssembleInfoQuery,
  useAddToolToAssembleMutation,
  useUploadResourceMutation,
  useDeleteResourceMutation
} = assemblyToolsApi;
