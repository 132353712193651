import { Modal, Select, Button, Form, InputNumber,message, Input } from 'antd';
import { costCenterApi } from '../../services/costCenterApi';
import { useEffect, useState } from 'react';

const { Option } = Select;

export const ReserveProduct = ({ 
  transferOpen, 
  setTransferOpen,
  dataSource,
  getCostCenter,
}) => {
  const [countMinimum, setCountMinimum] = useState(0);
  const [countEmergency, setCountEmergency] = useState(0);
  const [reservProduct, {}] = costCenterApi.useChangeReservProductMutation();
    const handleCreate = async () => {
  
      try {
        await reservProduct({
         storageId: dataSource?.storageId,
         guideId: dataSource?.guideId,
         countMinimum,
         countEmergency
        }).unwrap();
        message.success('Изделие успешно добавлено');
        setTimeout(()=>{
            getCostCenter();
        },2000)
      } catch (error) {
        console.log(error);
        message.error('Не удалось добавить изделие');
        setTransferOpen(false)
      }
    };
  

  return (
    <Modal footer={false} title="Изменение остатка" open={transferOpen} onCancel={() => setTransferOpen(false)}>
      <Form
        name="basic"
        initialValues={{
          remember: false
        }}
        autoComplete={true}>
            <Form.Item
          label="Минимальный остаток"
          initialValue={0}
          name="minimum"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <InputNumber value={countMinimum} onChange={(e) => setCountMinimum(e)} />
          </Form.Item>
            <Form.Item
          label="Аварийный остаток"
          name="emergency"
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <InputNumber value={countEmergency} onChange={(e) => setCountEmergency(e)} />
          </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button disabled={countEmergency === 0 && countMinimum 
            === 0} type="primary" onClick={handleCreate}>
            Изменить остаток
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
