import React, {useEffect, useState} from 'react'
import {guidesApi, useGetCountryClassesWithChildrenQuery} from "../../services/guidesApi";
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import {Button, ColorPicker, Flex, Layout, List, message, Table, Typography} from "antd";
const { Title, Text } = Typography;
import {
    ClearOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    HomeOutlined,
    PlusOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import g from "../../general.module.scss";
import {ModalCreateCountry, ModalEditCountry} from "./CountriesModals";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";

const Countries = () => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [refetch, setRefetch] = useState(null);

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetch(!refetch)
    }

    const [openModalEditCountry, setOpenModalEditCountry] = useState(false)
    const handleCloseModalEditCountry = () => {
        setOpenModalEditCountry(false)
        setRefetch(!refetch)
    }

    const [openModalCreateCountry, setOpenModalCreateCountry] = useState(false)
    const handleCloseModalCreateCountry = () => {
        setOpenModalCreateCountry(false)
        setRefetch(!refetch)
    }

    const [search, {data: countries, isFetching, isError}] = guidesApi.endpoints.getCountries.useLazyQuery();


    const handleElementClick = (element) => {
        console.log(element);
        setSelectedCountry(element);
    };

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => {
                setOpenModalCreateCountry(true)
            }
        },
        {
            icon: <EditOutlined />,
            disabled: selectedCountry === null,
            onClick: () => {
                setOpenModalEditCountry(true)
            }
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedCountry === null,
            onClick: () => {
                setOpenModalConfirmDelete(true)
            }
        },
    ]

    const [totalElements, setTotalElements] = useState(0)

    useEffect(() => {
        if (countries && totalElements === 0) setTotalElements(countries.totalElements)
        setAllData(countries)
    }, [countries]);

    const [allData, setAllData] = useState(null)

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleElementClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedCountry}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}
        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedCountry && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedCountry?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedCountry?.name}</Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{flexGrow: 2}}>
                <Layout style={{height: '100%', overflow: 'auto', position: 'relative'}} className={g.container_block}>
                </Layout>
            </Flex>

            <ModalCreateCountry
                callback_open={openModalCreateCountry}
                callback_close={handleCloseModalCreateCountry}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCountry}
            />
            <ModalEditCountry
                callback_open={openModalEditCountry}
                callback_close={handleCloseModalEditCountry}

                selectedCountry={selectedCountry}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedCountry}
            />
            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/country/${selectedCountry?.id}`}

                setAllElements={setAllData}
                selectedElement={selectedCountry}
                setSelectedElement={setSelectedCountry}
                setTotalElements={setTotalElements}
            />
        </ContainerAside>
    )
}

export default Countries