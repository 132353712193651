import { DxfViewer } from 'dxf-viewer/src/DxfViewer';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import s from './style.module.css';
import { Button } from 'antd';

export const DxfComponent = ({ deleteResource, resourceId, base64Path }) => {
  const viewerRef = useRef(null);
  const [base64Data, setBase64Data] = useState('');

  const optionalText = {
    curveSubdivision: 2,
    fallbackChar: '\uFFFD?'
  };

  const optionalDxfScene = {
    arcTessellationAngle: (10 / 180) * Math.PI,
    minArcTessellationSubdivisions: 8,
    wireframeMesh: false,
    suppressPaperSpace: false,
    textOptions: optionalText
  };

  const optionalDxf = {
    // canvasWidth: window.screen.availWidth,
    // canvasHeight: window.screen.availHeight,
    autoResize: false,
    clearColor: new THREE.Color('#000'),
    clearAlpha: 1.0,
    canvasAlpha: false,
    canvasPremultipliedAlpha: true,
    antialias: true,
    colorCorrection: false,
    blackWhiteInversion: true,
    pointSize: 2,
    sceneOptions: optionalDxfScene,
    retainParsedDxf: false,
    preserveDrawingBuffer: false,
    fileEncoding: 'utf-8'
  };

  useEffect(() => {
    const fetchBase64Data = async (path) => {
      try {
        const response = await fetch(path);
        if (!response.ok) {
          throw new Error(`Ошибка при загрузке файла: ${response.statusText}`);
        }
        const text = await response.text();
        setBase64Data(text);
      } catch (error) {
        console.error('Ошибка при получении файла:', error);
      }
    };

    if (base64Path) {
      fetchBase64Data(base64Path);
    }
  }, [base64Path]);

  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    if (viewerRef.current && base64Data) {
      try {
        // Декодируем base64 строку в бинарные данные
        const binaryString = window.atob(base64Data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        // Создаем Blob из бинарных данных
        const blob = new Blob([bytes], { type: 'application/dxf' });
        setBlobUrl(blob);

        // Создаем URL для Blob
        const url = URL.createObjectURL(blob);
        const viewerDxf = new DxfViewer(viewerRef.current, optionalDxf);
        viewerDxf.Load({
          url,
          fonts: [
            '/fonts/HanaMinA.ttf',
            '/fonts/NanumGothic-Regular.ttf',
            '/fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf',
            '/fonts/Roboto-LightItalic.ttf'
          ]
        });

        // Очистка URL при размонтировании компонента
        return () => URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Ошибка при декодировании base64 строки:', error);
      }
    }
  }, [base64Data]);

  function downloadBlob(name = `${resourceId}.dxf`) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) return window.navigator.msSaveOrOpenBlob(blobUrl);

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blobUrl);

    const link = document.createElement('a');
    link.href = data;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  return (
    <>
    <div ref={viewerRef} className={s.container}>
    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        color: "#000",
                                        fontSize: 24
                                    }} >
                                     <span style={{
                                        color: "#FFF",
                                        fontSize: 24
                                    }}onClick={() => {
                                        downloadBlob()
                                    }}>Скачать </span>
                                      {deleteResource !== undefined && deleteResource !== null && <span style={{
                                        color: "#FFF",
                                        fontSize: 24
                                    }} onClick={() => {
                                        deleteResource()
                                    }}>Удалить</span>}
                                    </div>
    </div>
    </>
  );
};
