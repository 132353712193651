import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, InputNumber, Layout, message, Modal, Select, Switch, Upload} from "antd";
import g from "../../general.module.scss";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import tool from '../../content/images/tool.svg'
import assembly from '../../content/images/assembly.svg'

export const ModalEditCutGrade = ({
                                      callback_open,
                                      callback_close,
                                      selectedCutGrade,
                                      allElements,
                                      setAllElements,
                                      setSelectedElement,
                                      setTotalElements,

    companies,
    detailCompany
}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        const result = {...body, supplier: {id: body.supplier.value}}
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/cutGrade`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    setAllElements({...allElements, results: [...allElements.results.filter(f => f.id !== selectedCutGrade.id), body]})
                    // setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    callback_close()
                    setIsWaitingResponse(false)
                    return res
                }
            })
    }

    return (
        <Modal
            centered
            title={'Изменение режущей кромки'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            destroyOnClose
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                    name={'createBranch'}
                    initialValues={{
                        id: selectedCutGrade?.id,
                        name: selectedCutGrade?.name,
                        supplier: {label: detailCompany?.shortName,value: detailCompany?.id},
                        kind: selectedCutGrade?.kind,
                        coat: selectedCutGrade?.coat
                    }}
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="supplier"
                    label="Поставщик"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Select
                        options={companies?.results?.map((el,i) => {
                            return (
                                {
                                    label: el?.shortName,
                                    value: el?.id
                                }
                            )
                        })}
                    />
                </Form.Item>
                <Form.Item
                    name="kind"
                    label="Вид"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="coat"
                    label="Покрытие"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Layout>
        </Modal>
    )
}

export const ModalCreateCutGrade = ({callback_open, callback_close, companies, allElements, setAllElements, setTotalElements, setSelectedElement}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = (body) => {
        const result = {...body, supplier: {id: body.supplier}}
        setIsWaitingResponse(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/cutGrade`, {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    console.log(allElements)
                    setAllElements({...allElements, results: [...allElements.results, body]})
                    setTotalElements(prevValue => prevValue + 1)
                    setSelectedElement(body)

                    callback_close()
                    return res
                }
                setIsWaitingResponse(false)
            })
            .catch(e => {
                message.error(`${e}`)
                setIsWaitingResponse(false)
            })
    }

    return (
        <Modal
            centered
            title={'Создание режущей кромки'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="id"
                    label="Идентификатор"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Название"
                    rules={[
                        {required: true},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="supplier"
                    label="Поставщик"
                    rules={[
                        {required: true},
                    ]}
                >
                        <Select
                            options={companies?.results?.map((el,i) => {
                                return (
                                    {
                                        label: el?.shortName,
                                        value: el?.id
                                    }
                                )
                            })}
                        />
                </Form.Item>
                <Form.Item
                    name="kind"
                    label="Вид"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="coat"
                    label="Покрытие"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Layout>
        </Modal>
    )
}