import { PlusOutlined } from '@ant-design/icons';
import { Layout, Button, Flex } from 'antd';
import { CostCenterContainer } from '../../components/costCenterContainer';
import { CostCenterCreate } from '../../components/costCenterCreate';
import { useState } from 'react';
import g from "../../general.module.scss";

const { Content } = Layout;

export const StantardIssue = () => {
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [endTransition, setEndTransition] = useState(false);
  return (
    <Layout style={{ display: 'flex', flexDirection: 'column', padding: '8px', gap: '8px' }} className={g.container}>
      {/* <CostCenterCreate isCreateOpen={isCreateOpen} setIsCreateOpen={setIsCreateOpen}  /> */}
      {/* <Flex style={{ display: 'flex', alignItems: 'center', gap: '40px', padding: '10px 20px', background: 'white' }} className={g.container_div}>
        <Button onClick={() => setIsCreateOpen(true)} type="primary" shape="circle" icon={<PlusOutlined />} />
      </Flex> */}
      <Content style={{ display: 'flex', gap: '10px', flexGrow: 1 }} className={g.container_div} >
        <CostCenterContainer
          endTransition={endTransition}
          setEndTransition={setEndTransition}
          status={'from'}
          from={from}
          setFrom={setFrom}
          setTo={setTo}
          to={to}
          showTransferButton={true}
        />
        <CostCenterContainer
          endTransition={endTransition}
          setEndTransition={setEndTransition}
          status={'to'}
          to={to}
          setTo={setTo}
          from={from}
          setFrom={setFrom}
          showTransferButton={false}
        />
      </Content>
    </Layout>
  );
};
