import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const warehousesApi = createApi({
  reducerPath: 'warehousesApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['warehouse'],
  endpoints: (builder) => ({
    getWarehouses: builder.query({
      query: (id) => `${process.env.REACT_APP_PARAM_ITEMS}/guide?guide=${id}`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'warehouse', id })), { type: 'warehouse', id: 'LIST' }]
          : [{ type: 'warehouse', id: 'LIST' }]
    })
  })
});

export const { useGetWarehousesQuery } = warehousesApi;
