import React, { useEffect, useState, useRef } from 'react';
import { Canvas,useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import StepModel from './StepModel';
import useContextViewer from './ContextViewer/ContextViewer';
import Ruler from './Ruler/Ruler';
import Sphere from './Ruler/Sphere';
import SpriteSpheres from './Ruler/SpriteSpheres';
import Line from './Ruler/Line';
import SpriteLine from './Ruler/SpriteLine';
import { Button } from 'antd';
import { ColumnWidthOutlined } from '@ant-design/icons';
import SettingsScene from './SettingsScene';
import StaticSpriteSize from './Size/StaticSpriteSize';
import StaticSize from './Size/StaticSize';
import LoaadModelPlus from './Assembly/LoaadModelPlus';
import PointingModel from "./Pointing/PointingModel"
import BoxStpModel from "./BoxStpModel/BoxStpModel"

const styles = {
  position: 'absolute',
  top: '50px',
  zIndex: '3',
  left: '10px',
  display: 'flex',
  justifContent: 'spaceBetween',
  marginBottom: '10%'
};

const CustomAxesHelper = (props) => {
  return <axesHelper renderOrder={2} args={[1000, 50]} {...props} />;
};
export const SceneAssembly = ({ setBlob3D, urlAssembly,setUrlAssembly, gridZ, setGridZ,BoxModelCard=null,setBoxModelCard=null,        stpName,
  setStpName }) => {

  setUrlAssembly(urlAssembly?.reverse())
  // setStpName(stpName?.reverse())

  // setGridZ(0)
  // console.log(Assembly)
  // const {
  //   setPointToPointRuler,
  //   setPointToTwoPointsRuler,
  //   setSpecifyTheLineRuler,
  //   setCornerRuler,
  //   setAmountRuler,
  //   setRegionRuler,
  //   setActionRuler,

  //   intersections,
  //   SphereMass,
  //   setSphereMass,
  //   LineMass,
  //   setLineMass,
  //   SpriteSpheresMass,
  //   setSpriteSphereMass,
  //   SpriteLineMass,
  //   setSpriteLineMass,
  //   ClickScene,
  //   setClickScene,
  //   SummaLine,
  //   setSummaLine,
  //   PointToPointRuler,
  //   PointToTwoPointsRuler,
  //   SpecifyTheLineRuler,
  //   RegionRuler,
  //   CornerRuler,
  //   AmountRuler,
  //   ActionRuler
  // } = useContextViewer();
  // useEffect(() => {
  //   function addModelAssembly(url, key) {
  //     console.log('addModelAssembly', key)
  //     // setAssembly([
  //     //   ...Assembly,
  //     //   <LoaadModelPlus url={url} nameGroup={key} key={url} />
  //     // ]);

  //     setAssembly(prevAssembly => [
  //       ...prevAssembly,
  //       <LoaadModelPlus url={url} nameGroup={key} AssemblyX={AssemblyX} setAssemblyX={setAssemblyX} key={url} />
  //     ]);
  //     console.log(url)
  //   }
  //   for (var i = 0; i < AssemblyURL.AssemblyURL.length; i++) {
  //     console.log('load' + i)
  //     addModelAssembly(AssemblyURL.AssemblyURL[i], i)

  //   }

  // }, [])
  const [AssemblyScene, setAssemblyScene] = useState([]);
  const initialRenderRef = useRef(true);
  const [hoveredModel, setHoveredModel] = useState(null);

  
  const [BoxModel, setBoxModel] = useState();
  useEffect(() => {
    console.log('BoxModelCard',BoxModelCard)
    if (BoxModelCard!==null)
    {
      console.log("Name",BoxModelCard)
      setBoxModel(<BoxStpModel MeshName={BoxModelCard}/>)
    }
    else{
      setBoxModel(undefined)
    }
     
  }, [BoxModelCard]);

  // Эффект для установки начального значения gridZ
  useEffect(() => {
    if (initialRenderRef.current) {
      setGridZ(0);
      initialRenderRef.current = false;
    }
  }, []); // Пустой массив зависимостей означает, что эффект выполнится только при монтировании
  // const [gridZ, setGridZ] = useState(0)
  useEffect(() => {
    if (urlAssembly?.length > 0) {
      const newModels = urlAssembly.map((url, index) => (
        <LoaadModelPlus key={`model-${url}-${index}`} url={url} nameGroup={index} name={stpName[index]}gridZ={gridZ} setGridZ={setGridZ} />
      ));
      setAssemblyScene(newModels);
    } else {
      setAssemblyScene([]);
    }
  }, [urlAssembly]);
  useEffect(() => {
    console.log(gridZ);
  }, [gridZ]);



  return (
    <>
      <Canvas>
        <SettingsScene />
        <StaticSize />
        <OrbitControls />
        <group name={'Assembly'}>{AssemblyScene}</group>
        <gridHelper rotation={[Math.PI / 2, 0, 0]} position={[0, 0, gridZ - 1]} renderOrder={1} args={[500, 50]} />
  
        <CustomAxesHelper />
        <PointingModel/>
        <group name={'BoxModel'}>{BoxModel}</group>
        {/* <BoxStpModel/> */}
        {/* <Ruler
          setSphereMass={setSphereMass}
          SphereMass={SphereMass}
          LineMass={LineMass}
          setLineMass={setLineMass}
          SpriteSpheresMass={SpriteSpheresMass}
          setSpriteSphereMass={setSpriteSphereMass}
          SpriteLineMass={SpriteLineMass}
          setSpriteLineMass={setSpriteLineMass}
          ClickScene={ClickScene}
          setClickScene={setClickScene}
          SummaLine={SummaLine}
          setSummaLine={setSummaLine}
          PointToPointRuler={PointToPointRuler}
          PointToTwoPointsRuler={PointToTwoPointsRuler}
          SpecifyTheLineRuler={SpecifyTheLineRuler}
          RegionRuler={RegionRuler}
          CornerRuler={CornerRuler}
          AmountRuler={AmountRuler}
          ActionRuler={ActionRuler}
        />

        {intersections.map((point, i, uuid) => (
          <Sphere key={`sphere-${i}`} position={point} uuidData={uuid} />
        ))}
        {intersections.map((start, end, index) => (
          <Line key={`line-${index}`} start={start} end={end} color="red" />
        ))}
        {intersections.map((point, index) => (
          <SpriteSpheres point={point} key={`sprite-${index}`} />
        ))}
        {intersections.map((point, index) => (
          <SpriteLine
            AmountRuler={AmountRuler}
            CornerRuler={CornerRuler}
            SpecifyTheLineRuler={SpecifyTheLineRuler}
            SummaLine={SummaLine}
            SpriteLineMass={SpriteLineMass}
            ClickScene={ClickScene}
            point={point}
            key={`sprite-${index}`}
          />
        ))} */}
      </Canvas>
      {/* <Button
        style={{ position: 'absolute', bottom: '10px', right: '10px' }}
        onClick={() => {
          setActionRuler(!ActionRuler);
          setPointToPointRuler(false);
          setPointToTwoPointsRuler(false);
          setSpecifyTheLineRuler(false);
          setRegionRuler(false);
          setCornerRuler(false);
          setAmountRuler(false);
        }}
        className="Button"
        icon={<ColumnWidthOutlined />}></Button>
      {ActionRuler && (
        <div className="RulerButton">
          <div style={styles}>
            <Button
              onClick={() => {
                setPointToPointRuler(!PointToPointRuler);
                setPointToTwoPointsRuler(false);
                setSpecifyTheLineRuler(false);
                setRegionRuler(false);
                setCornerRuler(false);
                setAmountRuler(false);
              }}
              icon={<ColumnWidthOutlined />}></Button>
            <Button
              onClick={() => {
                setPointToPointRuler(false);
                setPointToTwoPointsRuler(!PointToTwoPointsRuler);
                setSpecifyTheLineRuler(false);
                setRegionRuler(false);
                setCornerRuler(false);
                setAmountRuler(false);
              }}
              icon={<ColumnWidthOutlined />}></Button>
            <Button
              onClick={() => {
                setPointToPointRuler(false);
                setPointToTwoPointsRuler(false);
                setSpecifyTheLineRuler(!SpecifyTheLineRuler);
                setRegionRuler(false);
                setCornerRuler(false);
                setAmountRuler(false);
              }}
              icon={<ColumnWidthOutlined />}></Button>
            <Button
              onClick={() => {
                setPointToPointRuler(false);
                setPointToTwoPointsRuler(false);
                setSpecifyTheLineRuler(false);
                setRegionRuler(false);
                setCornerRuler(!CornerRuler);
                setAmountRuler(false);
              }}
              icon={<ColumnWidthOutlined />}></Button>
            <Button
              onClick={() => {
                setPointToPointRuler(false);
                setPointToTwoPointsRuler(false);
                setSpecifyTheLineRuler(false);
                setRegionRuler(false);
                setCornerRuler(false);
                setAmountRuler(!AmountRuler);
              }}
              icon={<ColumnWidthOutlined />}></Button>
          </div>
        </div>
      )} */}
    </>
  );
};
