import StaticSpriteSize from "./StaticSpriteSize"
const StaticSize = () => {



    const color = '#fcf7ff'

    return (
        <StaticSpriteSize point={[0, 0, 0]} textSize={0} a={-0.5} c={-0.5} color={color} colorback={color} colorTexst='black' />
    )


}

export default StaticSize