import { Scene } from '../../components/scene';
import {Layout, Flex, Image, Typography, Input, Table, List, Skeleton, Empty, Tag, message} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {guidesApi, useGetAssembleQuery, useGetClassTypeClassesWithChildrenQuery} from '../../services/guidesApi';
import { useEffect, useState } from 'react';
import { Toolbar } from '../../components/toolbar';
import { ClearOutlined, CopyOutlined, DeleteOutlined, EditOutlined, HomeOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { DxfComponent } from '../../components/dxfViewer';
import ContainerAside from "../../components/ContainerAside/ContainerAside";
import g from "../../general.module.scss";
import {ModalCreateClassType, ModalEditClassType} from "./ClassTypeModals";
import ModalConfirmDelete from "../../components/ModalConfirmDelete/ModalConfirmDelete";

const { Title, Text } = Typography;
const { Search } = Input;

const columns = [
    {
        title: 'Позиция',
        dataIndex: 'pos'
    },
    {
        title: 'Элемент',
        dataIndex: ['tool', 'id'],
        render: (id, record) => (
            <Flex gap="small" vertical>
                <Text>{record.tool.id}</Text>
                <Text>{record.tool.name}</Text>
                <Text>{record.tool.description}</Text>
            </Flex>
        )
    },
    {
        title: 'Количество',
        dataIndex: 'quantity'
    }
];

export const ClassType = () => {
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allMachines, setAllMachines] = useState([]);
    const [selectedClassTypeId, setSelectedClassTypeId] = useState(null);
    const [selectedClassType, setSelectedClassType] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [information, setInformation] = useState([]);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);
    const [refetch, setRefetch] = useState(null);

    const [search, {data: classType, isFetching, isError}] = guidesApi.endpoints.getClassTypes.useLazyQuery();

    useEffect(() => {
        console.log('CLSSTYPEE-> ',classType)
    }, [classType]);

    useEffect(() => {
        setTotalElements(classType?.totalElements || 0)
        setAllData(classType)
    }, [classType]);


    const handleToolClick = (branch) => {
        setSelectedClassTypeId(branch.id);
        setSelectedClassType(branch)
    };

    const toolbarItems = [
        {
            icon: <PlusOutlined />,
            onClick: () => {setOpenModalCreateClassType(true)}
        },
        {
            icon: <EditOutlined />,
            disabled: selectedClassType === null,
            onClick: () => {setOpenModalEditClassType(true)}
        },
        {
            icon: <DeleteOutlined />,
            disabled: selectedClassType === null,
            onClick: () => { setOpenModalConfirmDelete(true) }
        },
    ]

    const [refetchList, setRefetchList] = useState();

    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false)
    const handleCloseModalConfirmDelete = () => {
        setOpenModalConfirmDelete(false)
        setRefetchList(!refetchList)
    }

    const [openModalEditClassType, setOpenModalEditClassType] = useState(false)
    const handleCloseModalEditClassType = () => {
        setOpenModalEditClassType(false)
        setRefetchList(!refetchList)
    }

    const [openModalCreateClassType, setOpenModalCreateClassType] = useState(false)
    const handleCloseModalCreateClassType = () => {
        setOpenModalCreateClassType(false)
        setRefetchList(!refetchList)
    }

    const handleDeleteGroup = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/guide/classType/${selectedClassTypeId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    message.success('Элемент удален!')
                    setOpenModalConfirmDelete(false)
                    message.info('refetching...')
                    return res
                }
            })
    }
    const [allData, setAllData] = useState(null)

    return (
        <ContainerAside
            dataSource={allData}
            search={search}
            handleElementClick={handleToolClick}
            toolbarItems={toolbarItems}
            isError={isError}
            isFetching={isFetching}
            selectedItem={selectedClassType}

            totalElements={totalElements}
            setTotalElements={setTotalElements}

            field_title={'id'}
            field_description={'name'}

        >
            <List
                className={g.container_block}
                style={{ width: '350px', background: 'white' }}
                header={<Title level={5}>Основная информация</Title>}
                bordered
            >
                {selectedClassType && <>
                    <List.Item>
                        <Text type="secondary">{'Идентификатор'}</Text>
                        <Text>{selectedClassType?.name}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Название'}</Text>
                        <Text>{selectedClassType?.id}</Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Используется для'}</Text>
                        <Text style={{display: 'flex', flexWrap: 'wrap'}}>
                            {selectedClassType?.toolOrAssem === 1 || selectedClassType?.toolOrAssem === 3  ? <Tag color={'blue'}>Инструмент</Tag> : ""}
                            {selectedClassType?.toolOrAssem === 2 || selectedClassType?.toolOrAssem === 3  ? <Tag color={'pink'}>Сборка</Tag> : ""}
                            {!!!selectedClassType?.toolOrAssem || selectedClassType?.toolOrAssem === 0 ? <Tag>Не используется</Tag> : ""}
                        </Text>
                    </List.Item>
                    <List.Item>
                        <Text type="secondary">{'Выключен'}</Text>
                        <Text>{selectedClassType?.disabled === undefined ? "Не указано" : selectedClassType?.disabled === true ? 'Да' : 'Нет'}</Text>
                    </List.Item>
                </>
                }
            </List>
            <Flex vertical gap="small" style={{ flexGrow: 2 }} className={g.container_div}>
                <Layout style={{  background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={`${g.container_block} ${g.container_block_image}`}>
                    {/* {file ? <Scene url={file} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />} */}
                    <Image className={g.container_block_image_div} src={selectedClassType?.drawing?.includes('base64') ? selectedClassType?.drawing : `data:image/jpeg;base64,${selectedClassType?.drawing}`} />
                    <Image className={g.container_block_image_div} src={selectedClassType?.drawingAssemble?.includes('base64') ? selectedClassType?.drawingAssemble : `data:image/jpeg;base64,${selectedClassType?.drawingAssemble}`} />
                </Layout>
            </Flex>

            <ModalCreateClassType
                callback_open={openModalCreateClassType}
                callback_close={handleCloseModalCreateClassType}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedClassType}


            />
            <ModalEditClassType
                callback_open={openModalEditClassType}
                callback_close={handleCloseModalEditClassType}

                selectedClassType={selectedClassType}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedClassType}
            />

            <ModalConfirmDelete
                callback_open={openModalConfirmDelete}
                callback_close={handleCloseModalConfirmDelete}
                callback_submit={handleDeleteGroup}

                url_delete={`${process.env.REACT_APP_BASE_URL}/guide/classType/${selectedClassType?.id}`}

                allElements={allData}
                setAllElements={setAllData}
                setTotalElements={setTotalElements}
                setSelectedElement={setSelectedClassType}
            />
        </ContainerAside>
    );
};
