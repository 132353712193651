import {useForm} from "antd/lib/form/Form";
import React, {useEffect, useState} from "react";
import {Button, Form, Input, Layout, message, Modal} from "antd";
import g from "../../general.module.scss";

export const ModalInstrumentsUploadFile = ({callback_open, callback_close, callback_submit}) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64Image(`${reader.result}`)
        };
    };
    const [base64Image, setBase64Image] = useState('')

    useEffect(() => {
        try {
            form.setFieldsValue({
                drawing: `${base64Image}`,
            });
        } catch (e) {
            console.log(e)
        }
    }, [base64Image])

    const onChange = e => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };

    return (
        <Modal
            centered
            title={'Прикрепление файла'}
            open={callback_open}
            onCancel={callback_close}
            width={384}
            okButtonProps={{
                htmlType: 'submit',
            }}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={g.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                    name={'createBranch'}
                >
                    {dom}
                </Form>
            )}
        >
            <Layout className={g.modal_formLayout}>
                <Form.Item
                    name="drawing"
                    label="Изображение"
                    rules={[
                        {required: false},
                    ]}
                >
                    <Layout className={g.modal_inputfile_layout}>
                        <input
                            type="file"
                            id={'photoUploader'}
                            accept=".png, .dxg, .step"
                            onChange={onChange}
                            className={g.modal_inputfile_input}
                        />
                        <img src={base64Image} className={g.modal_inputfile_resultImage}/>
                        <Button disabled={base64Image === ''} onClick={() => {
                            document.getElementById("photoUploader").value = "";
                            setBase64Image('')
                        }} size={'small'} className={g.modal_inputfile_clearButton}>Очистить</Button>
                    </Layout>
                </Form.Item>
            </Layout>
        </Modal>
    )
}