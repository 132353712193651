import { Modal, Input, Button, Form, message } from 'antd';
import { useEffect } from 'react';
import { useEditCostCenterMutation } from '../../services/costCenterApi';

export const RenameCostCenter = ({ renameCostCenterOpen, setRenameCostCenterOpen, selectedCenter }) => {
  const [form] = Form.useForm();

  const [editCostCenter] = useEditCostCenterMutation();

  const handleEdit = async (values) => {
    try {
      await editCostCenter({
        id: selectedCenter.id,
        body: values
      });
      message.success('Место хранения успешно изменено');
    } catch (error) {
      console.log(error);
      message.error('Не удалось изменить место хранения');
    } finally {
      setRenameCostCenterOpen(false);
    }
  };

  useEffect(() => {
    if (selectedCenter) {
      form.setFieldsValue(selectedCenter);
    }
  }, [selectedCenter]);

  return (
    <Modal footer={false} title="Изменение места хранения" open={renameCostCenterOpen} onCancel={() => setRenameCostCenterOpen(false)}>
      <Form form={form} onFinish={handleEdit} name="basic" autoComplete="off">
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Описание"
          name="description"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Переименовать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
