import { Button, Card, Drawer, Flex, Form, Input, InputNumber, Select, Typography, notification, Tabs, Table, Image } from 'antd';
import React, { useState } from 'react';
import { useCreateAssembleMutation } from '../../../services/assemblyTools';
import { ListOfClassesAndGroups } from '../../ListOfClassesAndGroups/ListOfClassesAndGroups';
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { ListOfGroups } from '../ListOfGroups/ListOfGroups';

export const CreateToolAssembly = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [classesAndGroupsVisible, setClassesAndGroupsVisible] = useState(false);
  const [listOfGroupsVisible, setListOfGroupsVisible] = useState(false);
  const [classes, setClasses] = useState(null);
  const [group, setGroup] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [parameterValues, setParameterValues] = useState([]);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [weight, setWeight] = useState(null);
  const [cutCount, setCutCount] = useState(null);

  const [createAssemble] = useCreateAssembleMutation();

  const handleCreate = async (close) => {
    try {
      const values = await form.validateFields();
      const newAssemble = {
        id,
        name,
        description,
        weight,
        cutCount,
        group: { id: group.id },
        parameters: parameterValues
      };
      await createAssemble(newAssemble).unwrap();
      form.resetFields();
      setParameterValues([]);
      if (close) onClose();
      openNotification(values.id);
    } catch (error) {
      console.log(error);
    }
  };

  const openNotification = (id) => {
    api.success({
      message: `Сборка ${id} успешно создана`,
      placement: 'topRight',
      duration: 0
    });
  };

  const handleClassSelect = (selectedClasses) => {
    setClasses(selectedClasses);
    setGroup(null);
    form.setFieldsValue({ group: null });
    setParameterValues([]);
  };

  const handleGroupSelect = (selectedGroup) => {
    setGroup(selectedGroup);
    setParameterValues(selectedGroup.parameters || []);
  };

  const updateParameterValue = (record, value) => {
    setParameterValues((prev) => {
      const updatedParameters = prev.filter((p) => p.name !== record.name);
      return [...updatedParameters, { name: record.name, description: record.description, value }];
    });
  };

  const tabItems = [
    {
      key: '1',
      label: 'Общие',
      content: (
        <Form form={form} name="basic">
          <Flex gap={'50px'}>
            <Flex vertical gap={'small'} style={{ width: '400px' }}>
              <Typography.Title level={3}>Классификация</Typography.Title>
              <Card bordered={false} style={{ width: '100%' }}>
                <Flex gap="large" align="end">
                  <Flex align="center" gap="large">
                    <Image
                      width={70}
                      height={70}
                      src={classes?.drawing?.includes('http') || classes?.drawing?.includes('base64') ? classes?.drawing : ``}
                    />
                    <Flex vertical>
                      <Typography.Text style={{ color: '#A0D911' }}>{classes?.id}</Typography.Text>
                      <Typography.Text type="secondary">{classes?.name}</Typography.Text>
                    </Flex>
                  </Flex>
                  <Button onClick={() => setClassesAndGroupsVisible(true)}>Выбрать класс</Button>
                </Flex>
              </Card>

              {classes && (
                <Card bordered={false}>
                  <Flex gap="large" align="end">
                    <Flex align="center" gap="large">
                      <Flex>
                        <Image
                          width={70}
                          height={70}
                          src={group?.drawing?.includes('http') || group?.drawing?.includes('base64') ? group?.drawing : ``}
                        />
                        <Flex vertical>
                          <Typography.Text style={{ color: '#A0D911' }}>{group?.id}</Typography.Text>
                          <Typography.Text type="secondary">{group?.name}</Typography.Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Button onClick={() => setListOfGroupsVisible(true)}>Выбрать группу</Button>
                  </Flex>
                </Card>
              )}

              <Typography.Title level={3}>Идентификация</Typography.Title>
              <Form.Item label="ID" name="id" rules={[{ required: true, message: 'Обязательное поле!' }]}>
                <Input onChange={(e) => setId(e.target.value)} />
              </Form.Item>
              <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Обязательное поле!' }]}>
                <Input onChange={(e) => setName(e.target.value)} />
              </Form.Item>
              <Form.Item label="Описание" name="description">
                <Input onChange={(e) => setDescription(e.target.value)} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={'small'} style={{ width: '400px' }}>
              <Typography.Title level={3}>Общее</Typography.Title>
              <Form.Item label="Масса сборки" name="weight">
                <InputNumber onChange={(e) => setWeight(e)} min="0" step="0.1" stringMode />
              </Form.Item>
            </Flex>
            <Flex vertical gap={'small'} style={{ width: '400px' }}>
              <Typography.Title level={3}>Информация об инструмента</Typography.Title>
              <Form.Item label="Количество режущих кромок" name="cutCount">
                <InputNumber onChange={(e) => setCutCount(e)} min={0} />
              </Form.Item>
            </Flex>
          </Flex>
        </Form>
      )
    },
    {
      key: '2',
      label: 'Параметры',
      content: (
        <Flex vertical gap={'large'}>
          <Flex align="center" gap={'large'}>
            <Card bordered={false}>
              <Flex gap="large" align="end">
                <Flex align="center" gap="large">
                  <Image
                    width={70}
                    height={70}
                    src={classes?.drawing?.includes('http') || classes?.drawing?.includes('base64') ? classes?.drawing : ``}
                  />
                  <Flex vertical>
                    <Typography.Text style={{ color: '#A0D911' }}>{classes?.id}</Typography.Text>
                    <Typography.Text type="secondary">{classes?.name}</Typography.Text>
                  </Flex>
                </Flex>
                <Button onClick={() => setClassesAndGroupsVisible(true)}>Выбрать класс</Button>
              </Flex>
            </Card>

            {classes && (
              <Card bordered={false}>
                <Flex gap="large" align="end">
                  <Flex align="center" gap="large">
                    <Flex>
                      <Image
                        width={70}
                        height={70}
                        src={group?.drawing?.includes('http') || group?.drawing?.includes('base64') ? group?.drawing : ``}
                      />
                      <Flex vertical>
                        <Typography.Text style={{ color: '#A0D911' }}>{group?.id}</Typography.Text>
                        <Typography.Text type="secondary">{group?.name}</Typography.Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Button onClick={() => setListOfGroupsVisible(true)}>Выбрать группу</Button>
                </Flex>
              </Card>
            )}
          </Flex>

          {group && (
            <Table
              pagination={false}
              dataSource={parameterValues}
              columns={[
                {
                  title: 'Названия',
                  dataIndex: 'name',
                  key: 'name'
                },
                {
                  title: 'Описание',
                  dataIndex: 'description',
                  key: 'description'
                },
                {
                  title: 'Значение',
                  dataIndex: 'value',
                  key: 'value',
                  render: (_, record) => (
                    <Input defaultValue={record.value} onChange={(e) => updateParameterValue(record, e.target.value)} />
                  )
                }
              ]}
              rowKey="name"
            />
          )}
        </Flex>
      )
    }
  ];

  return (
    <>
      {contextHolder}
      <ListOfClassesAndGroups
        open={classesAndGroupsVisible}
        onClose={() => setClassesAndGroupsVisible(false)}
        setClasses={handleClassSelect}
      />
      <ListOfGroups
        open={listOfGroupsVisible}
        onClose={() => setListOfGroupsVisible(false)}
        groups={classes?.groups}
        setGroup={handleGroupSelect}
      />
      <Drawer
        width={'100vw'}
        title={
          <Tabs activeKey={activeTabKey} onChange={setActiveTabKey} size="large">
            {tabItems.map((item) => (
              <Tabs.TabPane tab={item.label} key={item.key} />
            ))}
          </Tabs>
        }
        placement="left"
        open={visible}
        closeIcon={false}
        onClose={onClose}
        footer={
          <Flex align="center" justify="end" gap="middle">
            <Button
              disabled={!id || !name || !description || !group}
              onClick={() => handleCreate(false)}
              icon={<SaveOutlined />}
              type="primary"
              size="large">
              Создать
            </Button>
            <Button disabled={!id || !name || !description || !group} onClick={() => handleCreate(true)} size="large">
              Сохранить и закрыть
            </Button>
            <Button onClick={onClose} icon={<CloseCircleOutlined />} danger size="large">
              Отменить
            </Button>
          </Flex>
        }>
        {tabItems.find((item) => item.key === activeTabKey)?.content}
      </Drawer>
    </>
  );
};
