import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['order', 'orderInfo', 'requirement'],
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => `${process.env.REACT_APP_PARAM_ORDER}/find`,
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'order', id })), { type: 'order', id: 'LIST' }] : [{ type: 'order', id: 'LIST' }]
    }),

    createOrder: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_PARAM_ORDER}/create`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'order', id: 'LIST' }]
    }),

    editOrder: builder.mutation({
      query: ({ id, name, type, userName }) => ({
        url: `${process.env.REACT_APP_PARAM_ORDER}/edit/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: 'order', id: 'LIST' }]
    }),

    changeOrderStatus: builder.mutation({
      query: ({ id, body }) => ({
        url: `${process.env.REACT_APP_PARAM_ORDER}/edit/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: 'order', id: 'LIST' }]
    }),

    creatingAPartialDelivery: builder.mutation({
      query: ({ id, body }) => ({
        url: `${process.env.REACT_APP_PARAM_ORDER}/edit/${id}/delivered`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'order', id: 'LIST' }]
    }),

    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_ORDER}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'order', id: 'LIST' }]
    }),

    removeToolFromOrder: builder.mutation({
      query: ({ orderId, toolId }) => ({
        url: `${process.env.REACT_APP_PARAM_ORDER}/edit/${orderId}/remove/${toolId}`,
        method: 'PUT'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'orderInfo', id }]
    }),

    getOrderInfo: builder.query({
      query: (id) => `${process.env.REACT_APP_PARAM_ORDER}/${id}`,
      providesTags: (result, error, id) => [{ type: 'orderInfo', id }]
    }),

    addToolInOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `${process.env.REACT_APP_PARAM_ORDER}/edit/${id}/add`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error, id) => [{ type: 'orderInfo', id }]
    }),

    getAllRequirements: builder.query({
      query: () => `${process.env.REACT_APP_PARAM_ORDER}/requirement/find?isIncludeOrder=false`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'requirement', id })), { type: 'requirement', id: 'LIST' }]
          : [{ type: 'requirement', id: 'LIST' }]
    }),

    createRequirement: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_PARAM_ORDER}/requirement/create`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'requirement', id: 'LIST' }]
    })
  })
});

export const {
  useGetAllOrdersQuery,
  useGetOrderInfoQuery,
  useCreateOrderMutation,
  useGetAllRequirementsQuery,
  useCreateRequirementMutation,
  useEditOrderMutation,
  useDeleteOrderMutation,
  useAddToolInOrderMutation,
  useRemoveToolFromOrderMutation,
  useCreatingAPartialDeliveryMutation,
  useChangeOrderStatusMutation
} = orderApi;
