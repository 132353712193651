
const Sphere = ({ position, uuidData }) => {
	const userDataMesh = { Mesh: uuidData }

	return (
		<mesh renderOrder={2} position={position} userData={userDataMesh} name={'HELP'}>
			<sphereGeometry args={[0.1, 32, 32]} />
			<meshStandardMaterial opacity={0.5} color='#0c60ff' depthTest={false} depthWrite={false} transparent={true} />
		</mesh>
		
	)
}

export default Sphere