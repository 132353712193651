import { Modal, Input, Button, Form, message } from 'antd';
import { useEffect } from 'react';
import { useEditOrderMutation } from '../../../services/ordersApi';

export const OrderEdit = ({ visible, onClose, currentValues }) => {
  const [form] = Form.useForm();

  const [editOrder] = useEditOrderMutation();

  useEffect(() => {
    if (currentValues) {
      form.setFieldsValue(currentValues);
    }
  }, [currentValues, form]);

  const handleUpdate = async (order) => {
    const { id, name, userName, type, status } = order;
    try {
      await editOrder({
        id,
        body: {
          name,
          userName,
          type,
          status
        }
      }).unwrap();
      message.success('Заказ успешно отредактирован');
    } catch (error) {
      console.log(error);
      message.error('Не удалось отредактировать заказ');
    }
    onClose();
  };

  return (
    <Modal footer={false} title="Редактирование заказа" open={visible} onCancel={onClose}>
      <Form form={form} name="basic" onFinish={handleUpdate}>
        <Form.Item label="ID" name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Составитель" name="userName">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Тип заказа" name="type">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Статус" name="status">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
