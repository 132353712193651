import { Drawer, Flex, Image, Input, List, Typography } from 'antd';
import React, { useState } from 'react';

export const ListOfGroups = ({ open, onClose, groups, setGroup }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleToolClick = (el) => {
    setGroup(el);
    onClose();
  };

  const handleSearch = (value) => {
    const filteredGroups = groups.filter((group) => group.name.toLowerCase().includes(value.toLowerCase()));
    setSearchTerm(filteredGroups || null);
  };

  return (
    <Drawer placement="left" open={open} onClose={onClose} closeIcon={false} bodyStyle={{ padding: 0 }}>
      <Flex gap="small" vertical style={{ width: '100%', height: '100%', background: 'white' }}>
        <div style={{ padding: '10px', background: 'white', position: 'sticky', top: 0, zIndex: 1 }}>
          <Input.Search
            size={'large'}
            onSearch={handleSearch}
            onPressEnter={(e) => handleSearch(e.target.value)}
            placeholder="Поиск по наименованию"
            enterButton
          />
        </div>
        <Flex id="scrollableDiv" gap="small" vertical style={{ width: '100%', height: '100%', padding: '0 8px', overflow: 'auto' }}>
          <List
            dataSource={searchTerm ? searchTerm : groups}
            renderItem={(el) => (
              <List.Item
                key={el.id}
                onClick={() => handleToolClick(el)}
                style={{
                  cursor: 'pointer'
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgb(252, 255, 230)')} // цвет фона при наведении
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')} // возвращение к начальному цвету
              >
                <List.Item.Meta
                  avatar={
                    <Image
                      width={70}
                      height={70}
                      src={el?.drawing?.includes('http') || el?.drawing?.includes('base64') ? el?.drawing : ``}
                    />
                  }
                  title={<Typography.Text style={{ color: '#A0D911' }}>{el.id}</Typography.Text>}
                  description={<Typography.Text>{el.name}</Typography.Text>}
                />
              </List.Item>
            )}
          />
        </Flex>
      </Flex>
    </Drawer>
  );
};
