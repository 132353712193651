import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const stockApi = createApi({
  reducerPath: 'stockApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['operationPlan'],
  endpoints: (builder) => ({
    getOperationPlans: builder.query({
      query: (id) => `${process.env.REACT_APP_PARAM_OPERATION_PLAN}/${id}/all`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'operationPlan', id })), { type: 'operationPlan', id: 'LIST' }]
          : [{ type: 'operationPlan', id: 'LIST' }]
    }),

    createOperationPlan: builder.mutation({
      query: ({ detailId, plan }) => ({
        url: `${process.env.REACT_APP_PARAM_OPERATION_PLAN}/${detailId}`,
        method: 'POST',
        body: plan
      }),
      invalidatesTags: [{ type: 'operationPlan', id: 'LIST' }]
    }),

    deleteOperationPlan: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_OPERATION_PLAN}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['operationPlan']
    }),

    createProcessingStep: builder.mutation({
      query: ({ planId, step }) => ({
        url: `${process.env.REACT_APP_PARAM_OPERATION_PLAN}/${planId}/add`,
        method: 'POST',
        body: step
      }),
      invalidatesTags: [{ type: 'operationPlan', id: 'LIST' }]
    }),

    deleteProcessingStep: builder.mutation({
      query: ({ planId, stepId }) => ({
        url: `${process.env.REACT_APP_PARAM_OPERATION_PLAN}/${planId}/remove/${stepId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['operationPlan']
    }),

    editProcessingStep: builder.mutation({
      query: ({ planId, stepId, step }) => ({
        url: `${process.env.REACT_APP_PARAM_OPERATION_PLAN}/${planId}/edit/${stepId}`,
        method: 'PUT',
        body: step
      }),
      invalidatesTags: ['operationPlan']
    })
  })
});

export const {
  useGetOperationPlansQuery,
  useCreateOperationPlanMutation,
  useDeleteOperationPlanMutation,
  useCreateProcessingStepMutation,
  useDeleteProcessingStepMutation,
  useEditProcessingStepMutation
} = stockApi;
