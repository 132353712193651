import React, { useMemo, useState } from 'react';
import * as THREE from 'three';
import useContextViewer from '../ContextViewer/ContextViewer';
const SpriteLine = ({ point, lengthLine, AmountRuler, CornerRuler, SpecifyTheLineRuler, SummaLine, SpriteLineMass, ClickScene }) => {

  var text = 0;
  const [Promeg, setPromeg] = useState(SummaLine);
  const [PromegClick, setPromegClick] = useState(ClickScene);

  if (SpecifyTheLineRuler) {
    lengthLine = Promeg;
    text = 'Σ' + String(lengthLine.toFixed(2));
  } else {
    text = '=' + String(lengthLine.toFixed(2));
  }
  if (AmountRuler) {
    lengthLine = Promeg;
    text = 'Σ' + String(PromegClick / 2) + '=' + String(lengthLine.toFixed(2));
  }
  if (CornerRuler) {
    text = '∠α=' + String(lengthLine.toFixed(3)) + '°';
  }
  // const text = '='+String(lengthLine.toFixed(2))
  const spritePosition = [point[0], point[1] + 2, point[2]];
  const canvas = useMemo(() => {
    const width = 800; // Увеличенная ширина канваса
    const height = 400; // Увеличенная высота канваса
    const borderRadius = 40; // Радиус закругления углов
    const borderWidth = 8; // Ширина границы
    const borderColor = '#0c60ff'; // Цвет границы
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');

    // Функция для рисования закругленного прямоугольника
    function drawRoundedRect(ctx, x, y, w, h, r) {
      ctx.beginPath();
      ctx.moveTo(x + r, y);
      ctx.lineTo(x + w - r, y);
      ctx.quadraticCurveTo(x + w, y, x + w, y + r);
      ctx.lineTo(x + w, y + h - r);
      ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
      ctx.lineTo(x + r, y + h);
      ctx.quadraticCurveTo(x, y + h, x, y + h - r);
      ctx.lineTo(x, y + r);
      ctx.quadraticCurveTo(x, y, x + r, y);
      ctx.closePath();
    }

    // Рисуем фон с закругленными углами
    context.fillStyle = '#183453a1'; // Цвет фона
    drawRoundedRect(context, borderWidth / 2, borderWidth / 2, width - borderWidth, height - borderWidth, borderRadius);
    context.fill();

    // Рисуем границу с закругленными углами
    context.strokeStyle = borderColor; // Цвет границы
    context.lineWidth = borderWidth; // Ширина границы
    drawRoundedRect(context, borderWidth / 2, borderWidth / 2, width - borderWidth, height - borderWidth, borderRadius);
    context.stroke();

    // Рисуем текст
    context.fillStyle = 'white'; // Цвет текста
    context.font = '120px sans-serif'; // Увеличенный размер шрифта
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    const lines = text.split('\n');
    const lineHeight = 120; // Высота строки
    const initialY = height / 2 - ((lines.length - 1) * lineHeight) / 2; // Начальная позиция Y для центрирования текста
    lines.forEach((line, index) => {
      const y = initialY + index * lineHeight;
      context.fillText(line, width / 2, y);
    });

    return canvas;
  }, [text]);

  const texture = useMemo(() => new THREE.CanvasTexture(canvas), [canvas]);

  return (
    <sprite renderOrder={3} position={spritePosition} scale={[3, 1.5, 1]} name={'HELP'}>
      <spriteMaterial name={SpriteLineMass} attach="material" map={texture} depthTest={false} depthWrite={false} transparent={true} />
    </sprite>
  );
};

export default SpriteLine;
