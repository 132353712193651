import {
  PoweroffOutlined,
  FileTextOutlined,
  MessageOutlined,
  BellOutlined,
  SettingFilled,
  UserOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Flex, Button, Input } from 'antd';
import { jwtDecode } from 'jwt-decode'; // Импортируем jwt-decode
import ph from './PageHeader.module.scss';
import React from 'react';
import { Header } from 'antd/es/layout/layout';
import { TabsComponent } from '../tabs';
import logo_full from '../../content/images/logo_full.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/authSlice';
import { useNavigate } from 'react-router-dom';
import { setActiveTab, setTabs } from '../../features/headerSlice';

export const PageHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idToken } = useSelector((state) => state.auth);
  const user = idToken ? jwtDecode(idToken) : {};

  const handleLogout = () => {
    dispatch(logout());

    dispatch(setTabs([]));
    dispatch(setActiveTab('startPage'));

    localStorage.removeItem('tabs');
    localStorage.setItem('tabs', JSON.stringify([{ key: 'startPage', label: 'Стартовая страница', active: true, closable: false }]));
    localStorage.setItem('activeTab', 'startPage');

    navigate('/');
  };

  return (
    <>
      <Header style={{ display: 'flex', alignItems: 'center' }} className={ph.container}>
        <Flex gap="small" justify="space-between" align="center" style={{ width: '100%', height: '100%' }} className={ph.container_block}>
          <Flex align="center" gap="small" className={ph.block_leftSide}>
            <img src={logo_full} />
          </Flex>
          <Flex gap="large" align="center" className={ph.block_rightSide} style={{ height: '100%' }}>
            <Input.Search placeholder="Поиск..." enterButton className={ph.header_searchField} />
            <Button icon={<FileTextOutlined style={{ color: 'white' }} />} type="text" size="large" />
            <Button icon={<MessageOutlined style={{ color: 'white' }} />} type="text" size="large" />
            <Button icon={<BellOutlined style={{ color: 'white' }} />} type="text" size="large" />
            <Button icon={<SettingFilled style={{ color: 'white' }} />} type="text" size="large" />

            <Button style={{ color: 'white' }} icon={<UserOutlined style={{ color: 'white' }} />} type="text" size="large">
              {user.name || ''}
            </Button>

            <Button icon={<QuestionCircleOutlined style={{ color: 'white' }} />} type="text" size="large" />
            <Button icon={<InfoCircleOutlined style={{ color: 'white' }} />} type="text" size="large" />
            <Button onClick={handleLogout} icon={<PoweroffOutlined style={{ color: 'white' }} />} type="text" size="large" />
          </Flex>
        </Flex>
      </Header>
      <TabsComponent />
    </>
  );
};
