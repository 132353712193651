import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const costCenterApi = createApi({
  reducerPath: 'product',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['costCenter', 'storage'],
  endpoints: (builder) => ({
    getAllCostCenters: builder.query({
      query: () => `${process.env.REACT_APP_PARAM_COSTCENTER}`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'costCenter', id })), { type: 'costCenter', id: 'LIST' }]
          : [{ type: 'costCenter', id: 'LIST' }]
    }),

    createCostCenter: builder.mutation({
      query: (center) => ({
        url: `${process.env.REACT_APP_PARAM_COSTCENTER}`,
        method: 'POST',
        body: center
      }),
      invalidatesTags: ['costCenter']
    }),

    deleteCostCenter: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_COSTCENTER}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['costCenter']
    }),

    editCostCenter: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `${process.env.REACT_APP_PARAM_COSTCENTER}/${id}`,
          method: 'PUT',
          body: body
        };
      },
      invalidatesTags: ['costCenter']
    }),

    getStoragesByCostCenterIdQ: builder.query({
      query: (id) => ({ url: `${process.env.REACT_APP_PARAM_COSTCENTER}/${id}/storages` }),
      providesTags: (result, error, { id }) =>
        result ? [...result.map(({ id }) => ({ type: 'storage', id })), { type: 'storage', id: 'LIST' }] : [{ type: 'storage', id: 'LIST' }]
    }),

    createStorage: builder.mutation({
      query: (storage) => ({
        url: `${process.env.REACT_APP_PARAM_STORAGE}`,
        method: 'POST',
        body: storage
      }),
      invalidatesTags: ['storage']
    }),

    deleteStorage: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_STORAGE}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['storage']
    }),

    editStorage: builder.mutation({
      query: ({ body, id }) => ({
        url: `${process.env.REACT_APP_PARAM_STORAGE}/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['storage']
    }),

    changeInventarization: builder.mutation({
      query: ({ body, id }) => ({
        url: `${process.env.REACT_APP_PARAM_STORAGE}/${id}/inventarization`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['storage']
    }),

    addNewProductInStorage: builder.mutation({
      query: (product) => ({
        url: `${process.env.REACT_APP_PARAM_TRANSACTION}/arrival`,
        method: 'POST',
        body: product
      }),
      invalidatesTags: ['storage']
    }),

    getCostCenterById: builder.query({
      query: (id) => `${process.env.REACT_APP_PARAM_COSTCENTER}/${id}/remains/group`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'costCenter', id })), { type: 'costCenter', id: 'LIST' }]
          : [{ type: 'costCenter', id: 'LIST' }]
    }),

    //======================================
    getStoragesByCostCenterId: builder.mutation({
      query: (id) => ({ url: `${process.env.REACT_APP_PARAM_COSTCENTER}/${id}/storages` }),
      providesTags: (result, error, { id }) =>
        result ? [...result.map(({ id }) => ({ type: 'storage', id })), { type: 'storage', id: 'LIST' }] : [{ type: 'storage', id: 'LIST' }]
    }),

    deleteItem: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_PARAM_ITEM_BATCH}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'costCenter', id }]
    }),

    transactionProduct: builder.mutation({
      query: (product) => ({
        url: `${process.env.REACT_APP_PARAM_TRANSACTION}/give`,
        method: 'POST',
        body: product
      }),
      invalidatesTags: ['costCenter']
    }),

    changeReservProduct: builder.mutation({
      query: (product) => ({
        url: `${process.env.REACT_APP_PARAM_STORAGE}-item-reserve`,
        method: 'POST',
        body: product
      }),
      invalidatesTags: ['costCenter']
    })
    //======================================
  })
});

export const {
  useGetAllCostCentersQuery,
  useCreateCostCenterMutation,
  useDeleteCostCenterMutation,
  useEditCostCenterMutation,
  useGetStoragesByCostCenterIdQQuery,
  useGetStoragesByCostCenterIdMutation,
  useCreateStorageMutation,
  useDeleteStorageMutation,
  useEditStorageMutation,
  useChangeInventarizationMutation,
  useAddNewProductInStorageMutation,
  useGetCostCenterByIdQuery,
  //=====================================
  useDeleteItemMutation,
  useTransactionProductMutation,
  useChangeReservProductMutation
} = costCenterApi;
