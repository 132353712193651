import { useEffect, useState } from 'react';
import { Scene } from '../../components/scene';
import { Toolbar } from '../../components/toolbar';
import { EditInstrument } from '../../components/editInstrument';
import { CreateOperationPlan } from '../../components/createOperationPlan';
import { guidesApi, useGetToolsQuery } from '../../services/guidesApi';
import { useDeleteOperationPlanMutation, useGetOperationPlansQuery } from '../../services/operationPlan';
import ca from './../../components/ContainerAside/ContainerAside.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Layout,
  Flex,
  Image,
  Typography,
  Input,
  Table,
  List,
  Skeleton,
  Empty,
  Tabs,
  Button,
  Popconfirm,
  Upload,
  message,
  Descriptions
} from 'antd';
import {
  AlignLeftOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
  PlusOutlined,
  UploadOutlined,
  GroupOutlined
} from '@ant-design/icons';
import { CreateInstrument } from '../../components/createInstrument';
import { DxfComponent } from '../../components/dxfViewer';
import s from './Instruments.module.css';
import CreateCopyInstrument from '../../components/createCopyInstrument/CreateCopyInstrument';
import ContainerAside from '../../components/ContainerAside/ContainerAside';
import g from '../../general.module.scss';
import { ModalInstrumentsUploadFile } from './InstrumentsModals';
import { useGetWarehousesQuery } from '../../services/warehouses';
const { Title, Text } = Typography;
const { Search } = Input;
const columnsParameters = [
  {
    title: 'Наименование',
    dataIndex: 'name'
  },
  {
    title: 'Описание',
    dataIndex: 'description'
  },
  {
    title: 'Значение',
    dataIndex: 'value'
  }
];

const columnsOperationPlan = [
  {
    title: 'ID',
    dataIndex: 'planId'
  },
  {
    title: 'Наименование',
    dataIndex: 'name'
  },
  {
    title: ' Материал',
    dataIndex: 'materialName'
  },
  {
    title: '',
    dataIndex: 'delete'
  }
];

const columnsWarehouses = [
  {
    title: 'Центр затрат',
    dataIndex: 'costCenterName',
    key: 'itemId'
  },
  {
    title: ' Новые',
    dataIndex: 'countNew',
    key: 'itemId'
  },
  {
    title: 'Ремонт',
    dataIndex: 'countRepaired',
    key: 'itemId'
  },
  {
    title: 'Б/У',
    dataIndex: 'countUsed',
    key: 'itemId'
  },
  {
    title: 'Наименование',
    dataIndex: 'itemName',
    key: 'itemId'
  },
  {
    title: 'Место хранения',
    dataIndex: 'storageName',
    key: 'itemId'
  }
];

export const Instruments = () => {
  const [uploading, setUploading] = useState(false);
  const [blob3D, setBlob3D] = useState(null);

  const [file, setFile] = useState('');
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [allTools, setAllTools] = useState([]);
  const [selectedTool, setSelectedTool] = useState(null);
  const [selectedToolId, setSelectedToolId] = useState('');
  const [parameters, setParameters] = useState([]);
  const [information, setInformation] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openCreateOperationPlan, setOpenCreateOperationPlan] = useState(false);
  const [activeTab, setActiveTab] = useState('parameters');
  const [openCopyCreate, setOpenCopyCreate] = useState(false);

  const [search, { data: tools, isFetching, isError }] = guidesApi.endpoints.getTools.useLazyQuery();
  const { data: operationPlans } = useGetOperationPlansQuery(selectedTool?.id, {
    skip: !selectedTool // Пропуск запроса, если id не выбран
  });

  const { data: warehouses } = useGetWarehousesQuery(selectedTool?.id, {
    skip: !selectedTool // Пропуск запроса, если id не выбран
  });

  const [deleteOperationPlan] = useDeleteOperationPlanMutation();
  const fetchDelete = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/guide/tool/${selectedTool?.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response);
      if (response.status === 200) {
        const filteredArr = allTools.filter((i) => i.id !== selectedTool?.id);
        setAllTools(filteredArr);
        setTotalElements((prevValue) => prevValue - 1);
        setSelectedTool(allTools[0]);
        message.success('Инструмент удален');
      } else {
        message.error('Ошибка удаления');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const [imageType, setImageType] = useState('');
  const handleToolClick = (tool) => {
    setFile('');
    setImageType('');
    setSelectedTool(tool);
    setSelectedToolId(tool.id);
    setInformation([
      { name: 'Вес', value: tool.weight },
      { name: 'Стадия подсчета', value: tool.countStage },
      { name: 'Количество режущих кромок', value: tool.countCuts }
    ]);
  };

  const [fileList, setFileList] = useState([]);
  const [resourceId, setResourceId] = useState('');
  const [openModalInstrumentsUploadFile, setOpenModalInstrumentsUploadFile] = useState(false);
  const handleCloseModalInstrumentsUploadFile = () => {
    setOpenModalInstrumentsUploadFile(false);
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);

      return false;
    },
    fileList
  };
  useEffect(() => {
    if (fileList[0]?.type === 'image/png') {
      let byte = fileList[0]?.size;
      let mb = byte / 1024 / 1024;
      if (mb >= 20) {
        setFileList(null);
        message.error('Файл превысил размер');
      }
    } else if (fileList[0]?.name.indexOf('.dxf') > 0) {
      let byte = fileList[0]?.size;
      let mb = byte / 1024 / 1024;
      if (mb >= 100) {
        setFileList(null);
        message.error('Файл превысил размер');
      }
    } else if (fileList[0]?.name?.indexOf('.step') > 0) {
      let byte = fileList[0]?.size;
      let mb = byte / 1024 / 1024;
      if (mb >= 200) {
        setFileList(null);
        message.error('Файл превысил размер');
      }
    }
  }, [fileList]);
  const handleUpload = async () => {
    const formaData = new FormData();
    fileList.forEach((file) => {
      formaData.append('file', file);
    });
    setUploading(true);

    const baseUrl = `${process.env.REACT_APP_BASE_URL}/guide/tool/${selectedTool?.id}/resource`;
    const params = new URLSearchParams({
      name: `${selectedTool?.id}_resource`
    });
    const fullUrl = `${baseUrl}?${params.toString()}`;
    const encodedUrl = encodeURI(fullUrl);
    console.log(fullUrl);
    const response = await fetch(encodedUrl, {
      method: 'POST',
      body: formaData // FormData
    })
      .then((res) => res.json())
      .then((res) => {
        const resArr = [...selectedTool.resources, res];

        setSelectedTool({ ...selectedTool, resources: resArr });
        setAllTools(allTools?.map((l) => (l.id == selectedTool.id ? { ...l, resources: resArr } : l)));
        setFileList([]);
        message.success('upload successfully.');
      })
      .catch((e) => {
        message.error('upload failed.');
        console.log(e);
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const toolbarItems = [
    { key: 1, icon: <EditOutlined />, onClick: () => setOpenEdit(true) },
    { key: 2, icon: <PlusOutlined />, onClick: () => setOpenCreate(true) },
    { key: 3, icon: <CopyOutlined />, onClick: () => setOpenCopyCreate(true) }
  ];
  useEffect(() => {
    if (activeTab === 'operationPlan') {
      const params = operationPlans?.map(({ id, planId, name, materialName }) => ({
        planId,
        name,
        materialName,
        delete: (
          <Popconfirm
            placement="leftBottom"
            title="Действительно удалить операционный план?"
            onConfirm={() => deleteOperationPlan(id).unwrap()}
            okText="Ок"
            cancelText="Отмена">
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        )
      }));

      setParameters(params);
    } else if (activeTab === 'parameters') {
      const params = selectedTool?.parameters?.map(({ name, description, value }) => ({
        name,
        description,
        value: !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : value
      }));
      setParameters(params);
    } else if (activeTab === 'warehouses') {
      setParameters(warehouses);
    }
  }, [activeTab, operationPlans]);

  const loadMoreData = () => {
    if (!isFetching && !isError) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (currentPage > 1) search({ page: currentPage, searchQuery });
  }, [currentPage]);

  useEffect(() => {
    if (searchQuery !== '') {
      search({ page: 1, searchQuery });
    } else {
      search({ page: 1, searchQuery: '' });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (tools) setTotalElements(tools?.totalElements);

    if (tools && tools.results && currentPage !== 1) {
      setAllTools((prevTools) => [...prevTools, ...tools.results]);
    } else if (tools && tools.results && (searchQuery || currentPage === 1)) {
      setAllTools(tools.results);
    }
  }, [tools]);

  useEffect(() => {
    let file = '';
    if (imageType === '') {
      setImageType('dxf');
    }
    if (imageType === 'step') {
      const newArr = selectedTool?.resources?.filter((i) => i.content_type === 'model/step');
      if (newArr?.length > 0) {
        setResourceId(newArr[0]?.url);
        file = `${process.env.REACT_APP_BASE_URL}/guide/${newArr[0]?.url}`;
      }
    } else if (imageType === 'dxf') {
      const newArr = selectedTool?.resources?.filter((i) => i.content_type === 'image/vnd.dxf');
      if (newArr?.length > 0) {
        setResourceId(newArr[0]?.url);
        file = `${process.env.REACT_APP_BASE_URL}/guide/${newArr[0]?.url}`;
      }
    } else if (imageType === 'png') {
      const newArr = selectedTool?.resources?.filter((i) => i.content_type === 'image/png' || i.content_type === 'image/jpeg');
      if (newArr?.length > 0) {
        setResourceId(newArr[newArr.length - 1]?.url);
        const fetchBase64Data = async (path) => {
          try {
            const response = await fetch(path);
            if (!response.ok) {
              throw new Error(`Ошибка при загрузке файла: ${response.statusText}`);
            }
            const file = await response.text();
            setFile(file);
          } catch (error) {
            console.error('Ошибка при получении файла:', error);
          }
        };
        fetchBase64Data(`${process.env.REACT_APP_BASE_URL}/guide/${newArr[newArr.length - 1]?.url}`);
      }
    } else if (imageType === 'params') {
      const newArr = selectedTool?.group?.resources?.filter((i) => i.content_type === 'image/vnd.dxf');
      if (newArr?.length > 0) {
        setResourceId(newArr[0]?.url);
        file = `${process.env.REACT_APP_BASE_URL}/guide/${newArr[0]?.url}`;
      }
    }
    setFile(file);
  }, [imageType, selectedTool]);

  function downloadBlob(name = `${resourceId}.step`) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) return window.navigator.msSaveOrOpenBlob(blob3D);

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob3D);

    const link = document.createElement('a');
    link.href = data;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
  const deleteResource = async () => {
    const responseId = resourceId.split('/')[1];

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/guide/tool/${selectedTool?.id}/resource/${responseId}`, {
      method: 'DELETE'
    });
    if (response.status === 204) {
      const deletedResource = selectedTool?.resources?.filter((i) => i.url !== resourceId);
      setAllTools(allTools?.map((l) => (l.id == selectedTool.id ? { ...l, resources: deletedResource } : l)));
      setSelectedTool({ ...selectedTool, resources: deletedResource });

      setFile('');
      message.success('Успешно удалено');
    } else {
      message.error('Ошибка удаления');
    }
  };

  return (
    <>
      <Layout className={ca.container} style={{ backgroundColor: '#f0f2f5' }}>
        <Flex gap={'small'} vertical className={ca.container_leftSide}>
          <Layout className={ca.container_leftSide_header}>
            <Search size={'large'} onSearch={(e) => setSearchQuery(e)} onPressEnter={(e) => setSearchQuery(e.target.value)} enterButton />
          </Layout>
          <Descriptions
            size={'small'}
            bordered={false}
            column={2}
            items={[
              {
                key: '1',
                label: 'Всего',
                children: isFetching ? (
                  <Skeleton.Input size={'small'} style={{ height: '22px', width: '44px', minHeight: 'unset', minWidth: 'unset' }} />
                ) : (
                  totalElements || 0
                )
              },
              {
                key: '2',
                label: 'Загружено',
                children: isFetching ? (
                  <Skeleton.Input size={'small'} style={{ height: '22px', width: '44px', minHeight: 'unset', minWidth: 'unset' }} />
                ) : (
                  allTools?.length || 0
                )
              }
            ]}
          />
          <Flex id={'leftSide_scrollableDiv'} gap={'small'} vertical className={ca.container_leftSide_scrollBlock}>
            <InfiniteScroll
              dataLength={allTools?.length}
              next={() => {
                !isFetching && !isError ? setCurrentPage((prevPage) => prevPage + 1) : '';
              }}
              hasMore={allTools?.length < totalElements}
              loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
              scrollableTarget={'leftSide_scrollableDiv'}>
              <List
                loading={isFetching}
                dataSource={allTools}
                renderItem={(item) => (
                  <List.Item
                    className={ca.list_item}
                    key={item.id}
                    onClick={() => handleToolClick(item)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: item.id === selectedTool?.id ? '#fcffe6' : 'transparent'
                    }}>
                    <List.Item.Meta
                      avatar={
                        item?.drawing ? (
                          <img
                            width={70}
                            height={70}
                            src={
                              item?.drawing.includes('http') || item?.drawing.includes('base64')
                                ? item?.drawing
                                : `data:image/jpeg;base64, ${item?.drawing}`
                            }
                          />
                        ) : (
                          <Skeleton.Image size={'small'} style={{ width: '70px', height: '70px' }} />
                        )
                      }
                      title={item['id']}
                      description={item['name']}
                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Flex>
        </Flex>
        <Layout className={ca.container_rightSide}>
          {toolbarItems.length !== 0 ? <Toolbar className={g.toolbar} toolbarItems={toolbarItems} /> : ''}
          <Flex className={g.container} gap="small" style={{ width: '100%', height: 'calc(100% - 53px)' }}>
            <List
              className={g.container_block}
              style={{ width: '350px', background: 'white' }}
              header={<Title level={5}>Основная информация</Title>}
              bordered>
              {selectedTool && (
                <>
                  <List.Item>
                    <Text type="secondary">{'Идентификатор'}</Text>
                    <Text>{selectedTool?.name}</Text>
                  </List.Item>
                  <List.Item>
                    <Text type="secondary">{'Класс инструмента'}</Text>
                    <Text>{selectedTool?.tmdClass?.name}</Text>
                  </List.Item>
                  <List.Item>
                    <Text type="secondary">{'Група инструмента'}</Text>
                    <Text>{selectedTool?.group?.name}</Text>
                  </List.Item>
                  <List.Item>
                    <Text type="secondary">{'Масса'}</Text>
                    <Text>{selectedTool?.weight}</Text>
                  </List.Item>
                  <List.Item>
                    <Text type="secondary">{'Режущий сплав'}</Text>
                    <Text>{selectedTool?.cutGrade?.name}</Text>
                  </List.Item>
                  <List.Item>
                    <Text type="secondary">{'Количество режущих кромок'}</Text>
                    <Text>{selectedTool?.countCuts}</Text>
                  </List.Item>
                </>
              )}
            </List>
            <Flex vertical gap="small" style={{ flexGrow: 1 }} className={g.container_div}>
              <Layout
                className={g.container_block}
                style={{
                  height: '50%',
                  background: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    borderBottom: '1px solid gray',
                    width: '100%'
                  }}>
                  <Button
                    style={{ borderBottom: imageType === 'dxf' ? '2px solid #A0D911' : '' }}
                    className={s.image__btn}
                    onClick={() => {
                      setFile('');
                      setImageType('dxf');
                    }}>{`2D изображение [dxf]`}</Button>
                  <Button
                    style={{ borderBottom: imageType === 'step' ? '2px solid #A0D911' : '' }}
                    className={s.image__btn}
                    onClick={() => {
                      setFile('');
                      setImageType('step');
                    }}>{`3D изображение [step]`}</Button>
                  <Button
                    style={{ borderBottom: imageType === 'png' ? '2px solid #A0D911' : '' }}
                    className={s.image__btn}
                    onClick={() => {
                      setFile('');
                      setImageType('png');
                    }}>{`Изображение [png]`}</Button>
                  <Button
                    style={{ borderBottom: imageType === 'params' ? '2px solid #A0D911' : '' }}
                    className={s.image__btn}
                    onClick={() => {
                      setFile('');
                      setImageType('params');
                    }}>
                    {`Параметрическое изображение`}
                  </Button>
                  <Upload {...props} previewFile={null} progress={null} itemRender={() => <></>}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                  <Button type="primary" onClick={handleUpload} disabled={fileList.length === 0} loading={uploading}>
                    {uploading ? 'Uploading' : 'Start Upload'}
                  </Button>
                  <Popconfirm
                    placement="leftBottom"
                    title="Действительно удалить инструмент?"
                    onConfirm={() => fetchDelete()}
                    okText="Ок"
                    cancelText="Отмена">
                    <Button danger icon={<DeleteOutlined />} />
                  </Popconfirm>
                </div>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  {imageType === 'step' ? (
                    file !== '' ? (
                      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            zIndex: 999,
                            top: 0,
                            color: '#000',
                            fontSize: 24
                          }}>
                          <span
                            style={{
                              color: '#000',
                              fontSize: 24
                            }}
                            onClick={() => {
                              downloadBlob();
                            }}>
                            Скачать{' '}
                          </span>
                          <span
                            style={{
                              color: '#000',
                              fontSize: 24
                            }}
                            onClick={() => {
                              deleteResource();
                            }}>
                            Удалить
                          </span>
                        </div>
                        <Scene url={file} setBlob3D={setBlob3D} />
                      </div>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : (
                    <></>
                  )}
                  {imageType === 'dxf' ? (
                    file !== '' ? (
                      <DxfComponent deleteResource={deleteResource} resourceId={resourceId} base64Path={file} />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : (
                    <></>
                  )}
                  {imageType === 'png' ? (
                    file !== '' ? (
                      <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <span
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: '#000',
                            fontSize: 24
                          }}
                          onClick={() => deleteResource()}>
                          Удалить
                        </span>
                        <img width={'35%'} height={'100%'} src={`data:image/png;base64,${file}`} />
                      </div>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : (
                    <></>
                  )}
                  {imageType === 'params' ? (
                    file !== '' ? (
                      <DxfComponent resourceId={resourceId} base64Path={file} />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Layout>
              <Layout
                className={g.container_block}
                style={{
                  height: '50%',
                  padding: '10px',
                  overflow: 'auto',
                  position: 'relative',
                  background: 'white'
                }}>
                <Tabs
                  size="large"
                  activeKey={activeTab}
                  tabBarExtraContent={
                    activeTab === 'operationPlan' ? (
                      <Button
                        disabled={!selectedTool}
                        type="text"
                        onClick={() => setOpenCreateOperationPlan(true)}
                        icon={<PlusOutlined />}
                      />
                    ) : (
                      false
                    )
                  }
                  onChange={(tab) => setActiveTab(tab)}
                  items={[
                    { key: 'parameters', label: 'Параметры', icon: <AlignLeftOutlined /> },
                    // {
                    //   key: 'operationPlan',
                    //   label: 'Операционный план',
                    //   icon: <FileDoneOutlined />,
                    //   disabled: selectedTool ? false : true
                    // },
                    {
                      key: 'warehouses',
                      label: 'Склады',
                      icon: <GroupOutlined />,
                      disabled: selectedTool ? false : true
                    }
                  ]}
                />
                <Table
                  rowKey="name"
                  pagination={false}
                  dataSource={parameters}
                  columns={
                    activeTab === 'parameters'
                      ? columnsParameters
                      : activeTab === 'operationPlan'
                      ? columnsOperationPlan
                      : columnsWarehouses
                  }
                />
              </Layout>
            </Flex>
          </Flex>
        </Layout>
      </Layout>

      <CreateInstrument
        setAllTools={setAllTools}
        allTools={allTools}
        tool={selectedTool}
        setTool={setSelectedTool}
        visible={openEdit}
        onClose={() => setOpenEdit(false)}
      />
      <CreateInstrument
        setAllTools={setAllTools}
        setTotalElements={setTotalElements}
        allTools={allTools}
        setTool={setSelectedTool}
        visible={openCreate}
        onClose={() => setOpenCreate(false)}
      />
      <CreateCopyInstrument
        setAllTools={setAllTools}
        setTotalElements={setTotalElements}
        allTools={allTools}
        setTool={setSelectedTool}
        tool={selectedTool}
        visible={openCopyCreate}
        onClose={() => setOpenCopyCreate(false)}
      />
      <CreateOperationPlan tool={selectedTool} visible={openCreateOperationPlan} onClose={() => setOpenCreateOperationPlan(false)} />

      <ModalInstrumentsUploadFile
        callback_open={openModalInstrumentsUploadFile}
        callback_close={handleCloseModalInstrumentsUploadFile}
        callback_submit={handleUpload}
      />
      {/*  </Flex>*/}
      {/*</Flex>*/}

      {/*// </Layout>*/}
    </>
  );
};
