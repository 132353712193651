import { Modal, Input, Select, Button, Form } from 'antd';
import { useState } from 'react';
import { useCreateCostCenterMutation } from '../../services/costCenterApi';

const { Option } = Select;

export const CostCenterCreate = ({ isCreateOpen, setIsCreateOpen }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');

  const [createCenter, {}] = useCreateCostCenterMutation();

  const handleCreate = async () => {
    await createCenter({
      name,
      type,
      description
    });
    setIsCreateOpen(false);
  };

  return (
    <Modal footer={false} title="Создание центра затрат" open={isCreateOpen} onCancel={() => setIsCreateOpen(false)}>
      <Form
        name="basic"
        initialValues={{
          remember: true
        }}
        autoComplete="off">
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Тип"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Select onChange={(value) => setType(value)}>
            <Option value="Склад">Склад</Option>
            <Option value="Оборудование">Оборудование</Option>
            <Option value="Тех. обслуживание">Тех. обслуживание</Option>
            <Option value="Списание">Списание</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Описание"
          name="description"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}>
          <Input onChange={(e) => setDescription(e.target.value)} />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'end', margin: 0 }}>
          <Button disabled={!name || !type || !description} type="primary" onClick={handleCreate}>
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
